import React, { useRef, useEffect,useState } from 'react';
import { Card, CardContent ,Box,FormControlLabel,Checkbox ,Typography,Grid } from '@mui/material';
import { styled } from '@mui/system';
import { CardHeader } from '@mui/material';



const ScrollableCard  = ({listData,addressData,setListData,place,addressNumber,building,village,moo,soi,province,khet,kwang,zip,street}) => {
  const cardRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false); 
  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [inputErrors, setInputErrors] = useState([]);
  // const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

  useEffect(() => {
    const selectedIndexList = listData.map(item => item.index); // ดึง index จาก listData
    setSelectedIndexes(selectedIndexList);
    // console.log("cardRef.current:", cardRef.current);

    
  }, [addressData,place,addressNumber,building,village,moo,soi,province,khet,kwang,zip,street]); // เรียกครั้งเดียวหลัง 
  
  useEffect(() => {
    const updatedList = listData.map((item) => ({
      ...item,
      place:place,
      number: addressNumber,
      // building: building + " หมู่บ้านๅๅ" + village,
      building: (building && building !== "-" ? " อาคาร " + building : "") + (village && village !== "-" ? " หมู่บ้าน " + village : ""),
      moo: moo,
      soi: soi,
      street: street,
      kwang: kwang,
      khet: khet,
      province: province,
      zip: zip,
    }));
    setListData(updatedList);
  }, [place,addressNumber, building, village, moo, soi, province, khet, kwang, zip, street]);


  const CustomCheckbox = styled(Checkbox)({
    color: 'rgba(255, 94, 67, 0.5)',
    '&.Mui-checked': {
      color: '#FF5E43',
    },
    marginRight: '15px'
  });

  const handleCheckAll = () => {
    if (isChecked) {
      removeAllItems(); // ลบข้อมูลทั้งหมด
    } else {
      const allIndexes = addressData.map((_, index) => index); // สร้าง array ของทุก index
      setSelectedIndexes(allIndexes);

      const updatedList = addressData.map((item) => ({
        policy_no: item.policy_no,
        policy_number: item.id,
        place:place,
        number: addressNumber,
        building: (building && building !== "-" ? " อาคาร " + building : "")+ (village && village !== "-" ? " หมู่บ้าน " + village : ""),
        moo: moo,
        soi: soi,
        street: street,
        kwang: kwang, // recheck use code or name
        khet: khet,
        province: province,
        zip: zip,

        address1: item.address1,
        address2: item.address2,
        address3: item.address3,
        address4: item.address4,
        address5: item.address5,
      }));



      setListData(updatedList);
      // setDataInformation(updatedList) // ตั้งค่า listData ให้มีข้อมูลทั้งหมด
    }
    setIsChecked(!isChecked); // เปลี่ยนสถานะ CheckAll
  };

  const handleBoxClick = (index, policyNoVal, address1, address2, address3, address4, address5, uuId) => {
    // console.log(cardRef)
    setSelectedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array
        removeItemByPolicyNo(policyNoVal);
        return prevIndexes.filter(i => i !== index);
      } else {
        // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน array

        const updatedList = {
          index:index,
          policy_no: policyNoVal,
          policy_number: uuId,
          place:place,
          number: addressNumber,
          building: (building && building !== "-" ? " อาคาร " + building : "") + (village && village !== "-" ? " หมู่บ้าน " + village : ""),
          moo: moo,
          soi: soi,
          street: street,
          kwang: kwang, // recheck use code or name
          khet: khet,
          province: province,
          zip: zip,

          address1: address1,
          address2: address2,
          address3: address3,
          address4: address4,
          address5: address5,
        }
        addItem(updatedList);
        return [...prevIndexes, index];
      }
    });
  };

  
  const addItem = (newItem) => {
    setListData((prevListData) => [...prevListData, newItem]);
  };

  const removeItemByPolicyNo = (policyNo) => {
    setListData((prevListData) => prevListData.filter(item => item.policy_no !== policyNo));
  };

  const removeAllItems = () => {
    setListData([]); // ตั้งค่า listData ให้เป็น array ว่าง
    setSelectedIndexes([]); // เคลียร์ selectedIndexes
  };

  return (
    <Card ref={cardRef} sx={{ maxHeight: '490px', overflowY: 'auto' }}>
       <CardContent
          sx={{
            maxHeight: '490px',
          overflowY: 'auto',
          border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: '12px', // ความโค้งของขอบ
          borderWidth: '2px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box>
            <FormControlLabel
              control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
              label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
            />
          </Box>

          <Typography
            variant="body2"
            sx={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} // ข้อความสีแดงพร้อม margin ด้านบน
          >
            *กรุณาเลือกกรมธรรม์
          </Typography>

          {addressData.map((_, index) => (
            <Box
              key={index}
              onClick={() => handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id)} // เมื่อ Box ถูกคลิก
              sx={{
                marginBottom: '15px',
                padding: '10px',
                transition: 'background-color 0.3s ease, border 0.3s ease',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: selectedIndexes.includes(index)
              }}
            >
              <Card style={{ zIndex: 1000 }} sx={{
                backgroundColor: selectedIndexes.includes(index)
                  ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                  : 'transparent', // พื้นหลังปกติ
                transition: 'background-color 0.3s ease, border 0.3s ease',
              }}>
                <CardHeader
                  title={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CustomCheckbox
                        checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
                        onChange={(e) => {
                          // console.log("");
                          e.stopPropagation(); // หยุดไม่ให้เกิด event ที่ parent
                          // handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id); // ทำการคลิกเมื่อ checkbox ถูกเลือก
                        }}
                        sx={{ color: 'rgba(255, 94, 67, 0.8)' }} // ปรับสี
                      />
                      <Box sx={{ marginLeft: '10px' }}> {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
                        {"กรมธรรม์เลขที่ : " + _.policy_no}
                      </Box>
                    </Box>
                  }
                  sx={{
                    backgroundColor: 'rgba(255, 94, 67, 0.3)', // ปรับให้ใช้งาน rgba ถูกต้อง
                  }}
                  titleTypographyProps={{
                    sx: {
                      fontFamily: '"Prompt-Thin", sans-serif !important',
                      fontSize: '15px',
                      color: 'black',
                      marginLeft: '15px',
                    },
                  }}
                />
                <CardContent>
                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <span className="font-size-label-pol">ที่อยู่ :</span>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {`${_.address1} ${_.address2} ${_.address3} `}{`${_.address4} `} {`${_.address5} `}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}

        </CardContent>

    </Card>
  );
};

export default ScrollableCard;

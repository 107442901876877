import "../../App.css";
import "./Add_Parameter.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useState } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import Box from "@mui/material/Box";
import withNavigation from "./withNavigation";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  DialogContentText,
  Grid,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { styled } from "@mui/material/styles";

const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked ": {
    color: "coral",
    "&:hover": {
      backgroundColor: "rgba(225, 0, 0, 0.08)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "coral",
  },
  "& .MuiFormControlLabel-label": {
    color: "coral",
  },
}));
const CustomDataGrid = styled(DataGrid)({
  "& .MuiDataGrid-checkboxInput.Mui-checked": {
    color: "coral",
  },
  "& > .MuiDataGrid-columnSeparator": {
    display: "none",
  },
});
class Add_Parameter_edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      token: "",
      username: "",
      step: 1,
      offset: 0,
      data: [],
      perPage: 50,
      currentPage: 0,
      pageCount: 0,
      open: false,
      openInst: false,
      data1: "",
      data2: "",
      checked: false,
      currentRow: {
        pmdId: "",
        code: "",
        name: "",
        description: "",
        variable1: "",
        variable2: "",
        variable3: "",
        variable4: "",
        variable5: "",
        variable6: "",
        variable7: "",
        variable8: "",
        variable9: "",
        flagActive: false,
      },
      columns: [
        {
          field: "code",
          headerName: "Code",
          flex: 0.7,
          minWidth: 250,
          align: "center",
          headerAlign: "center",
          editable: false,
          sortable: false,
          resizable: false,
          filterable: false,
          headerClassName: "super-app-theme--header",
          renderHeader: () => <strong>Code</strong>,
        },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
          align: "center",
          headerAlign: "center",

          sortable: false,
          resizable: false,
          filterable: false,
          minWidth: 120,
          headerClassName: "super-app-theme--header",
          renderHeader: () => <strong>Name</strong>,
        },
        {
          field: "description",
          headerName: "Description",
          flex: 1,
          minWidth: 200,
          align: "center",
          headerAlign: "center",
          editable: false,
          sortable: false,
          resizable: false,
          filterable: false,
          headerClassName: "super-app-theme--header",
          renderHeader: () => <strong>Deccription</strong>,
        },
        {
          field: "variable1",
          headerName: "Variable 1",
          flex: 1,
          minWidth: 120,
          align: "center",
          headerAlign: "center",
          editable: false,
          sortable: false,
          resizable: false,
          filterable: false,
          headerClassName: "super-app-theme--header",
          renderHeader: () => <strong>Variable 1</strong>,
        },
        {
          field: "action",
          headerName: "Action",
          description: "This column has a value getter and is not sortable.",
          align: "center",
          headerAlign: "center",
          editable: false,
          sortable: false,
          resizable: false,
          filterable: false,
          flex: 1,
          minWidth: 120,
          headerClassName: "super-app-theme--header",
          renderHeader: () => <strong>Action</strong>,
          renderCell: (params) => {
            return (
              <div className="detail_action">
                <button
                  className="detail_button btn"
                  onClick={() => this.handleEditRow(params.row)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-info-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                  </svg>
                  <span> Edit</span>
                </button>
              </div>
            );
          },
        },
      ],
      selectedRows: [],
      openDialog: false,
      isEdit: false,
      apiData: [],
      pmt_id: "",
    };
    this.handleEditRow = this.handleEditRow.bind(this);
  }
  componentDidMount() {
    const { location } = this.props;
    const { row } = location.state || { row: {} };
    axios
      .get(
        `https://localhost:7044/api/parameter/getparameterdetatail?pmt_id=${row.pmtId}`
      )
      .then((response) => {
        const apiDataWithIds = response.data.payload.map((item, index) => ({
          ...item,
          id: index + 1,
          ...item,
          flagActive: item.flagActive === "Y",
        }));

        this.setState({
          apiData: apiDataWithIds,
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the API data", error);
      });
  }

  handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    this.setState((prevState) => ({
      currentRow: { ...prevState.currentRow, [name]: inputValue },
    }));
  };

  handleSelectionChange = (selection) => {
    this.setState({ selectedRows: selection });
  };

  handleOpenDialog = () => {
    this.setState({
      openDialog: true,
      isEdit: false,
      currentRow: {
        pmt_id: "",
        code: "",
        name: "",
        description: "",
        variable1: "",
        variable2: "",
        variable3: "",
        variable4: "",
        variable5: "",
        variable6: "",
        variable7: "",
        variable8: "",
        variable9: "",
        flagActive: false,
      },
    });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleEditRow = (row) => {
    this.setState({ openDialog: true, isEdit: true, currentRow: { ...row } });
  };
  handleButtonClick() {
    window.location.href = `addParameter`;
  }
  isDuplicateCode = (code) => {
    return this.state.apiData.some(
      (item) => item.code === code && item.pmdId !== this.state.currentRow.pmdId
    );
  };
  isDuplicateName = (name) => {
    const { apiData } = this.state;
    return apiData.some(
      (row) => row.name === name && row.pmdId !== this.state.currentRow.pmdId
    );
  };
  handleUpdateRow = () => {
    const { currentRow } = this.state;
    const { location } = this.props;
    const { row } = location.state || { row: {} };
    const code_name = this.state.apiData[0];
    // console.log(code_name.name);
    if (
      currentRow.code != "" &&
      currentRow.name != "" &&
      currentRow.description != "" &&
      !this.isDuplicateCode(currentRow.code) &&
      !this.isDuplicateName(currentRow.name)
    ) {
      const dataupdate = {
        pmd_id: `${currentRow.pmdId}`,
        code: currentRow.code,
        name: currentRow.name,
        description: currentRow.description,
        variable1: currentRow.variable1,
        variable2: currentRow.variable2 ?? "",
        variable3: currentRow.variable3 ?? "",
        variable4: currentRow.variable4 ?? "",
        variable5: currentRow.variable5 ?? "",
        variable6: currentRow.variable6 ?? "",
        variable7: currentRow.variable7 ?? "",
        variable8: currentRow.variable8 ?? "",
        variable9: currentRow.variable9 ?? "",
        flag_active: currentRow.flagActive ? "Y" : "N",
        pmt_id: `${row.pmtId}`,
      };
      axios
        .post(
          "https://localhost:7044/api/parameter/putparameterdetatail",
          dataupdate
        )
        .then((response) => {
          this.setState((prevState) => {
            const dataupdate_table = {
              id: currentRow.id,
              pmdId: currentRow.pmdId,
              code: currentRow.code,
              name: currentRow.name,
              description: currentRow.description,
              variable1: currentRow.variable1,
              variable2: currentRow.variable2,
              variable3: currentRow.variable3,
              variable4: currentRow.variable4,
              variable5: currentRow.variable5,
              variable6: currentRow.variable6,
              variable7: currentRow.variable7,
              variable8: currentRow.variable8,
              variable9: currentRow.variable9,
              flagActive: currentRow.flagActive ? "Y" : "N",
              pmt_id: `${row.pmtId}`,
            };
            const newRow = [...prevState.apiData];
            newRow[currentRow.id - 1] = dataupdate_table;
            // console.log(newRow);
            this.setState({ apiData: newRow });
            alert("Update successfully");
            // console.log(response);
            this.handleCloseDialog();
          });
          this.handleCloseDialog();
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (currentRow.name == "") {
      alert("name is missing");
    } else if (currentRow.description == "") {
      alert("description is missing");
    } else if (!this.isDuplicateName(currentRow.name)) {
      alert("name already exists");
    } else {
      alert("code already exists");
    }
  };

  handleAddRow = () => {
    const { currentRow } = this.state;
    const { location } = this.props;
    const { row } = location.state || { row: {} };
    if (
      currentRow.code != "" &&
      currentRow.name != "" &&
      currentRow.description != "" &&
      !this.isDuplicateCode(currentRow.code) &&
      !this.isDuplicateName(currentRow.name)
    ) {
      const dataupdate = {
        code: currentRow.code,
        name: currentRow.name,
        description: currentRow.description,
        variable1: currentRow.variable1,
        variable2: currentRow.variable2 ?? "",
        variable3: currentRow.variable3 ?? "",
        variable4: currentRow.variable4 ?? "",
        variable5: currentRow.variable5 ?? "",
        variable6: currentRow.variable6 ?? "",
        variable7: currentRow.variable7 ?? "",
        variable8: currentRow.variable8 ?? "",
        variable9: currentRow.variable9 ?? "",
        flag_active: currentRow.flagActive ? "Y" : "N",
        pmt_id: `${row.pmtId}`,
      };
      axios
        .post(
          "https://localhost:7044/api/parameter/postparameterdetatail",
          dataupdate
        )
        .then((response) => {
          this.setState((prevState) => {
            const newId =
              prevState.apiData.length > 0
                ? prevState.apiData[prevState.apiData.length - 1].id + 1
                : 1;
            const newpmdId =
              prevState.apiData.length > 0
                ? prevState.apiData[prevState.apiData.length - 1].pmdId + 1
                : 1;
            const dataupdate_table = {
              id: newId,
              pmdId: newpmdId,
              code: currentRow.code,
              name: currentRow.name,
              description: currentRow.description,
              variable1: currentRow.variable1,
              variable2: currentRow.variable2,
              variable3: currentRow.variable3,
              variable4: currentRow.variable4,
              variable5: currentRow.variable5,
              variable6: currentRow.variable6,
              variable7: currentRow.variable7,
              variable8: currentRow.variable8,
              variable9: currentRow.variable9,
              flagActive: currentRow.flagActive ? "Y" : "N",
              pmt_id: `${row.pmtId}`,
            };
            const newRow = [...prevState.apiData];
            newRow[newId - 1] = dataupdate_table;
            this.setState({ apiData: newRow });
            alert("Add Row successfully");
            // console.log(response);
            this.handleCloseDialog();
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (currentRow.name == "") {
      alert("name is missing");
    } else if (currentRow.description == "") {
      alert("description is missing");
    } else if (!this.isDuplicateName(currentRow.name)) {
      alert("name already exists");
    } else {
      alert("code already exists");
    }
  };
  handleDeleteSelected = () => {
    const { selectedRows, apiData } = this.state;
    const rowsToDelete = apiData.filter((row) => selectedRows.includes(row.id));
    const apiDataWithIds = rowsToDelete.map((item) => item.pmdId);
    if (selectedRows.length > 0) {
      axios({
        method: "delete",
        url: "https://localhost:7044/api/parameter/deleteparameterdetail",
        data: apiDataWithIds,
      })
        .then((response) => {
          alert("Deleted successfully.");
          // console.log(response);
          this.setState((prevState) => ({
            apiData: prevState.apiData.filter(
              (row) => !selectedRows.includes(row.id)
            ),
            selectedRows: [],
          }));
        })
        .catch((error) => {
          console.error("There was an error deleting the data", error);
        });
    } else {
      alert("please select the column for delete");
    }
  };

  render() {
    const { location } = this.props;
    const { row } = location.state || { row: {} };
    const { apiData } = this.state;
    /*const rowsindex = apiData.filter((rower) =>
      row.pmtId === rower.pmt_id ? rower : ""
    );*/
    return (
      <div>
        <Header_Home />
        <Header title="Parameter : บันทึกข้อมูล" />
        <div className="content margin-3">
          <div className="content margin-auto">
            <h3 className="head_parameter">Parameter : บันทึกข้อมูล</h3>
            <section className="section rounded border border-2 p-3">
              <div className="parameter_button_group">
                <button
                  className="add_para_button"
                  variant="contained"
                  onClick={this.handleOpenDialog}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="26"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                  <span> Add</span>
                </button>
                <button
                  className="add_para_button"
                  variant="contained"
                  onClick={this.handleDeleteSelected}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="26"
                    fill="currentColor"
                    className="bi bi-plus-lg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                    />
                  </svg>
                  <span> delete</span>
                </button>

                <button
                  className="add_para_button back_button"
                  variant="contained"
                  onClick={this.handleButtonClick}
                >
                  <span>back</span>
                </button>
              </div>
              <div className="row">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    minHeight: 400,
                    "& .super-app-theme--header": {
                      backgroundColor: "rgba(189,229,254,255)",
                    },
                  }}
                >
                  <CustomDataGrid
                    rows={apiData}
                    columns={this.state.columns.map((col) => {
                      if (col.field === "action") {
                        return {
                          ...col,
                          renderCell: (params) => (
                            <button
                            className="btn detail_button"
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleEditRow(params.row)}
                            >
                                                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                fill="currentColor"
                                className="bi bi-info-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                              </svg>
                              <span> Edit</span>
                            </button>
                          ),
                        };
                      }
                      return col;
                    })}
                    getRowId={(row) => row.id}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    checkboxSelection
                    onRowSelectionModelChange={(newSelection) =>
                      this.handleSelectionChange(newSelection)
                    }
                  />
                </Box>
                <Dialog
                  open={this.state.openDialog}
                  onClose={this.handleCloseDialog}
                >
                  <DialogTitle>
                    {this.state.isEdit ? "Edit Row" : "Add New Row"}
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          label="Code"
                          name="code"
                          value={this.state.currentRow.code}
                          onChange={this.handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Name"
                          name="name"
                          value={this.state.currentRow.name}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Description"
                          name="description"
                          value={this.state.currentRow.description}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 1"
                          name="variable1"
                          value={this.state.currentRow.variable1}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 2"
                          name="variable2"
                          value={this.state.currentRow.variable2}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 3"
                          name="variable3"
                          value={this.state.currentRow.variable3}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 4"
                          name="variable4"
                          value={this.state.currentRow.variable4}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 5"
                          name="variable5"
                          value={this.state.currentRow.variable5}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 6"
                          name="variable6"
                          value={this.state.currentRow.variable6}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 7"
                          name="variable7"
                          value={this.state.currentRow.variable7}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Variable 8"
                          name="variable8"
                          value={this.state.currentRow.variable8}
                          onChange={this.handleInputChange}
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          type="text"
                          variant="standard"
                          label="Variable 9"
                          name="variable9"
                          value={this.state.currentRow.variable9}
                          onChange={this.handleInputChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <RedSwitch
                              checked={this.state.currentRow.flagActive}
                              onChange={this.handleInputChange}
                              name="flagActive"
                            />
                          }
                          label={
                            <Box
                              sx={{
                                color: this.state.currentRow.flagActive
                                  ? "coral"
                                  : "#000000",
                              }}
                            >
                              {this.state.currentRow.flagActive
                                ? "Active"
                                : "Inactive"}
                            </Box>
                          }
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <div className="popButton">
                      <div
                        className="popButton_in"
                        onClick={
                          this.state.isEdit
                            ? this.handleUpdateRow
                            : this.handleAddRow
                        }
                      >
                        <button className="btn popButton_in_button popButton_in_button_save">
                          {this.state.isEdit ? "Update" : "Add"}
                        </button>
                      </div>
                      <div
                        className="popButton_in_cancel"
                        onClick={this.handleCloseDialog}
                      >
                        <button className="btn popButton_in_button popButton_in_button_cancel">
                          Close
                        </button>
                      </div>
                    </div>
                  </DialogActions>
                </Dialog>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(Add_Parameter_edit);

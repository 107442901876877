import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import img_2 from "../../images/logonew1.png";
import img_logo from "../../images/rabbitlife-logo.png";
import Footer from "../Component/Footer";
import OTP_LOGIN from "./otp_login";
import HeaderLogin from "../Component/HeaderLogin";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      error_flag: false,
      loading: false,
      error_meg: "เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง",
    };
    this.submit = this.submit.bind(this);
  }
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  prevStep = () => {
    this.props.prevStep();
  };
  toISOLocal(date) {
    var localISOTime = new Date().toISOString().slice(0, 19);
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      localISOTime +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      ":" +
      pad(Math.abs(tzo) % 60)
    );
  }

  componentDidMount() {
    // console.log("componentDidMount");
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount ss");
  }
  submit(event) {
    // alert("dddd");
    event.preventDefault();
    let username = document.getElementById("username").value;
    // let password = document.getElementById('password').value;
    // console.log("username: " + username);

    this.setState({ loading: true });

    // console.log('password: ' + password);
    this.setState({
      username: username,
    });
    fetch(process.env.REACT_APP_API_BASEURL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        // password: password,
        // from: "web"
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        // console.log("retrun", res);
        // alert("sss")
        // window.location.href ='/otp';
        if (res.success) {
          if (res.act) {
            this.setState({
              step: 2,
            });
            this.setState({ loading: false });
          } else {
            // alert("กรุณาลงทะเบียนก่อนเข้าใช้ระบบ");
            this.setState({ error_flag: true, error_meg: res.payload.error });
            this.setState({ loading: false });
          }
        } else {
          this.setState({ error_flag: true, error_meg: res.payload.error });
          this.setState({ loading: false });
        }
      })
      .catch(function (res) {
        // console.log(res);
      });

    // if(token !='') {
    //     this.props.prevStep();
    // }
  }

  resend_otp = () => {
    let username = this.props.username;
    let password = this.props.password;

    this.setState({ username: username });
    this.setState({ password: password });
    // console.log("username: " + username);
    // console.log("password: " + password);

    fetch(process.env.REACT_APP_API_BASEURL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        //console.log(res)
        if (res.success === true) {
          alert("ระบบทำการส่ง OTP เรียบร้อยแล้วโปรดทำการตรวจสอบอีกครั้ง");
        } else {
          alert(res.payload.error);
        }
      })
      .catch(function (res) {
        // console.log(res);
      });
  };

  submit2 = () => {
    let otp = document.getElementById("otp").value;
    let username = this.state.username;
    // let password = document.getElementById('password').value;
    // console.log("username: " + username);
    // console.log("otp: " + otp);

    fetch(process.env.REACT_APP_API_BASEURL + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        otp: otp,
      }),
    })
      .then((res) => res.json())

      .then((res) => {
        // console.log("retrun", res);
        this.setState({ token: res.token });
        // console.log(res.token);
        // console.log("sss");

        // console.log("token", this.state.token);

        if (res.success !== false) {
          localStorage.clear();
          localStorage.setItem("customer_id", res.customer_id);
          localStorage.setItem("profile", res.profile);
          localStorage.setItem("first_name", res.first_name);
          localStorage.setItem("last_name", res.last_name);
          localStorage.setItem("token_login", res.token);
          
          // console.log("localStorage", localStorage.getItem('username'))
          // console.log("localStorage", localStorage.getItem('token_login'))
          // console.log("localStorage", localStorage.getItem("token_login"));

          window.location.href = "/home";
        } else {
          alert(res.payload.error);
          // window.location.href ='/';
        }
      })
      .catch(function (res) {
        // console.log(res);
      });
  };

  handleChange = (e) => {
    this.setState({
      PP: e.key,
    });
    // console.log(e.key);
    if (e.key === "Enter") {
      return false;
    }
  };

  render() {
    const { step, loading } = this.state;
    switch (step) {
      case 1:
        return (
          <div className="page-contanner">
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress
                  sx={{
                    animationDuration: "550ms",
                  }}
                  color="inherit"
                />
              </Backdrop>
            )}
            <HeaderLogin />
            <section className="sec-form" id="">
              <div className="container-fluid">
                <div className="row">
                  {/* <!-- <div className="col-md-4 col-sm-4 col-xs-12"></div> --> */}
                  <div className="col-lg-4 col-md- col-xs-12 p-5 mx-auto">
                    <div className="logo text-center">
                      <img className="img-fluid" src={img_2} alt="" />
                      <h4>iService</h4>
                    </div>

                    <form className="login-form mt-5" onSubmit={this.submit}>
                      <div className="form-group">
                        <label for="exampleInputEmail1" id="lbl_email">
                          เลขประจำตัวประชาชน หรือ เลขที่หนังสือเดินทาง
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          style={{ borderWidth: 1 }}
                          id="username"
                          placeholder="โปรดระบุ"
                          autoComplete="off"
                        />
                        {this.state.error_flag && (
                          <p className="text-danger">{this.state.error_meg}</p>
                        )}
                        <a
                          onClick={this.continue}
                          id="emailHelp"
                          className="form-text txt-sm"
                          style={{ cursor: 'pointer' }}
                        >
                          ลงทะเบียนสมาชิกใหม่
                        </a>
                      </div>

                      {/* <div className="form-group">
                                            <label for="exampleInputEmail1" id="lbl_email">รหัสผ่าน</label>
                                            <input type="password" className="form-control form-control-lg" id="password" aria-describedby="emailHelp" placeholder="โปรดระบุ"/>
                                            <a onClick={this.continue} id="emailHelp" className="form-text txt-sm">ลงทะเบียนสมาชิกใหม่</a>
                                        </div> */}
                      <button
                        type="submit"
                        className="btn btn-block btn-lg btn mt-3 cust-btn"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        เข้าสู่ระบบ
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      case 2:
        return <OTP_LOGIN username={this.state.username} />;
    }
  }
}

export default Login;

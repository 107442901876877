import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

const priorityOrder = [
  'เบอร์โทรศัพท์มือถือ',
  'เบอร์โทรศัพท์บ้าน',
  'เบอร์โทรศัพท์ที่ทำงาน',
  'เบอร์โทรศัพท์อื่นๆ'
];

const sortPersonNames = (names) => {
  return names.sort((a, b) => {
    return priorityOrder.indexOf(a) - priorityOrder.indexOf(b);
  });
};
const mapTypeToKey = (type) => {
  switch (type) {
    case 'เบอร์โทรศัพท์มือถือ':
      return 'mobile';
    case 'เบอร์โทรศัพท์บ้าน':
      return 'phone_home';
    case 'เบอร์โทรศัพท์ที่ทำงาน':
      return 'phone_office';
    case 'เบอร์โทรศัพท์อื่นๆ':
      return 'phone_other';
    default:
      return 'error';
  }
};

export default function MultipleSelectPhone({ onInputChange }) {
  const [personName, setPersonName] = useState([]);
  const [inputValues, setInputValues] = useState({});

  const [inputErrors, setInputErrors] = useState({}); // เก็บข้อผิดพลาดของฟิลด์

  

  const handleChange = (event, newValue) => {
    // console.log("OldInput")
    // console.log(inputValues)


 


    const sortedNames = sortPersonNames(newValue); 
    setPersonName(sortedNames);
  
    // อัปเดต inputValues ให้ตรงกับประเภทที่เลือก
    const updatedInputs = { ...inputValues };
    
    // เพิ่มเฉพาะค่าที่ถูกเลือกใหม่เข้าไปใน updatedInputs โดยไม่ลบค่าเดิม
    sortedNames.forEach((type) => {
      const key = mapTypeToKey(type);
      if (!(key in updatedInputs)) {
        updatedInputs[key] = (type === 'เบอร์โทรศัพท์บ้าน' || type === 'เบอร์โทรศัพท์ที่ทำงาน') ? ['', ''] : '';
      }
    });

    Object.keys(updatedInputs).forEach((key) => {
      const type = priorityOrder.find((t) => mapTypeToKey(t) === key);
      if (!newValue.includes(type)) {
        delete updatedInputs[key];
      }
    });
  
    // อัปเดต inputValues โดยเก็บเฉพาะคีย์ที่อยู่ใน newValue
  
    setInputValues(updatedInputs); // อัปเดตโดยไม่เคลียร์ค่าที่มีอยู่แล้ว
  };
  

  const handleInputChange = (type, index, event) => {
    const value = event.target.value;


    if (!validatePhoneNumber(value)) {
      alert("กรุณากรอกเฉพาะตัวเลขเท่านั้น");
      return;
    }
    const updatedValues = { ...inputValues };
    const key = mapTypeToKey(type);
  
    // ตรวจสอบว่าเป็นประเภทที่มีสองช่องหรือไม่ (เบอร์โทรศัพท์บ้านและที่ทำงาน)
    if (type === 'เบอร์โทรศัพท์บ้าน' || type === 'เบอร์โทรศัพท์ที่ทำงาน') {
      if (Array.isArray(updatedValues[key])) {
        const currentValues = [...updatedValues[key]];
        currentValues[index] = event.target.value;
        updatedValues[key] = currentValues;
      } else {
        // ถ้ายังไม่ได้ตั้งค่าเป็นอาร์เรย์ ให้ตั้งค่าเป็นอาร์เรย์ใหม่
        updatedValues[key] = index === 0 ? [event.target.value, ''] : ['', event.target.value];
      }
    } else {
      // สำหรับประเภทที่มีช่องเดียว
      updatedValues[key] = event.target.value;
    }
  
    setInputValues(updatedValues);
  };
  

  useEffect(() => {
    // console.log(onInputChange)
    // console.log("inputValues=======")
    // console.log(inputValues)
    onInputChange(inputValues);
    
  }, [inputValues, onInputChange]);

  const validatePhoneNumber = (value, type) => {
    const phoneRegex = /^[0-9]*$/;
    
    // ตรวจสอบเฉพาะตัวเลขและจำนวนหลักสำหรับมือถือและเบอร์อื่นๆ
    if ((type === 'เบอร์โทรศัพท์มือถือ' || type === 'เบอร์โทรศัพท์อื่นๆ') && (!phoneRegex.test(value) || value.length !== 10)) {
      return false;
    }
  
    // สำหรับเบอร์บ้านและที่ทำงาน ไม่จำกัดความยาว แต่ตรวจเฉพาะตัวเลข
    return phoneRegex.test(value);
  };
  

  

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="flex-start" style={{ marginBottom: '10px' }} > 
        <Grid item xs={12} md={6}       >
          <Autocomplete
            sx={{ borderRadius: '16px!important' }}
            multiple
            options={priorityOrder}
            value={personName}
            onChange={handleChange}
            freeSolo
            disableCloseOnSelect
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                  // onDelete={() => handleDelete(option)}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="ประเภท"
                placeholder="เลือกประเภทเบอร์โทรศัพท์"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px', // ทำให้ Autocomplete โค้ง
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {personName.map((type) => {
          const key = mapTypeToKey(type);

          return (
            <Box key={type} sx={{ mt: 2, width: '100%' }}>
              <InputLabel sx={{ fontFamily: '"Prompt-Thin", sans-serif !important', color: 'black' }}>
                {type}
              </InputLabel>
              {type === 'เบอร์โทรศัพท์บ้าน' || type === 'เบอร์โทรศัพท์ที่ทำงาน' ? (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <OutlinedInput
                    inputProps={{
                      maxLength: 9, // จำกัดให้กรอกได้ไม่เกิน 10 ตัว
                      inputMode: 'numeric', // แสดงคีย์บอร์ดตัวเลขบนมือถือ
                    }}
                      value={inputValues[key]?.[0] || ''}
                      onChange={(event) => handleInputChange(type, 0, event)}
                      fullWidth
                      placeholder={`กรอก ${type} `}
                      sx={{ borderRadius: '16px', mt: 1, height: '40px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <OutlinedInput
                     inputProps={{
                      maxLength: 3, // จำกัดให้กรอกได้ไม่เกิน 10 ตัว
                      inputMode: 'numeric', // แสดงคีย์บอร์ดตัวเลขบนมือถือ
                    }}
                      value={inputValues[key]?.[1] || ''}
                      onChange={(event) => handleInputChange(type, 1, event)}
                      fullWidth
                      placeholder={`ต่อ`}
                      sx={{ borderRadius: '16px', mt: 1, height: '40px' }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <OutlinedInput
                inputProps={{
                  maxLength: 10, // จำกัดให้กรอกได้ไม่เกิน 10 ตัว
                  inputMode: 'numeric', // แสดงคีย์บอร์ดตัวเลขบนมือถือ
                }}
                  value={inputValues[key] || ''}
                  onChange={(event) => handleInputChange(type, null, event)}
                  fullWidth
                  placeholder={`กรอก ${type}`}
                  sx={{ borderRadius: '16px', mt: 1, height: '40px' }}
                />
              )}
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
}

import React, { useState, useEffect } from "react";

import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
// CSS
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BenefitReceivingDetail.css";
// component
import PopUp from "../Component/Popup";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import { TableBenefitReceiving } from "./component/TableBenefitReceiving";
import { CustomInputText } from "../Component/CustomInputText";
import ReactDatePicker from "react-datepicker";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment";
import OverwriteMomentBE from "../../util/OverwriteMomentBE";
import icon_calendar from "../../Icon/calendar.png";
import { CustomInputDate } from "../Component/CustomInputDate";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalAlert } from "../Component/ModalAlert";
import { CustomInputAutocomplete } from "../Component/CustomInputAutocomplete";
import { mockDataBankName } from "./mockData";
import {
  searchBenefit
  , saveBenefit
} from "../BenefitReceivingHistory/BenefitReceivingService/benefitReceiveService";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";

// services
// import { getDataAPI } from "./services";
// icon
// import icon_ from "../../Icon/icon.png";

export const BenefitReceivingDetail = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const currentId = location?.state?.id;
  const currentData = location?.state?.data || {};
  const status = currentData.cus_check === "true";
  const currentVerification = currentData.cus_check == "true";
  const isUnder7 = currentData.age_range === "MST009";
  const isUnder20 = currentData.age_range === "MST010";

  const [bankName, setBankName] = useState(currentData.cuscf_bank);
  const [bankNumber, setBankNumber] = useState(currentData.cuscf_banknum);
  const [verificationStatus, setVerificationStatus] =
    useState(currentVerification);
  const [loading, setLoading] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    getBankName().then();
  }, []);


  const getImage = (row, index) => {
    // สร้าง doc_code ที่ต้องการ
    const docCodeToCheck = `${row.age_range}_${index}`;

    // ตรวจสอบว่ามี doc_code ตรงกับที่ต้องการหรือไม่
    const matchingFile = row.file.find(file => file.doc_code === docCodeToCheck);

    // คืนค่า file_name ถ้ามีหรือ "-" ถ้าไม่มี
    return matchingFile ? matchingFile.file_name : row.imageOwner || "-";
  };

  const onCheckVerification = (checked) => {
    setVerificationStatus(checked);
    if (checked) {
      // TODO: call API to check verification
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setTextModal("ตรวจสอบสำเร็จ");
        setOpenModal(true);
      }, 1500);
    }
  };
  const onSave = async () => {
    const result = await saveBenefit(currentId, bankName, bankNumber, verificationStatus);
    // console.log("SAVE DATA ID >>>", result);
    // TODO: call API to save data
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setTextModal("บันทึกข้อมูลสำเร็จ");
      setOpenModal(true);
    }, 1500);
  };

  const getBankName = async () => {
    const result = await getParameterAPI(14); // ดึงข้อมูลจาก API
    if (result.success) {
      const payload = result.payload;

      // Map ข้อมูลให้เหมาะสมสำหรับ Autocomplete
      const banksFormatted = payload.map((bank) => ({
        label: bank.name, // ค่าแสดงใน dropdown
        value: bank.name // ค่าใช้ส่งกลับหรือใช้งาน
      }));

      setBanks(banksFormatted); // เก็บข้อมูลธนาคารที่จัดรูปแบบแล้ว
    }
  };

  return (
    <div className="benefit-receiving-detail">
      {loading ? <PopUp /> : null}
      <Header_Home />
      <Header title="รายชื่อลูกค้าที่ลงทะเบียนรับเงินผลประโยชน์" />
      <ModalAlert
        title={textModal}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <section className="title-section">
            <span
              className="bold"
              style={{ fontSize: "24px", marginInline: "8px" }}
            >
              {"แก้ไขข้อมูล"}
            </span>
          </section>

          <section className="input-section">
            <Grid container spacing={2}>
              <Grid item xs={4} alignContent={"center"}>
                <span className=" bold">{"ชื่อ - นามสกุล"}</span>
              </Grid>
              <Grid item xs={8}>
                <CustomInputText
                  id="input-name"
                  value={currentData.cus_nm + " " + currentData.cus_ln || "-"}
                  disabled
                />
              </Grid>
              <Grid item xs={4} alignContent={"center"}>
                <span className="bold">{"เลขประจำตัวประชาชน"}</span>
              </Grid>
              <Grid item xs={8}>
                <CustomInputText
                  id="input-citizen-id"
                  value={currentData.cus_psnid || "-"}
                  disabled
                />
              </Grid>
              <Grid item xs={4} alignContent={"center"}>
                <span className="bold">{"เบอร์โทร"}</span>
              </Grid>
              <Grid item xs={8}>
                <CustomInputText
                  id="input-phone-number"
                  value={currentData.cus_tel || "-"}
                  disabled
                />
              </Grid>
              <Grid item xs={4} alignContent={"center"}>
                <span className="bold">{"ธนาคาร"}</span>
              </Grid>
              <Grid item xs={8}>
                <CustomInputAutocomplete
                  id="input-bank-name"
                  placeholder="ธนาคาร"
                  value={bankName}
                  onChange={(value) => {
                    // console.log(value);
                    setBankName(value.label);
                  }}
                  disabled={status}
                  options={banks}
                />
              </Grid>
              <Grid item xs={4} alignContent={"center"}>
                <span className="bold">{"เลขบัญชี"}</span>
              </Grid>
              <Grid item xs={8}>
                <CustomInputText
                  id="input-bank-number"
                  placeholder="เลขบัญชี"
                  value={bankNumber}
                  onChange={(e) => {
                    setBankNumber(e.target.value);
                  }}
                  disabled={status}
                />
              </Grid>
              <Grid item xs={4}>
                <span className="bold">{"KYC "}</span>
              </Grid>
              <Grid item xs={8}>
                <span style={{ paddingLeft: "12px" }}>
                  {currentData.cus_kyc || "-"}
                </span>
              </Grid>
              <Grid item xs={4} alignContent={"center"}>
                <span className="bold">{"ตรวจสอบ "}</span>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  disabled={status}
                  checked={verificationStatus}
                  onChange={(e) => onCheckVerification(e.target.checked)}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={7} lg={6}>
                <span className="bold">
                  {isUnder7
                    ? "สำเนาสูติบัตรของผู้เยาว์ เซ็นสำเนาถูกต้อง  "
                    : isUnder20
                      ? "สำเนาบัตรประชาชนของผู้เยาว์ เซ็นสำเนาถูกต้อง  "
                      : "สำเนาบัตรประชาชน เซ็นสำเนาถูกต้อง  "}
                </span>
              </Grid>
              <Grid item xs={12} sm={4} md={5} lg={6}>
                <a
                  className="text-orange text-long-hidden"
                  href={currentData.file.find(file => file.doc_code === `${currentData.age_range}_1`)?.link_files || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getImage(currentData, 1)}
                </a>
              </Grid>

              <Grid item xs={12} sm={8} md={7} lg={6}>
                <span className="bold">
                  {isUnder7
                    ? "รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์ "
                    : isUnder20
                      ? "รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้เยาว์"
                      : "รูปถ่ายหน้าตรงคู่กับบัตรประชาชน"}
                </span>
              </Grid>
              <Grid item xs={12} sm={4} md={5} lg={6}>
                <a
                  className="text-orange text-long-hidden"
                  href={
                    isUnder7
                      ? currentData.file.find(file => file.doc_code === `${currentData.age_range}_4`)?.link_files
                      : isUnder20
                        ? currentData.file.find(file => file.doc_code === `${currentData.age_range}_3`)?.link_files
                        : currentData.file.find(file => file.doc_code === `${currentData.age_range}_2`)?.link_files
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >                  {isUnder7
                  ? getImage(currentData, 4)
                  : isUnder20
                    ? getImage(currentData, 3)
                    : getImage(currentData, 2)}
                </a>
              </Grid>
              {isUnder7 || isUnder20 ? (
                <>
                  <Grid item xs={12} sm={8} md={7} lg={6}>
                    <span className="bold">
                      {
                        "สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม เซ็นสำเนาถูกต้อง "
                      }
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} md={5} lg={6}>
                    <a
                      className="text-orange text-long-hidden"
                      href={currentData.file.find(file => file.doc_code === `${currentData.age_range}_2`)?.link_files || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getImage(currentData, 2)}
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={8} md={7} lg={6}>
                    <span className="bold">
                      {"รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม "}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} md={5} lg={6}>
                    {isUnder7 || isUnder20 ? (
                      <a
                        className="text-orange text-long-hidden"
                        href={
                          isUnder7
                            ? currentData.file.find(file => file.doc_code === `${currentData.age_range}_3`)?.link_files
                            : currentData.file.find(file => file.doc_code === `${currentData.age_range}_4`)?.link_files
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {isUnder7
                          ? getImage(currentData, 3)
                          : getImage(currentData, 4)}
                      </a>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8} md={7} lg={6}>
                    <span className="bold">
                      {"สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง "}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4} md={5} lg={6}>
                    <a
                      className="text-orange text-long-hidden"
                      href={currentData.file.find(file => file.doc_code === `${currentData.age_range}_6`)?.link_files}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getImage(currentData, 6)}
                    </a>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={8} md={7} lg={6}>
                <span className="bold">{"สำเนาสมุดบัญชีเงินฝากธนาคาร "}</span>
              </Grid>
              <Grid item xs={12} sm={4} md={5} lg={6}>
                {isUnder7 || isUnder20 ? (
                  <a
                    className="text-orange text-long-hidden"
                    href={currentData.file.find(file => file.doc_code === `${currentData.age_range}_5`)?.link_files}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getImage(currentData, 5)}
                  </a>
                ) : (
                  <a
                    className="text-orange text-long-hidden"
                    href={currentData.file.find(file => file.doc_code === `${currentData.age_range}_3`)?.link_files}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getImage(currentData, 3)}
                  </a>
                )}
              </Grid>
            </Grid>
          </section>
          <section className="action-btn-section">
            <button
              style={{ fontSize: 18 }}
              onClick={() => onSave()}
              className="btn-fill-orange p-2 bold"
              disabled={status}
            >
              บันทึก
            </button>
            <button
              onClick={() => navigate(-1)}
              className="btn-fill-gray p-2 bold ml-2"
            >
              กลับ
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ScrollableCard from "./ScrollableCard";
// CSS
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./edit_address_v2.css";
// import { useSwipeable } from 'react-swipeable';
// component
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import PopUp from "../Component/Popup";
import { CustomCarousel } from "../Component/CustomCarousel";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputAutocomplete } from "../Component/CustomInputAutocomplete";
import { CustomInputFile } from "../Component/CustomInputFile";
import { Carousel } from "react-responsive-carousel";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { CardHeader } from "@mui/material";
import FileUploadWithPreviewAddress from "./FileUploadWithPreviewAddress";

// services
import {
  getPersonalAddressAPI,
  getPersonalAmphurAPI,
  getPersonalDistrictAPI,
  getPersonalPostalAPI,
  getPersonalProvinceAPI,
  getUserProfileAPI,
  updatePersonalAddressAPI,
  updatePersonalImageAPI,
} from "./PersonalService/personalService";
// icon
import icon_address_pin from "../../Icon/address-pin.png";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "react-modal";
import InputAdornment from "@mui/material/InputAdornment";
import iconSearch from "../../images/icon-search-2.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StoreContext } from "../../context/store";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Skeleton from "react-loading-skeleton";
import greenCheck from "../../Icon/green-circle-check.png";
import { Modal as BootstrapModal } from "react-bootstrap";
import remove from "../../images/remove.png";
import { Checkbox, FormControlLabel, Box } from "@mui/material";

const EditAddress2 = (props) => {
  let navigate = useNavigate();
  const { dataPolicyActive, updateDataPolicyActive } = useContext(StoreContext);
  const refCarousel = useRef(null);
  const cardRef = useRef(null);
  const [addressStep, setAddressStep] = useState(1);
  const [addressData, setAddressData] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState();
  const [khetOptions, setKhetOptions] = useState();
  const [kwangOptions, setKwangOptions] = useState();
  const [zipOptions, setZipOptions] = useState();
  const [loading, setLoading] = useState(true); // for get profile, get address, save edit, save image
  const [loadingProvince, setLoadingProvince] = useState(true); // for get province
  const [loadingKhet, setLoadingKhet] = useState(false); // for get Khet
  const [loadingKwang, setLoadingKwang] = useState(false); // for get Kwang
  const [loadingZip, setLoadingZip] = useState(false); // for get Zip
  const [activeDataModal, setActiveDataModal] = useState(dataPolicyActive ?? 0);
  const [idnvFlag, setIdnvFlag] = useState(
    localStorage.getItem("idnvFlag") || "N"
  );

  const Item = styled("div")(({ theme }) => ({
    // backgroundColor: '#fff',
    // border: '1px solid',
    // borderColor: '#ced7e0',
    // padding: theme.spacing(1),
    borderRadius: "2px",
    // textAlign: 'center',
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
      borderColor: "#444d58",
    }),
  }));

  // const [errorProfileData, setErrorProfileData] = useState(false);
  // const [errorAddressData, setErrorAddressData] = useState(false);
  // const [errorProvinceData, setErrorProvinceData] = useState(false);
  // const [errorKhetData, setErrorKhetData] = useState(false);
  // const [errorKwangData, setErrorKwangData] = useState(false);
  // const [errorZipData, setErrorZipData] = useState(false);
  // const [errorSaveAddressData, setErrorSaveAddressData] = useState(false);
  // const [errorSaveImageData, setErrorSaveImageData] = useState(false);

  // const [openErrorWarning, setOpenErrorWarning] = useState(false);
  // const [openErrorMissingAddress, setOpenErrorMissingAddress] = useState(false);
  // const [openErrorSaveAddress, setOpenErrorSaveAddress] = useState(false);
  // const [openErrorSaveImage, setOpenErrorSaveImage] = useState(false);
  const [addressNew, setAddressNew] = useState();

  const [place, setPlace] = useState();
  const [addressNumber, setAddressNumber] = useState();
  const [building, setBuilding] = useState();
  const [village, setVillage] = useState();
  const [moo, setMoo] = useState();
  const [soi, setSoi] = useState();
  const [street, setStreet] = useState();
  const [province, setProvince] = useState();
  const [khet, setKhet] = useState();
  const [kwang, setKwang] = useState();
  const [zip, setZip] = useState();
  const [inputErrors, setInputErrors] = useState([]);

  // const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [clickPre, setClickPre] = useState();
  const [clickForward, setClickForward] = useState();
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [completedModal, setCompletedModal] = useState(false);
  const [searchBasePolicy, setSearchBasePolicy] = useState("");
  const [option, setOption] = useState([]);
  const [toggleImg, setToggleImg] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
  const [isChecked, setIsChecked] = useState(false); // สถานะของ CheckAll
  const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

  const fileDescriptions = ["รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่บัตรประชาชน"];
  const [file, setFile] = useState(Array(fileDescriptions.length).fill(null));

  const breakPoints = [{ width: 1, itemsToShow: 1 }];
  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchBasePolicy("");
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleSearchBasePolicy = (e) => {
    setSearchBasePolicy(e);
  };

  const handleSelectSearch = (idx) => {
    setActiveDataModal(idx);
  };

  const styleDrive = {
    py: 0,
    width: "100%",
    maxWidth: "auto",
    // borderRadius: 2,
    // border: '1px solid',
    borderColor: "divider",
    backgroundColor: "background.paper",
  };

  // ----- function for Step 1 update address -----
  const getPersonalAddress = async () => {
    setLoading(true);
    // setErrorAddressData(false);
    getPersonalAddressAPI()
      .then((res) => {
        setLoading(false);
        if (res.success) {
          let option = [];
          res.payload.map((item, idx) => {
            let dataTmp = {
              label: item.policy_no,
              id: idx,
            };
            option.push(dataTmp);
          });
          setOption(option);
          // setAddressData(data);
          setAddressData(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorAddressData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  const getProvince = () => {
    setLoadingProvince(true);
    // setErrorProvinceData(false);
    getPersonalProvinceAPI()
      .then((res) => {
        setLoadingProvince(false);
        if (res.success) {
          setProvinceOptions(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingProvince(false);
        // setErrorProvinceData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  const getAmphur = (provinceCode) => {
    setLoadingKhet(true);
    // setErrorKhetData(false);
    getPersonalAmphurAPI(provinceCode)
      .then((res) => {
        setLoadingKhet(false);
        if (res.success) {
          setKhetOptions(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingKhet(false);
        // setErrorKhetData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  useEffect(() => {
    if (province && province.code) {
      getAmphur(province.code);
    }
  }, [province, listData]);

  useEffect(() => {
    if (addressData.length > 0) {
      setUpselectedAddress(0);
    }
  }, [addressData]);

  useEffect(() => {
    // console.log("useEffect khet/amphur change >>>", khet);
    if (province && khet && khet.code) {
      getDistrict(khet.code);
    }
  }, [khet]);

  const getDistrict = (khetCode) => {
    setLoadingKwang(true);
    // setErrorKwangData(false);
    getPersonalDistrictAPI(khetCode)
      .then((res) => {
        setLoadingKwang(false);
        if (res.success) {
          setKwangOptions(res.payload);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingKwang(false);
        // setErrorKwangData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  const getPostal = (khetCode, kwangCode) => {
    setLoadingZip(true);
    // setErrorZipData(false);
    getPersonalPostalAPI(khetCode, kwangCode)
      .then((res) => {
        setLoadingZip(false);
        if (res.success) {
          setZipOptions(res.payload);
          const activeZip = res.payload.filter((item) => item.active === true);
          setZip(activeZip[0].zip);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingZip(false);
        // setErrorZipData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
  };

  useEffect(() => {
    // console.log("useEffect kwang/district change >>>", kwang);
    if (province && khet && khet.code && kwang && kwang.code) {
      getPostal(khet.code, kwang.code);
    }
    // console.log(addressStep);
  }, [kwang]);

  const handleSelectedFile = (e) => {
    const inputFile = e.target.file;
    if (inputFile.length > 0) {
      const updatedFiles = [...file];
      updatedFiles[0] = Object.assign(inputFile[0], {
        preview: URL.createObjectURL(inputFile[0]),
      });
      setFiles(updatedFiles);
    }
  };

  const removeInputError = (inputId) => {
    setInputErrors((errors) => errors.filter((err) => err !== inputId));
  };

  const filteredOptions = option.filter((item) => {
    return searchBasePolicy.trim() === ""
      ? true
      : item.label.toLowerCase().includes(searchBasePolicy.toLowerCase());
  });

  const validateAddress = () => {
    const errors = [];
    if (!place) errors.push("input-place");
    if (!addressNumber) errors.push("input-address-number");
    if (!building) errors.push("input-building");
    if (!village) errors.push("input-village");
    if (!moo) errors.push("input-moo");
    if (!soi) errors.push("input-soi");
    if (!street) errors.push("input-street");
    if (!kwang) errors.push("input-kwang");
    if (!khet) errors.push("input-khet");
    if (!province) errors.push("input-province");
    if (!zip) errors.push("input-zip");
    setInputErrors(errors);
    if (errors.length > 0) {
      setToggleImg(false);
      setTextModal("กรุณากรอกข้อมูลให้ครบถ้วน");
      setCompletedModal(true);
    }
    return errors;
  };

  const validateImage = () => {
    let error = false;
    // console.log("file");
    // console.log(file);
    // ตรวจสอบว่า file มีค่า null ทุกตำแหน่งหรือไม่
    const allNull = file.every(item => item === null);

    if (allNull) {
      // console.log(file);
      error = true;
      setToggleImg(false);
      setTextModal(
        "เพื่อความรวดเร็วในการดำเนินการ โปรดแนบเอกสารให้ครบตามที่บริษัทฯ กำหนดค่ะ"
      );
      setCompletedModal(true);
    }

    return error;
  };

  // const saveAddress = () => {
  //   setLoading(true);
  //   // setErrorSaveAddressData(false);
  //   const errorsAddress = validateAddress();
  //   const errorImage = validateImage();
  //   if (
  //     selectedAddress.length > 0 &&
  //     errorsAddress.length === 0 &&
  //     errorImage === false
  //   ) {
  //     let tmpVillage = "";
  //     if (village != "" || village != "-") {
  //       tmpVillage = village;
  //     }
  //     const payload = { policies: [] };
  //     selectedAddress.forEach((item) => {
  //       payload.policies.push({
  //         policy_no: item.policy_no,
  //         policy_number: item.id,
  //         number: addressNumber,
  //         building: building + " " + tmpVillage,
  //         moo: moo,
  //         soi: soi,
  //         street: street,
  //         kwang: kwang.name, // recheck use code or name
  //         khet: khet.name,
  //         province: province.name,
  //         zip: zip,

  //         address1: item.address1,
  //         address2: item.address2,
  //         address3: item.address3,
  //         address4: item.address4,
  //         address5: item.address5,
  //       });
  //     });
  //     // console.log("payload to submit >>>", payload);
  //     updatePersonalAddressAPI(payload)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.success) {
  //           saveImage();
  //         } else {
  //           console.error("fail Save Address Data", res);
  //           // setErrorSaveAddressData(true);
  //           // setOpenErrorSaveAddress(true);
  //           setToggleImg(false);
  //           setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //           setCompletedModal(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setLoading(false);
  //         // setErrorSaveAddressData(true);
  //         // setOpenErrorSaveAddress(true);
  //         setToggleImg(false);
  //         setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //         setCompletedModal(true);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // };
  // const saveImage = () => {
  //   setLoading(true);
  //   // setErrorSaveImageData(false);

  //   const errorImage = validateImage();
  //   if (selectedAddress.length > 0 && errorImage === false) {
  //     let policy = "";
  //     selectedAddress.forEach((item, index) => {
  //       if (index === 0) policy = item.policy_no;
  //       else policy = policy + "," + item.policy_no;
  //     });
  //     const payload = new FormData();
  //     payload.append("image1", file[0], fileName);
  //     payload.append("policy", policy);
  //     payload.append("type", "1");

  //     // console.log("payload to submit >>>", payload);
  //     // for (var [key, value] of payload.entries()) {
  //     //   console.log("key, value to submit >>>", key, value);
  //     // }

  //     updatePersonalImageAPI(payload)
  //       .then((res) => {
  //         setLoading(false);
  //         if (res.success) {
  //           setToggleImg(true);
  //           setTextModal(
  //             "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
  //           );
  //           setCompletedModal(true);
  //           // alert(
  //           //   "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
  //           // );
  //           // navigate("/personal");
  //         } else {
  //           console.error("fail Save Image Data", res);
  //           // setErrorSaveImageData(true);
  //           // setOpenErrorSaveImage(true);
  //           setToggleImg(false);
  //           setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //           setCompletedModal(true);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setLoading(false);
  //         // setErrorSaveImageData(true);
  //         // setOpenErrorSaveImage(true);
  //         setToggleImg(false);
  //         setTextModal("ไม่สามารถบันทึกข้อมูลได้");
  //         setCompletedModal(true);
  //       });
  //   } else {
  //     setLoading(false);
  //   }
  // };

  const saveAddressAndImage = () => {
    setLoading(true);
    const errorsAddress = validateAddress();
    // const errorImage = validateImage();

    if (
      selectedAddress.length > 0 &&
      errorsAddress.length === 0
    ) {
      let tmpVillage = "";
      if (village !== "" && village !== "-") {
        tmpVillage = village;
      }
      const buildingDescription =
        building === "-" && village === "-" 
          ? "-" 
          : (building && building !== "-" ? " อาคาร " + building : "-") + 
            (tmpVillage && tmpVillage !== "-" ? " หมู่บ้าน " + tmpVillage : "-");


      const payload = { policies: [] };
      listData.forEach((item) => {
        payload.policies.push({
          policy_no: item.policy_no,
          policy_number: item.id,
          place: place,
          number: addressNumber,
          building: buildingDescription,
          moo: moo,
          soi: soi,
          street: street,
          kwang: kwang.name, // ตรวจสอบว่าใช้ code หรือ name
          khet: khet.name,
          province: province.name,
          zip: zip,

          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          address5: item.address5,
        });
      });

      // รวมการอัปเดตที่อยู่และรูปภาพ
      updatePersonalAddressAPI(payload)
        .then((res) => {
          if (res.success) {
            // เรียกฟังก์ชัน saveImage หลังจากบันทึกที่อยู่สำเร็จ
            if (idnvFlag === 'N') {
              let policy = listData.map((item) => item.policy_no).join(",");
              const imagePayload = new FormData();
              imagePayload.append("files", file[0], fileName);
              imagePayload.append("listID", res.result);
              imagePayload.append("type", "1");

              updatePersonalImageAPI(imagePayload)
                .then((imageRes) => {
                  setLoading(false);
                  if (imageRes.success) {
                    setToggleImg(true);
                    setTextModal(
                      "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
                    );
                    setCompletedModal(true);
                  } else {
                    console.error("fail Save Image Data", imageRes);
                    setToggleImg(false);
                    setTextModal("ไม่สามารถบันทึกรูปภาพได้");
                    setCompletedModal(true);
                  }
                })
                .catch((imageErr) => {
                  console.error("Image Save Error: ", imageErr);
                  setLoading(false);
                  setToggleImg(false);
                  setTextModal("ไม่สามารถบันทึกรูปภาพได้");
                  setCompletedModal(true);
                });
            } else {
              setLoading(false);

              setToggleImg(true);
              setTextModal(
                "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
              );
              setCompletedModal(true);
            }
          } else {
            console.error("fail Save Address Data", res);
            setLoading(false);
            setToggleImg(false);
            setTextModal("ไม่สามารถบันทึกข้อมูลได้");
            setCompletedModal(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setToggleImg(false);
          setTextModal("ไม่สามารถบันทึกข้อมูลได้");
          setCompletedModal(true);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(idnvFlag);
    // check user profile
    setLoading(true);
    getUserProfileAPI()
      .then((res) => {
        setLoading(false);
        if (res.success == "true") {
          if (res.payload.profile == "POS2") {
            // window.location.href = "/home";
            navigate("/home");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        // setErrorProfileData(true);
        // setOpenErrorWarning(true);
        setToggleImg(false);
        setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
        setCompletedModal(true);
      });
    // get Personal Address
    getPersonalAddress();
    // get Province data for dropdown
    getProvince();

    return () => {
      // console.log("useEffect return unmount >>>");
    };
  }, []);

  const renderOptionRow = (val, idx) => {
    const isActive = activeDataModal === idx;

    return (
      <div key={idx}>
        <Row
          onClick={!isActive ? () => handleSelectSearch(idx) : undefined}
          style={isActive ? { backgroundColor: "#FF5E43" } : {}}
          className={`gap-1 border-top border-bottom border-1 rounded-1 ${isActive ? "text-white" : "text-danger pointer"
            }`}
        >
          <Col className="px-3 d-flex flex-row justify-content-between">
            <span>{val.label}</span>
            {isActive && (
              <ArrowCircleLeftOutlinedIcon style={{ color: "white" }} />
            )}
          </Col>
        </Row>
      </div>
    );
  };

  const handleOwlChange = async (e) => {
    setActiveIndex(e);
    setActiveDataModal(e);
    updateDataPolicyActive(e);
    setUpselectedAddress(e);
  };

  const handleConfrimSearch = () => {
    setActiveIndex(activeDataModal);
    updateDataPolicyActive(activeDataModal);
    handleCloseModal();
    setUpselectedAddress(activeDataModal);
  };

  const setUpselectedAddress = (index) => {
    setSelectedAddress((prev) => {
      let findAddress = prev.find((i) => i.id === addressData[index].id);
      if (findAddress) {
        return prev.filter((ii) => ii.id !== findAddress.id);
      } else {
        return [...prev, addressData[index]];
      }
    });
  };

  const CustomCheckbox = styled(Checkbox)({
    color: "rgba(255, 94, 67, 0.5)",
    "&.Mui-checked": {
      color: "#FF5E43",
    },
    marginRight: "15px",
  });

  // const ScrollableCard = () => {

  //   if (listData.length > 0) {
  //     console.log(listData)
  //     // setFlgCanNext(true)
  //   }

  //   return (
  //     <Card
  //       // sx={{
  //       //   maxHeight: '490px',
  //       //   overflowY: 'auto',
  //       //   border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
  //       //   borderRadius: '12px', // ความโค้งของขอบ
  //       //   borderWidth: '2px',
  //       //   display: 'flex',
  //       //   flexDirection: 'column',
  //       //   boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  //       // }}
  //       ref={cardRef} sx={{ maxHeight: '490px', overflowY: 'auto' }}

  //     >
  //       <CardContent
  //         sx={{
  //           cursor: 'pointer',
  //           overflowY: 'auto', // เปิดการเลื่อนในแนวตั้ง
  //           boxSizing: 'border-box',
  //         }}
  //       >
  //         <Box>
  //           <FormControlLabel
  //             control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
  //             label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
  //           />
  //         </Box>

  //         <Typography
  //           variant="body2"
  //           sx={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} // ข้อความสีแดงพร้อม margin ด้านบน
  //         >
  //           *กรุณาเลือกกรมธรรม์
  //         </Typography>

  //         {addressData.map((_, index) => (
  //           <Box
  //             key={index}
  //             onClick={() => handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id)} // เมื่อ Box ถูกคลิก
  //             sx={{
  //               marginBottom: '15px',
  //               padding: '10px',
  //               transition: 'background-color 0.3s ease, border 0.3s ease',
  //               cursor: 'pointer',
  //               alignItems: 'center',
  //               justifyContent: 'space-between',
  //               border: selectedIndexes.includes(index)
  //             }}
  //           >
  //             <Card style={{ zIndex: 1000 }} sx={{
  //               backgroundColor: selectedIndexes.includes(index)
  //                 ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
  //                 : 'transparent', // พื้นหลังปกติ
  //               transition: 'background-color 0.3s ease, border 0.3s ease',
  //             }}>
  //               <CardHeader
  //                 title={
  //                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //                     <CustomCheckbox
  //                       checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
  //                       onChange={(e) => {
  //                         console.log("");
  //                         e.stopPropagation(); // หยุดไม่ให้เกิด event ที่ parent
  //                         // handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id); // ทำการคลิกเมื่อ checkbox ถูกเลือก
  //                       }}
  //                       sx={{ color: 'rgba(255, 94, 67, 0.8)' }} // ปรับสี
  //                     />
  //                     <Box sx={{ marginLeft: '10px' }}> {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
  //                       {"กรมธรรม์เลขที่ : " + _.policy_no}
  //                     </Box>
  //                   </Box>
  //                 }
  //                 sx={{
  //                   backgroundColor: 'rgba(255, 94, 67, 0.3)', // ปรับให้ใช้งาน rgba ถูกต้อง
  //                 }}
  //                 titleTypographyProps={{
  //                   sx: {
  //                     fontFamily: '"Prompt-Thin", sans-serif !important',
  //                     fontSize: '15px',
  //                     color: 'black',
  //                     marginLeft: '15px',
  //                   },
  //                 }}
  //               />
  //               <CardContent>
  //                 <Typography>
  //                   <Grid container spacing={1}>
  //                     <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
  //                       <span className="font-size-label-pol">ที่อยู่ :</span>
  //                     </Grid>
  //                     <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
  //                       <span className="font-size-label-pol">
  //                         {`${_.address1} ${_.address2} ${_.address3} `}{`${_.address3} ${_.address4}  `} {`${_.address5} `}
  //                       </span>
  //                     </Grid>
  //                   </Grid>
  //                 </Typography>
  //               </CardContent>
  //             </Card>
  //           </Box>
  //         ))}

  //       </CardContent>
  //     </Card>
  //   );
  // };

  const ScrollableCardInformation = () => {
    const cardRef = React.useRef(null);

    // const handlers = useSwipeable({
    //   onSwiped: (eventData) => {
    //     if (cardRef.current) {
    //       cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
    //     }
    //   },
    //   delta: 10, // กำหนดความไวต่อการสไวป์
    //   trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    // });

    return (
      <Card
        sx={{
          maxHeight: "490px",
          overflowY: "hidden",
          border: "1px solid rgba(255, 94, 67, 0.3)", // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: "12px", // ความโค้งของขอบ
          borderWidth: "2px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        ref={cardRef}
      // {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: "pointer",
            overflowY: "auto", // เปิดการเลื่อนในแนวตั้ง
            boxSizing: "border-box",
          }}
        >
          {listData.map((_, index) => (
            <Box
              key={index}
              // onClick={() => handleBoxClick(index, _.policy_no, _.id, _.mobile, _.phone_office, _.phone_office_to, _.phone_home, _.phone_home_to, _.phone_other)}
              sx={{
                marginBottom: "15px",
                padding: "10px",

                transition: "background-color 0.3s ease, border 0.3s ease",
                alignItems: "center", // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                justifyContent: "space-between", // แยกข้อความทางซ้ายกับไอคอนทางขวา
              }}
            >
              <Card>
                {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67, 0.3)' }} /> */}
                <CardHeader
                  title={"กรมธรรม์เลขที่ : " + _.policy_no}
                  sx={{
                    backgroundColor: "rgba(255, 94, 67, 0.3)", // ปรับให้ใช้งาน rgba ถูกต้อง
                  }}
                  titleTypographyProps={{
                    sx: {
                      fontFamily: '"Prompt-Thin", sans-serif !important',
                      fontSize: "15px",
                      color: "black",
                      marginLeft: "15px",
                      // width: '50px !important',
                    },
                  }}
                />
                <CardContent style={{ marginBottom: "0px" }}>
                  <List sx={styleDrive} style={{ marginBottom: "10px" }}>
                    <ListItem>
                      <ListItemText primary="ข้อมูลใหม่" />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                  </List>

                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                        <span className="font-size-label-pol">ที่อยู่ :</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {_.place && _.place !== "-" && `${_.place} `}
                          {_.number && _.number !== "-" && `เลขที่ ${_.number} `}
                          {_.building && _.building !== "-" && `${_.building} `}
                          {_.moo && _.moo !== "-" && `หมู่ที่ ${_.moo} `}
                          {_.soi && _.soi !== "-" && `ซอย ${_.soi} `}
                          {_.street && _.street !== "-" && `ถนน ${_.street} `}
                          {_.kwang?.name && _.kwang.name !== "-" && `แขวง/ตำบล ${_.kwang.name} `}
                          {_.khet?.name && _.khet.name !== "-" && `เขต/อำเภอ ${_.khet.name} `}
                          {_.province?.name && _.province.name !== "-" && `จังหวัด ${_.province.name} `}
                          {_.zip && _.zip !== "-" && `รหัสไปรษณีย์ ${_.zip}`}
                        </span>
                        
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>

                <CardContent>
                  <List sx={styleDrive} style={{ marginBottom: "10px" }}>
                    <ListItem>
                      <ListItemText primary="ข้อมูลเก่า" />
                    </ListItem>
                    <Divider variant="middle" component="li" />
                  </List>

                  <Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={2} lg={3} xl={3}>
                        <span className="font-size-label-pol">ที่อยู่ :</span>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <span className="font-size-label-pol">
                          {`${_.address1} ${_.address2} ${_.address3} `}
                          {`${_.address4}  `} {`${_.address5} `}
                        </span>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>

              <Box></Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className="edit-address">
      {loading ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      ) : null}
      <Header_Home />
      <Header title="เปลี่ยนที่อยู่รับเอกสาร" />
      <div className="container-footer-space">
        <section
          className="update-receiving-address"
          style={{ display: addressStep === 1 ? "block" : "none" }}
        >
          <div
            className="pb-2"
            style={{ display: addressStep === 1 ? "block" : "none" }}
          >
            <img
              className="address-icon"
              src={icon_address_pin}
              alt="address-icon"
            />
            <span className="bold">{"ที่อยู่รับเอกสาร"}</span>
          </div>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{ maxWidth: 800, margin: "0 auto" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-address-number"
                  label="สถานที่"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={place}
                  onChange={(e) => {
                    setPlace(e.target.value); // ตรวจสอบว่าฟังก์ชันนี้อัปเดต state อย่างถูกต้อง
                  }}
                  error={inputErrors.includes("input-place")} // ลองทดสอบโดยคอมเมนต์ออกชั่วคราวเพื่อเช็คว่าเกี่ยวกับ error หรือไม่
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-address-number"
                  label="เลขที่"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={addressNumber}
                  onChange={(e) => {
                    setAddressNumber(e.target.value); // ตรวจสอบว่าฟังก์ชันนี้อัปเดต state อย่างถูกต้อง
                  }}
                  error={inputErrors.includes("input-address-number")} // ลองทดสอบโดยคอมเมนต์ออกชั่วคราวเพื่อเช็คว่าเกี่ยวกับ error หรือไม่
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-village"
                  label="หมู่บ้าน"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={village}
                  onChange={(e) => {
                    setVillage(e.target.value);
                    removeInputError("input-village");
                  }}
                  error={inputErrors.includes("input-village")}
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-building"
                  label="อาคาร"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={building}
                  onChange={(e) => {
                    setBuilding(e.target.value);
                    removeInputError("input-building");
                  }}
                  error={inputErrors.includes("input-building")}
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <CustomInputText
                  id="input-building"
                  label="สถานที่"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={addressNew}
                  onChange={(e) => {
                    setAddressNew(e.target.value);
                    removeInputError("input-building");
                  }}
                  error={inputErrors.includes("input-building")}
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />

              </Grid> */}

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-moo"
                  label="หมู่ที่"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={moo}
                  onChange={(e) => {
                    setMoo(e.target.value);
                    removeInputError("input-moo");
                  }}
                  error={inputErrors.includes("input-moo")}
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-soi"
                  label="ซอย"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={soi}
                  onChange={(e) => {
                    setSoi(e.target.value);
                    removeInputError("input-soi");
                  }}
                  error={inputErrors.includes("input-soi")}
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-street"
                  label="ถนน"
                  placeholder="กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)"
                  value={street}
                  onChange={(e) => {
                    setStreet(e.target.value);
                    removeInputError("input-street");
                  }}
                  error={inputErrors.includes("input-street")}
                  inputProps={{
                    style: { fontSize: "16px" }, // ขนาดตัวอักษรปกติ
                    placeholder: "กรณีไม่มีข้อมูล ให้ใส่เครื่องหมายขีดกลาง (-)",
                  }}
                  sx={{
                    "& ::placeholder": {
                      fontSize: "11px", // ขนาด placeholder เล็กลง
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputAutocomplete
                  id="input-province"
                  label="จังหวัด"
                  placeholder="กรุณาเลือก"
                  value={province}
                  onChange={(value) => {
                    // console.log("prev province value >>>", province);
                    // console.log("selected province value >>>", value);
                    if (!value) {
                      setKhet(null);
                      setKwang(null);
                      setZip(null);
                    } else {
                      if (province && value.id !== province.id) {
                        setKhet(null);
                        setKwang(null);
                        setZip(null);
                        setKhetOptions([]);
                        setKwangOptions([]);
                        setZipOptions([]);
                      }
                    }
                    setProvince(value);
                    removeInputError("input-province");
                  }}
                  options={provinceOptions || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={loadingProvince}
                  error={inputErrors.includes("input-province")}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputAutocomplete
                  id="input-khet"
                  label="เขต/อำเภอ"
                  placeholder="กรุณาเลือก"
                  value={khet}
                  onChange={(value) => {
                    if (!value) {
                      setKwang(null);
                      setZip(null);
                    } else {
                      if (khet && value.id !== khet.id) {
                        setKwang(null);
                        setZip(null);
                        setKwangOptions([]);
                        setZipOptions([]);
                      }
                    }
                    setKhet(value);
                    removeInputError("input-khet");
                  }}
                  options={khetOptions || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={!province}
                  loading={loadingKhet}
                  error={inputErrors.includes("input-khet")}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputAutocomplete
                  id="input-kwang"
                  label="แขวง/ตำบล"
                  placeholder="กรุณาเลือก"
                  value={kwang}
                  onChange={(value) => {
                    if (!value) {
                      setZip(null);
                    } else {
                      if (kwang && value.id !== kwang.id) {
                        setZip(null);
                        setZipOptions([]);
                      }
                    }
                    setKwang(value);
                    removeInputError("input-kwang");
                    removeInputError("input-zip"); // Note: also remove zip error because we loading new zip from selected Kwang
                  }}
                  options={kwangOptions || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={!khet}
                  loading={loadingKwang}
                  error={inputErrors.includes("input-kwang")}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <CustomInputText
                  id="input-zip"
                  label="รหัสไปรษณีย์"
                  placeholder="รหัสไปรษณีย์"
                  value={zip}
                  onChange={(e) => {
                    setZip(e.target.value);
                    removeInputError("input-zip");
                  }}
                  disabled={!kwang}
                  loading={loadingZip}
                  error={inputErrors.includes("input-zip")}
                />
              </Grid>
            </Grid>
          </Box>
        </section>
        <br />
        {/* (addressStep === 2 :? ) <ScrollableCard /> */}

        <Box
          sx={{
            display: addressStep === 3 ? "flex" : "none",
            alignItems: "center",
          }}
          style={{ marginBottom: "10px" }}
        >
          <AssignmentTurnedInRoundedIcon
            fontSize={"large"}
            style={{ color: "rgb(255, 94, 67)" }}
          />
          <Typography style={{ fontSize: "20px", marginLeft: "10px" }}>
            ยืนยันข้อมูล
          </Typography>
        </Box>
        {addressStep == 1 ? (
          <ScrollableCard
            listData={listData}
            addressData={addressData}
            setListData={setListData}
            place={place}
            addressNumber={addressNumber}
            building={building}
            village={village}
            moo={moo}
            soi={soi}
            province={province}
            khet={khet}
            kwang={kwang}
            zip={zip}
            street={street}
          />
        ) : addressStep == 3 ? (
          <ScrollableCardInformation />
        ) : (
          <section
            className="upload-image-address"
            style={{ display: addressStep === 2 ? "block" : "none" }}
          >
            <div className={`address-image-card`}>
              <ul className="list-group list-group-flush">
                <div className="address-card-title">{"อัพโหลดไฟล์"}</div>
                <hr className="line" />
                <FileUploadWithPreviewAddress
                  setFile={setFile}
                  fileDescriptions={fileDescriptions}
                  file={file}
                />

                {/* <li className="address-card-body">
                  <div className="d-flex flex-row justify-content-between align-content-center align-items-center ">
                    <div>
                      <div className="bold">
                        {"รูปถ่ายหน้าตรงที่เป็นปัจจุบันคู่กับบัตรประชาชน"}
                      </div>
                      <div>{"รองรับไฟล์ .jpeg, .png, .pdf"}</div>
                    </div>
                    <CustomInputFile
                      id={"file"}
                      filename={fileName}
                      onChange={(e) => handleSelectedFile(e)}
                    />
                  </div>
                </li> */}
              </ul>
            </div>
          </section>
        )}

        <br />
        <section className="action-address">
          <Grid
            container
            spacing={2}
            justifyContent="center" // จัดตำแหน่งตรงกลางแนวนอน
            alignItems="center" // จัดตรงกลางแนวตั้ง
            sx={{ minHeight: "10vh" }} // ให้ Grid เต็มความสูงหน้าจอ
          >
            <Grid item xs={12} sm={6} md={2}>
              <button
                onClick={() => {
                  if (addressStep === 1) {
                    navigate(-1); // ย้อนกลับหน้า
                  } else if (addressStep === 3 && idnvFlag == "Y") {
                    setAddressStep(addressStep - 2);
                  } else {
                    setAddressStep(addressStep - 1);
                  }
                }}
                className="btn-address-back w-100 p-2 bold"
              >
                ย้อนกลับ
              </button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <button
                style={{ fontSize: 18 }}
                onClick={() => {
                  if (addressStep <= 2) {
                    let errorsAddress = validateAddress();
                    if (errorsAddress.length === 0) {
                      if (listData.length === 0) {
                        setToggleImg(false);
                        setTextModal(
                          "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนที่อยู่รับเอกสาร"
                        );
                        setCompletedModal(true);
                        // refCarousel.current.scrollIntoView();
                      } else if (addressStep == 1 && idnvFlag == "N") {
                        // console.log("selectedAddress value 2", selectedAddress);
                        setAddressStep(2);
                      } else if (addressStep == 1 && idnvFlag == "Y") {
                        // console.log("selectedAddress value 2", selectedAddress);
                        setAddressStep(3);
                      } else if (addressStep == 2) {
                        const errorImage = validateImage();

                        if (!errorImage) {
                          setAddressStep(3);

                        }
                        // setAddressStep(3);
                      }
                    }
                  } else if (addressStep == 3) {
                    saveAddressAndImage();
                  }
                }}
                className="btn-address-next w-100 p-2 bold"
              >
                {addressStep <= 2 ? "ต่อไป" : "บันทึก"}
              </button>
            </Grid>
          </Grid>
        </section>
      </div>
      <Modal
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        contentLabel="ModalPolicy"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="p-lg-2 p-3">
          <h3 style={{ color: "#FA0E0E", fontSize: "18px" }}>กรมธรรม์เลขที่</h3>
          <div className="d-grid mb-4">
            <TextField
              type="text"
              size="small"
              onChange={(e) => handleSearchBasePolicy(e.target.value)}
              className=""
              value={searchBasePolicy}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={iconSearch} height={20} width={20} alt="" />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  height: "35px",
                  "& fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&:hover fieldset": {
                    borderColor: "#FB5E3F",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#FB5E3F",
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "150px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {!filteredOptions.length == 0 ? (
              filteredOptions.map(renderOptionRow)
            ) : (
              <div className="text-center text-muted">
                ไม่มีข้อมูลที่ตรงกับการค้นหา
              </div>
            )}
          </div>
          <Row className="d-flex flex-row justify-content-evenly mt-3">
            {/* <Col className="bg-danger"> */}
            <button
              style={{ fontSize: 15 }}
              onClick={handleConfrimSearch}
              className="btn-checkout-premium w-25 p-2 bold"
            >
              ตกลง
            </button>
            <button
              style={{ fontSize: 15 }}
              onClick={handleCloseModal}
              className="btn-checkout-back w-25 p-2 bold"
            >
              ยกเลิก
            </button>
          </Row>
        </div>
      </Modal>
      {/* <div className="backdrop"></div> */}
      {/* <Modal isOpen={false} className="Modal" overlayClassName="Overlay">
        <div className="modal-container">
          <img
            src={greenCheck}
            alt="check-icon"
            style={{ width: "150px", height: "150px" }}
          />
          <div
            style={{
              marginTop: 20,
              textAlign: "center",
            }}
          >
            {
              "บริษัทได้รับทราบข้อมูลจากท่านแล้ว ท่านจะได้รับ SMS เพื่อดาวน์โหลดเอกสารการเปลี่ยนแปลงภายใน 5 วันทำการ ขอบคุณค่ะ"
            }
          </div>
        </div>
      </Modal> */}
      <BootstrapModal
        show={completedModal}
        backdrop="static"
        // size="md"
        // style={{}}
        className="backdrop-alert"
        contentClassName="modal-alert"
      >
        <BootstrapModal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={toggleImg ? greenCheck : remove}
              alt="check-icon"
              className="modal-image-size"
            />
          </div>

          <li
            className="body-list-group"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Col
              xl={12}
              md={12}
              xs={12}
              style={{
                display: "flex",
                marginTop: 5,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div className="font-modal">{textModal}</div>
            </Col>
          </li>
          <div className="d-flex justify-content-center flex-wrap">
            <button
              className="btn-checkout-modal w-20 p-2 bold"
              onClick={() => {
                if (toggleImg) {
                  navigate("/personal");
                } else {
                  setCompletedModal(false);
                }
              }}
            >
              กลับ
            </button>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default EditAddress2;

  import React, { useEffect, useState, useRef } from "react";
  import { useNavigate } from "react-router-dom";
  import { Grid, Backdrop, CircularProgress, Typography, Card, CardContent } from "@mui/material";
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  import { useSwipeable } from 'react-swipeable';
  import { CardHeader } from '@mui/material';
  import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';

  // CSS
  import "../../App.css";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "./edit_email_v2.css";
  // component
  import Header_Home from "../Component/Header_Home";
  import Header from "../Component/Header";
  import PopUp from "../Component/Popup";
  import { CustomCarousel } from "../Component/CustomCarousel";
  import { CustomInputTextEmail } from "../Component/CustomInputTextEmail";
  import ScrollableEmail from "./ScrollableEmail";
  // services
  import {
    getPersonalEmailAPI,
    getUserProfileAPI,
    updatePersonalEmailAPI,
  } from "./PersonalService/personalService";
  // icon
  import icon_email from "../../Icon/email_icon.png";
  import { Carousel } from "react-responsive-carousel";
  import AssignmentIcon from "@mui/icons-material/Assignment";
  import Skeleton from "react-loading-skeleton";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
  import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  import Modal from "react-modal";
  import TextField from "@mui/material/TextField";
  import InputAdornment from "@mui/material/InputAdornment";
  import iconSearch from "../../images/icon-search-2.png";
  import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
  import { Modal as BootstrapModal } from "react-bootstrap";
  import greenCheck from "../../Icon/green-circle-check.png";
  import remove from "../../images/remove.png";
  import { styled } from '@mui/system';
  import { Checkbox, FormControlLabel, Box } from '@mui/material';

  const EditEmail2 = (props) => {
    let navigate = useNavigate();

    const refCarousel = useRef(null);

    const [emailStep, setEmailStep] = useState(1);
    const [emailData, setEmailData] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState([]);
    const [loading, setLoading] = useState(true); // for get profile, get email, save edit email

    // const [errorProfileData, setErrorProfileData] = useState(false);
    // const [errorEmailData, setErrorEmailData] = useState(false);
    // const [errorSaveEmailData, setErrorSaveEmailData] = useState(false);

    // const [openErrorWarning, setOpenErrorWarning] = useState(false);
    // const [openErrorMissingEmail, setOpenErrorMissingEmail] = useState(false);
    // const [openErrorSaveEmail, setOpenErrorSaveEmail] = useState(false);

    const [email, setEmail] = useState();
    const [inputErrors, setInputErrors] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeDataModal, setActiveDataModal] = useState(0);
    const [clickPre, setClickPre] = useState();
    const [clickForward, setClickForward] = useState();
    const [currentItem, setCurrentItem] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [searchBasePolicy, setSearchBasePolicy] = useState("");
    const [option, setOption] = useState([]);
    const [completedModal, setCompletedModal] = useState(false);
    const [textModal, setTextModal] = useState("");
    const [toggleImg, setToggleImg] = useState(false);

    const [dataInformation, setDataInformation] = useState([]);

    const handleOpenModal = () => setOpenModal(true);

    const [checked, setChecked] = useState({});


    // const [listData, setListData] = useState([]);
    const [flgCanNext,setFlgCanNext]  =useState(false);
    const [flgNextScreen,setFlgNextScreen]  =useState(false);

    const [selectedIndexes, setSelectedIndexes] = useState([]); // เก็บ index ของ Box ที่ถูกเลือกเป็น array
    const [isChecked, setIsChecked] = useState(false); // สถานะของ CheckAll
    const [listData, setListData] = useState([]); // เก็บข้อมูลที่ถูกเลือก

    const CustomCheckbox = styled(Checkbox)({
      color: 'rgba(255, 94, 67, 0.5)',
      '&.Mui-checked': {
        color: '#FF5E43',
      },
      marginRight: '15px'
    });

    const handleCheckboxChange = (policy_no, oldEmail) => {
      setChecked((prevState) => ({
        ...prevState,
        [policy_no]: {
          ...prevState[policy_no], // คงค่าเดิมที่มีอยู่
          email: oldEmail,            // เพิ่มหรืออัปเดต email
          checked: !prevState[policy_no]?.checked, // สลับค่า checked
        },
      }));
    };

    const hasChecked = () => {
      // console.log(checked)
      return Object.values(checked).some(item => item.checked === true);
    };

    useEffect(() => {
      // console.log('Current email:', email); // ตรวจสอบค่า email ที่ใช้อยู่
      // console.log('Old listData:', listData); 
      const updatedList = listData.map((item) => ({
        index: item.index,
        policyNo: item.policyNo,
        oldEmail: item.oldEmail,
        email: email
      }));
      
      // console.log('new listData:', updatedList); 
      setListData(updatedList);
    }, [email]);

    useEffect(() => {

      if (emailData.length > 0) {
        setUpSelectEmail(0);
      }
    }, [emailData]);

    const handleCloseModal = () => {
      setOpenModal(false);
      setSearchBasePolicy("");
    };

    const filteredOptions = option.filter((item) => {
      return searchBasePolicy.trim() === ""
        ? true
        : item.label.toLowerCase().includes(searchBasePolicy.toLowerCase());
    });

    const handleConfrimSearch = () => {
      setActiveIndex(activeDataModal);
      // updateDataPolicyActive(activeDataModal);
      handleCloseModal();
      setUpSelectEmail(activeDataModal);
    };

    const handleSelectSearch = (idx) => {
      setActiveDataModal(idx);
    };

    const renderOptionRow = (val, idx) => {
      const isActive = activeDataModal === idx;

      return (
        <div key={idx}>
          <Row
            onClick={!isActive ? () => handleSelectSearch(idx) : undefined}
            style={isActive ? { backgroundColor: "#FF5E43" } : {}}
            className={`gap-1 border-top border-bottom border-1 rounded-1 ${isActive ? "text-white" : "text-danger pointer"
              }`}
          >
            <Col className="px-3 d-flex flex-row justify-content-between">
              <span>{val.label}</span>
              {isActive && (
                <ArrowCircleLeftOutlinedIcon style={{ color: "white" }} />
              )}
            </Col>
          </Row>
        </div>
      );
    };

    const handleSearchBasePolicy = (e) => {
      setSearchBasePolicy(e);
    };

    const setUpSelectEmail = (index) => {
      setSelectedEmail((prev) => {
        let findEmail = prev.find(
          (i) => i.policy_no === emailData[index].policy_no
        );
        if (findEmail) {
          return prev.filter((ii) => ii.policy_no !== findEmail.policy_no);
        } else {
          // return [...prev, emailData[index]]; // multi
          return [emailData[index]];
        }
      });
    };

    // ----- function for Step 1 update email -----
    const getPersonalEmail = () => {
      setLoading(true);
      // setErrorEmailData(false);
      getPersonalEmailAPI()
        .then((res) => {
          setLoading(false);
          if (res.success) {
            let option = [];
        
            res.payload.map((item, idx) => {
              let dataTmp = {
                label: item.policy_no,
                id: idx,
              };
              option.push(dataTmp);
            });
            setOption(option);
            // setEmailData(data);
            setEmailData(res.payload);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // setErrorEmailData(true);
          // setOpenErrorWarning(true);
          setToggleImg(false);
          setTextModal("ไม่สามารถบันทึกข้อมูลได้");
          setCompletedModal(true);
        });
    };

    // ----- function for Step 2 confirm -----

    const validateEmail = () => {
      const errors = [];
      if (!email) errors.push("input-email");
      setInputErrors(errors);
      if (errors.length > 0) {
        setToggleImg(false);
        setTextModal("กรุณากรอกข้อมูลให้ครบถ้วน");
        setCompletedModal(true);
      }
      return errors;
    };

    const saveEmail = () => {

      // console.log("payload to submit >>>", payload);

      setLoading(true);
      // setErrorSaveEmailData(false);
      const errorsEmail = validateEmail();
      if (errorsEmail.length === 0) {
        // TODO: recheck here
        const payload = { policies: [] };

        // วนลูปผ่าน data

        listData.map((item, idx) => {
          payload.policies.push({
            policy_no: item.policyNo.trim(), // ลบช่องว่างส่วนเกินใน policy_no
            email: email,
            old_email: item.oldEmail, // old_email เป็นค่าเดียวกับ email
          });
        });
        // for (const policy_no in checked) {
        //   if (checked[policy_no].checked) { // ตรวจสอบว่าค่า checked เป็น true
        //     payload.policies.push({
        //       policy_no: policy_no.trim(), // ลบช่องว่างส่วนเกินใน policy_no
        //       email: email,
        //       old_email: checked[policy_no].email, // old_email เป็นค่าเดียวกับ email
        //     });
        //   }
        // }
        // console.log("payload to submit >>>", payload);
        updatePersonalEmailAPI(payload)
          .then((res) => {
            setLoading(false);
            if (res.success) {
              setToggleImg(true);
              setTextModal("บริษัทได้รับทราบข้อมูลจากท่านแล้ว ขอบคุณค่ะ");
              setCompletedModal(true);
            } else {
              console.error("fail Save Email Data", res);
              // setErrorSaveEmailData(true);
              // setOpenErrorSaveEmail(true);
              setToggleImg(false);
              setTextModal("ไม่สามารถบันทึกข้อมูลได้");
              setCompletedModal(true);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            // setErrorSaveEmailData(true);
            // setOpenErrorSaveEmail(true);
            // alert("ไม่สามารถบันทึกข้อมูลได้");
            setToggleImg(false);
            setTextModal("ไม่สามารถบันทึกข้อมูลได้");
            setCompletedModal(true);
          });
      }
    };



    useEffect(() => {
      // check user profile
      setLoading(true);
      getUserProfileAPI()
        .then((res) => {
          setLoading(false);
          if (res.success == "true") {
            if (res.payload.profile == "POS2") {
              // window.location.href = "/home";
              navigate("/home");
            }
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          // setErrorProfileData(true);
          // setOpenErrorWarning(true);
          // alert("ไม่สามารถเข้าถึงข้อมูลได้");
          setToggleImg(false);
          setTextModal("ไม่สามารถเข้าถึงข้อมูลได้");
          setCompletedModal(true);
        });
      // get Personal Email
      getPersonalEmail();

      return () => {
        // console.log("useEffect return unmount >>>");
      };
    }, []);

    const handleOwlChange = async (e) => {
      setActiveIndex(e);
      setActiveDataModal(e);
      setUpSelectEmail(e);
      // updateDataPolicyActive(e);
    };

    const handleBoxClick = (index, policyNoVal, emailVal) => {
      setSelectedIndexes((prevIndexes) => {
        if (prevIndexes.includes(index)) {
          // ถ้าถูกเลือกแล้ว ให้ลบออกจาก array
          removeItemByPolicyNo(policyNoVal);
          return prevIndexes.filter(i => i !== index);
        } else {
          // ถ้ายังไม่ถูกเลือก ให้เพิ่มเข้าไปใน array

          const updatedList =  {
            policyNo: policyNoVal,
            oldEmail: emailVal,
            email: email
          }
          addItem(updatedList);
          return [...prevIndexes, index];
        }
      });
    };

    const addItem = (newItem) => {
      setListData((prevListData) => [...prevListData, newItem]);
    };

    const removeItemByPolicyNo = (policyNo) => {
      setListData((prevListData) => prevListData.filter(item => item.policyNo !== policyNo));
    };

    const removeAllItems = () => {
      setListData([]); // ตั้งค่า listData ให้เป็น array ว่าง
      setSelectedIndexes([]); // เคลียร์ selectedIndexes
    };

    const handleCheckAll = () => {
      if (isChecked) {
        removeAllItems(); // ลบข้อมูลทั้งหมด
      } else {
        const allIndexes = emailData.map((_, index) => index); // สร้าง array ของทุก index
        setSelectedIndexes(allIndexes);

        const updatedList = emailData.map((item) => ({
          policyNo: item.policy_no,
          oldEmail: item.email,
          email: email
        }));

        setListData(updatedList);
        // setDataInformation(updatedList) // ตั้งค่า listData ให้มีข้อมูลทั้งหมด
      }
      setIsChecked(!isChecked); // เปลี่ยนสถานะ CheckAll
    };

    const ScrollableCard = () => {
    const cardRef = React.useRef(null);


  

    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

  if(listData.length>0){
      // console.log(listData)
    setFlgCanNext(true)
  }
  

    return (
      <Card
        sx={{
          maxHeight: '490px',
          overflowY: 'hidden',
          border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: '12px', // ความโค้งของขอบ
          borderWidth: '2px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: 'pointer',
            overflowY: 'auto', // เปิดการเลื่อนในแนวตั้ง
            boxSizing: 'border-box',
          }}
        >
          <Box>
            <FormControlLabel
              control={<CustomCheckbox onClick={handleCheckAll} checked={isChecked} />}
              label={<Typography style={{ fontSize: '20px' }}>ทุกกรมธรรม์</Typography>}
            />
          </Box>
          <Typography
              variant="body2"
              sx={{ color: 'red', marginTop: '5px', marginLeft: '5px' }} // ข้อความสีแดงพร้อม margin ด้านบน
            >
              *กรุณาเลือกกรมธรรม์
            </Typography>

          {emailData.map((_, index) => (
          

            <Box
                key={index}
                onClick={() => handleBoxClick(index, _.policy_no, _.email)} // เมื่อ Box ถูกคลิก
                sx={{
                  marginBottom: '15px',
                  padding: '10px',

                  // border: selectedIndexes.includes(index)
                  //   ? '1px solid rgba(255, 94, 67, 0.8)' // สีขอบเข้มเมื่อถูกเลือก
                  //   : '1px solid rgba(255, 94, 67, 0.3)', // ขอบปกติ
                  // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // เงาที่ด้านล่าง
                  // borderRadius: '12px', // ความโค้งของขอบ 12px
                  // backgroundColor: selectedIndexes.includes(index)
                  // ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                  // : 'transparent', // พื้นหลังปกติ
                  transition: 'background-color 0.3s ease, border 0.3s ease',
                  cursor: 'pointer',
                  // display: 'flex', // จัดการแสดงผลให้เป็นแนวนอน (flexbox)
                  alignItems: 'center', // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                  justifyContent: 'space-between', // แยกข้อความทางซ้ายกับไอคอนทางขวา
                  border: selectedIndexes.includes(index)
                  //   ? '1px solid rgba(255, 94, 67, 0.8)' // สีขอบเข้มเมื่อถูกเลือก
                  //   : '1px solid rgba(255, 94, 67, 0.3)', // ขอบปกติ
                  // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // เงาที่ด้านล่าง
                }}
              >
                <Card style={{ zIndex: 1000 }} sx={{

                  backgroundColor: selectedIndexes.includes(index)
                    ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                    : 'transparent', // พื้นหลังปกติ
                  transition: 'background-color 0.3s ease, border 0.3s ease',
                }}>
                  {/* <CardHeader title={"กรมธรรม์เลขที่ : " + _.policy_no} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} /> */}
                  <CardHeader
                    title={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CustomCheckbox
                          checked={selectedIndexes.includes(index)} // ทำการเช็คตาม selectedIndexes
                          onChange={(e) => {
                            // console.log("");
                            e.stopPropagation(); // หยุดไม่ให้เกิด event ที่ parent
                            // handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id); // ทำการคลิกเมื่อ checkbox ถูกเลือก
                          }}
                          sx={{ color: 'rgba(255, 94, 67, 0.8)' }} // ปรับสี
                        />
                        <Box sx={{ marginLeft: '10px' }}> {/* เพิ่มระยะห่างระหว่าง checkbox และข้อความ */}
                          {"กรมธรรม์เลขที่ : " + _.policy_no}
                        </Box>
                      </Box>
                    }
                    sx={{
                      backgroundColor: 'rgba(255, 94, 67, 0.3)', // ปรับให้ใช้งาน rgba ถูกต้อง
                    }}
                    titleTypographyProps={{
                      sx: {
                        fontFamily: '"Prompt-Thin", sans-serif !important',
                        fontSize: '15px',
                        color: 'black',
                        marginLeft: '15px',
                      },
                    }}
                  />

                  <CardContent>
                    <Typography  >
                      <Grid container spacing={1} >
                        <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                          <span className="font-size-label-pol">อีเมล :</span>
                        </Grid>
                        <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                          <span className="font-size-label-pol">
                            {`${_.email}`}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          xl={1}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center', // จัดให้อยู่กึ่งกลางแนวนอน
                            alignItems: 'center',     // จัดให้อยู่กึ่งกลางแนวตั้ง
                          }}
                        >
                          {/* {selectedIndexes.includes(index) && (
                            <CheckCircleIcon
                              sx={{
                                color: 'rgba(255, 94, 67, 0.8)',
                              }}
                            />
                          )} */}
                        </Grid>


                      </Grid>





                    </Typography>
                  </CardContent>
                </Card >
                <Box >
                </Box>
              </Box>
          ))}
        </CardContent>
      </Card>
    );
  };


  const ScrollableCardInformation = () => {
  
    const cardRef = React.useRef(null);
  
    const handlers = useSwipeable({
      onSwiped: (eventData) => {
        if (cardRef.current) {
          cardRef.current.scrollTop += -eventData.deltaY; // เลื่อนการ์ดตามการสไวป์ (ขึ้นหรือลง)
        }
      },
      delta: 10, // กำหนดความไวต่อการสไวป์
      trackMouse: true, // ทำให้ใช้เมาส์ในการลากได้
    });

    return (
      <Card
        sx={{
          maxHeight: '490px',
          overflowY: 'hidden',
          border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
          borderRadius: '12px', // ความโค้งของขอบ
          borderWidth: '2px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
        ref={cardRef}
        {...handlers} // ผูกฟังก์ชันสไวป์ให้กับการ์ด
      >
        <CardContent
          sx={{
            cursor: 'pointer',
            overflowY: 'auto', // เปิดการเลื่อนในแนวตั้ง
            boxSizing: 'border-box',
          }}
        >
        


          {listData.map((_, index) => (

            <Box
                key={index}
                onClick={() => handleBoxClick(index, _.policy_no, _.address1, _.address2, _.address3, _.address4, _.address5, _.id)} // เมื่อ Box ถูกคลิก
                sx={{
                  marginBottom: '15px',
                  padding: '10px',

                  // border: selectedIndexes.includes(index)
                  //   ? '1px solid rgba(255, 94, 67, 0.8)' // สีขอบเข้มเมื่อถูกเลือก
                  //   : '1px solid rgba(255, 94, 67, 0.3)', // ขอบปกติ
                  // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // เงาที่ด้านล่าง
                  // borderRadius: '12px', // ความโค้งของขอบ 12px
                  // backgroundColor: selectedIndexes.includes(index)
                  // ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                  // : 'transparent', // พื้นหลังปกติ
                  transition: 'background-color 0.3s ease, border 0.3s ease',
                  cursor: 'pointer',
                  // display: 'flex', // จัดการแสดงผลให้เป็นแนวนอน (flexbox)
                  alignItems: 'center', // จัดให้ทุกอย่างอยู่กึ่งกลางในแนวตั้ง
                  justifyContent: 'space-between', // แยกข้อความทางซ้ายกับไอคอนทางขวา
                  // border: selectedIndexes.includes(index)
                  //   ? '1px solid rgba(255, 94, 67, 0.8)' // สีขอบเข้มเมื่อถูกเลือก
                  //   : '1px solid rgba(255, 94, 67, 0.3)', // ขอบปกติ
                  // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // เงาที่ด้านล่าง
                }}
              >
                <Card style={{ zIndex: 1000 }} sx={{

                  // backgroundColor: selectedIndexes.includes(index)
                  //   ? 'rgba(255, 94, 67, 0.1)' // สีพื้นหลังเข้มเมื่อถูกเลือก
                  //   : 'transparent', // พื้นหลังปกติ
                  transition: 'background-color 0.3s ease, border 0.3s ease',
                }}>
                  <CardHeader title={"กรมธรรม์เลขที่ : " + _.policyNo} sx={{ backgroundColor: 'rgb(255, 94, 67,0.3)' }} />


                  <CardContent>
                    <Typography  >
                      <Grid container spacing={1} >
                        <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                          <span className="font-size-label-pol">อีเมลใหม่ :</span>
                        </Grid>
                        <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                          <span className="font-size-label-pol">
                            {`${email}`}
                          </span>
                        </Grid>
                        


                      </Grid>
                      <Grid container spacing={1} >
                        <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                          <span className="font-size-label-pol">อีเมลเดิม :</span>
                        </Grid>
                        <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
                          <span className="font-size-label-pol">
                            {`${_.oldEmail}`}
                          </span>
                        </Grid>
                        


                      </Grid>






                    </Typography>
                  </CardContent>
                </Card >
                <Box >
                </Box>
              </Box>
          ))}
        </CardContent>
      </Card>
    );
  };


  const validateEmailRegex = (email) => {
    // console.log(email)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmailRegex(newEmail)) {
      if (!inputErrors.includes("input-email")) {
        setInputErrors((errors) => [...errors, "input-email"]);
        setErrorMessage("กรุณากรอกอีเมลให้ถูกต้อง");
      }
    } else {
      setInputErrors((errors) =>
        errors.filter((err) => err !== "input-email")
      );
      setErrorMessage('');
    }
  };




    const breakPoints = [{ width: 1, itemsToShow: 1 }];

    const CustomBox = styled(Box)(({ theme }) => ({
      // border: '1px solid rgba(255, 94, 67, 0.3)', // สีของขอบ #FF5E43 ที่โปร่งใส 30%
      // borderRadius: '12px', // ความโค้งของขอบ
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      borderWidth: '2px'
    }));

    // const CustomCheckbox = styled(Checkbox)({
    //   color: 'rgba(255, 94, 67, 0.5)',
    //   '&.Mui-checked': {
    //     color: '#FF5E43',
    //   },
    //   marginRight: '15px'
    // });

    const CustomCheckboxBypolicy = styled(Checkbox)({
      color: 'rgba(255, 94, 67, 0.5)',
      '&.Mui-checked': {
        color: '#FF5E43',
      },
      marginRight: '15px'
    });

    return (
      <div className="edit-email">
        {loading ? (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress
              sx={{
                animationDuration: "550ms",
              }}
              color="inherit"
            />
          </Backdrop>
        ) : null}
        <Header_Home />
        <Header title="เปลี่ยนอีเมล" />
        <div className="container-footer-space">

          {/* <ScrollableCard /> */}

        
          
          <section
            className="update-receiving-address"
            style={{ display: emailStep === 1 ? "block" : "none" }}
          >
            <div className="pb-2">
              <img className="email-icon" src={icon_email} alt="email-icon" />
              <span className="bold">{"อีเมล"}</span>
            </div>
            <div className="px-4">
              <CustomInputTextEmail
                id="input-email"
                // label="อีเมล"
                placeholder="อีเมล"
                value={email}
                onChange={handleEmailChange}
                // onChange={(e) => {
                //   // console.log("e.target.value >>>", e.target.value);
                //   // setEmail(e.target.value);
                //   // setInputErrors((errors) =>
                //   //   errors.filter((err) => err !== "input-email")
                //   // );
                // }}
                error={inputErrors.includes("input-email")}
                errorMessage={errorMessage}
              />
            </div>
          </section>
          <br/>

          <Box sx={{ display: emailStep === 2 ? "flex" : "none", alignItems: 'center' }} style={{marginBottom:'10px'}}>
            <AssignmentTurnedInRoundedIcon fontSize={'large'} style={{color:'rgb(255, 94, 67)'}}/>
            <Typography style={{ fontSize: '20px', marginLeft: '10px' }}>ยืนยันข้อมูล</Typography>
          </Box>
          {/* {emailStep === 1 ? <ScrollableCard /> : <ScrollableCardInformation />} */}
          {emailStep === 1 ? <ScrollableEmail listData={listData} emailData={emailData} setListData={setListData} email={email}  /> : <ScrollableCardInformation />}
          <section className="action-address">
            <Grid container 
            spacing={2}
            justifyContent="center" // จัดตำแหน่งตรงกลางแนวนอน
            alignItems="center"     // จัดตรงกลางแนวตั้ง
            sx={{ minHeight: '20vh' }} // ให้ Grid เต็มความสูงหน้าจอ
            >
              <Grid item xs={12} sm={6} md={2}>
              <button
                  onClick={() => {
                    // console.log(emailStep)
                    if (emailStep === 1) {
                      // exit page or back step
                      navigate(-1);
                    } else {
                      setEmailStep(1);
                    }
                  }}
                  className="btn-address-back w-100 p-2 bold"
                >
                  ย้อนกลับ
                </button>
              
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
              <button
                  style={{ fontSize: 18 }}
                  onClick={() => {
                    if (emailStep == 1) {
                        let checkEmail = validateEmailRegex(email)
                      // console.log(listData)

                      if (listData.length>0 && checkEmail) {
                        if(email==""){
                          setTextModal(
                            "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนอีเมล"
                          );
                        }
                      
                        setFlgNextScreen(true)
                        setEmailStep(2);
                        // }
                      } else {
                        setToggleImg(false);
                        // console.log(checkEmail)
                        if(!checkEmail){
                          setTextModal(
                            "รูปแบบอีเมลไม่ถูกต้อง"
                          );
                        }else{
                          setTextModal(
                            "กรุณาเลือกกรมธรรม์ที่ต้องการแจ้งเปลี่ยนอีเมล"
                          );
                        }
                      
                        setCompletedModal(true);
                        // refCarousel.current.scrollIntoView();
                      }
                    } else {
                      saveEmail();
                    }
                  }}
                  className="btn-address-next w-100 p-2 bold"
                >
                  {emailStep === 1 ? "ต่อไป" : "บันทึก"}
                </button>
              </Grid>
            
            </Grid>
          </section>
        </div>
        <Modal
          isOpen={openModal}
          onRequestClose={handleCloseModal}
          contentLabel="ModalPolicy"
          className="Modal"
          overlayClassName="Overlay"
        >
          <div className="p-lg-2 p-3">
            <h3 style={{ color: "#FA0E0E", fontSize: "18px" }}>กรมธรรม์เลขที่</h3>
            <div className="d-grid mb-4">
              <TextField
                type="text"
                size="small"
                onChange={(e) => handleSearchBasePolicy(e.target.value)}
                className=""
                value={searchBasePolicy}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={iconSearch} height={20} width={20} alt="" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: "#FFF",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "6px",
                    height: "35px",
                    "& fieldset": {
                      borderColor: "#FB5E3F",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FB5E3F",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FB5E3F",
                    },
                  },
                }}
              />
            </div>
            <div
              style={{
                maxHeight: "150px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {!filteredOptions.length == 0 ? (
                filteredOptions.map(renderOptionRow)
              ) : (
                <div className="text-center text-muted">
                  ไม่มีข้อมูลที่ตรงกับการค้นหา
                </div>
              )}
            </div>
            <Row className="d-flex flex-row justify-content-evenly mt-3">
              {/* <Col className="bg-danger"> */}
              <button
                style={{ fontSize: 15 }}
                onClick={handleConfrimSearch}
                className="btn-checkout-premium w-25 p-2 bold"
              >
                ตกลง
              </button>
              <button
                style={{ fontSize: 15 }}
                onClick={handleCloseModal}
                className="btn-checkout-back w-25 p-2 bold"
              >
                ยกเลิก
              </button>
            </Row>
          </div>
        </Modal>
        <BootstrapModal
          show={completedModal}
          backdrop="static"
          // size="md"
          // style={{}}
          className="backdrop-alert"
          contentClassName="modal-alert"
        >
          <BootstrapModal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={toggleImg ? greenCheck : remove}
                alt="check-icon"
                className="modal-image-size"
              />
            </div>

            <li
              className="body-list-group"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col
                xl={12}
                md={12}
                xs={12}
                style={{
                  display: "flex",
                  marginTop: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div className="font-modal">{textModal}</div>
              </Col>
            </li>
            <div className="d-flex justify-content-center flex-wrap">
              <button
                className="btn-checkout-modal w-20 p-2 bold"
                onClick={() => {
                  if (toggleImg) {
                    navigate("/personal");
                  } else {
                    setCompletedModal(false);
                  }
                }}
              >
                กลับ
              </button>
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      </div>
    );
  };

  export default EditEmail2;

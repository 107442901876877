import React, { useState, createContext } from "react";

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {
  const [informationEditBank, setInformationEditBank] = useState({
    bank_account_name: "", // ชื่อ-นามสกุล
    bank_account: "", // เลขธนาคาร
    bank_name: "", // ชื่อธนาคาร
    bank_account_file_name: "", // ชื่อไฟล์
    bank_account_file: "", //ไฟล์
    bank_account_flag_information: false,
  });

  const [informationEditPromptPayAccount, setInformationEditPromptPayAccount] =
    useState({
      prompt_pay_account_name: "", // ชื่อเจ้าของ
      prompt_pay_account: "", // พร้อมเพย์
      prompt_pay_flag_information: false,
    });

  const [infomationAccount, setInformationAccount] = useState({
    username: "",
    age: undefined,
    loadingInformation: false,
  });

  const [dataPolicyActive, setDataPolicyActive] = useState(0);

  const updateDataPolicyActive = (newData) => {
    setDataPolicyActive(newData);
  };

  const updateInformationAccount = (newData) => {
    setInformationAccount({ ...infomationAccount, ...newData });
  };

  const updateInformationEditBank = (newInformation) => {
    setInformationEditBank({ ...informationEditBank, ...newInformation });
  };

  const updateInformationEditPromptPay = (newInformation) => {
    // console.log("new", newInformation)
    setInformationEditPromptPayAccount({
      ...informationEditPromptPayAccount,
      ...newInformation,
    });
  };

  // console.log("Store Data : ", JSON.stringify(infomationAccount));
  return (
    <StoreContext.Provider
      value={{
        informationEditBank,
        updateInformationEditBank,
        informationEditPromptPayAccount,
        updateInformationEditPromptPay,
        infomationAccount,
        updateInformationAccount,
        dataPolicyActive,
        updateDataPolicyActive
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../LetterDigiCom/letterDigiCom-styles.css";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";
import { policyLoanEnquiry, policyLoanHistory } from "./PolicyLoanService/policyLoanService.js";
import Skeleton from "react-loading-skeleton";
import "moment/locale/th";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal, Col, Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CollapseTable from "./table.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CurrencyFormater } from "../../util";
import styles from "./policyLoan-styles.module.css";
import { CurrencyFormaterRound } from "../../util/function.js";
const PolicyLoan = () => {
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const [LoadingContent, setLoadingContent] = useState(true);
  const [activeTab, setActiveTab] = useState();
  const [cusTel, setCusTel] = useState("");
  const [dataLoanEnquiry, setDataLoanEnquiry] = useState('');
  const [dataLoanHistory, setDataLoanHistory] = useState(['']);
  let [searchParams, setSearchParams] = useSearchParams();
  const policyNo = searchParams.get("policyNo") || "";
  let navigate = useNavigate();

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };

  const policyLoanEnquiryAPI = async () => {
    const result = await policyLoanEnquiry(policyNo)
    setLoadingContent(false);
    setDataLoanEnquiry(result.data)
    // console.log(result.data);
  }

  const policyLoanHistoryAPI = async () => {
    const result = await policyLoanHistory(policyNo)
    // setLoadingContent(false);
    setDataLoanHistory(result.data)
    // console.log(result.data);
  }

  const goBack = () => {
    navigate('/policyList')
  }

  useEffect(() => {
    getTel()
    policyLoanEnquiryAPI()
    policyLoanHistoryAPI()
    // console.log(policyNo);
  }, []);

  return (
    <div>
      <Header_Home />
      <Header title="ตรวจสอบสิทธิกู้ยืม" />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}
      <div className="frame-loan p-4">
        <div className="container p-4">
          {LoadingContent && (
            <>
              <Row className="card-custom3 d-flex">
                <Col className="mt-3">
                  <Skeleton style={{ height: "30px" }} />
                  <div className="d-flex flex-row justify-content-between">
                    <Skeleton />
                  </div>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Col>
              </Row>
              <br />
            </>
          )}
          {!LoadingContent && dataLoanEnquiry && (
            <Tabs
              defaultActiveKey="home"
              id="fill-tab-example"
              fill
              className="mt-2"
              activeKey={activeTab}
              onSelect={handleSelect}
            >
              <Tab eventKey="home" title="เงินกู้ตามกรมธรรม์">
                <div className="card-custom">
                  <br/>
                  <ul className="list-group">
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 13,
                      }}
                    >
                      <span className="light">จำนวนเงินที่สามารถกู้ได้ :</span>
                      <span className="bold"> {CurrencyFormaterRound(dataLoanEnquiry.cashValueAvailableAmount)} บาท</span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 13,
                      }}
                    >
                      <span className="light">อัตราดอกเบี้ย :</span>
                      <span className="bold">
                      {dataLoanEnquiry.loanInterestRate} %
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 13,
                      }}
                    >
                      <span className="light">จำนวนเงินกู้ :</span>
                      <span className="bold">
                      {CurrencyFormater(dataLoanEnquiry.loanAmount)} บาท
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 13,
                      }}
                    >
                      <span className="light">จำนวนดอกเบี้ย :</span>
                      <span className="bold">
                      {CurrencyFormater(dataLoanEnquiry.interestAmount)} บาท
                      </span>
                    </li>
                    <li
                      className={styles.bodyListGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "10px",
                        fontSize: 13,
                      }}
                    >
                      <span className="light">จำนวนเงินกู้รวมดอกเบี้ยทั้งหมด :</span>
                      <span className="bold">
                      {CurrencyFormater(dataLoanEnquiry.totalLoanAmount)} บาท
                      </span>
                    </li>
                  </ul>
                  <br/>
                </div>
              </Tab>
              <Tab eventKey="profile" title="ประวัติการกู้ยืม">
                <div className="card-custom col-12">
                  <ul
                    className="list-group list-group-flush"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                      <CollapseTable data={dataLoanHistory} />
                    </div>

                    <br />
                  </ul>
                </div>
              </Tab>
            </Tabs>
          )}

          <div className="mt-3" style={{ marginLeft: "20px" }}>
            <span className="text-description">
              แบบฟอร์มสัญญาเงินกู้ตามกรมธรรม์ประกันภัยสามารถดาวน์โหลดได้ที่{" "}
              <a
                href="https://rabbitlife-my.sharepoint.com/:b:/g/personal/saijai_s_rabbitlife_co_th/EbjhdU-GD-BElXfUFebnuuwBXm4poEB79SmW69_6i3hlvg"
                target="_blank"
                rel="noopener noreferrer"
              >
                 ดาวน์โหลดแบบฟอร์ม
              </a>
            </span>
          </div>
          <div className="mt-3" style={{ marginLeft: "20px" }}>
            <span className="text-danger">
            *แบบประกันประเภทบำนาญ หากเริ่มรับเงินบำนาญแล้วจะไม่สามารถใช้สิทธิตามกรมธรรม์ได้ 
            สอบถามข้อมูลเพิ่มเติมได้ที่ ฝ่ายลูกค้าสัมพันธ์ {cusTel}
            </span>
          </div>


          <Row className="d-flex flex-column mt-2 mb-5">
            {/* {rcData.policyPaymentAllowFlag == "Y" &&
            rcData.paymentFlagIservice == "N" && ( */}
            {/* <Col className="d-flex justify-content-end px-5 gap-2">
              <button
                style={{ fontSize: 18 }}
                // onClick={goToPayment}
                className="btn-checkout-premium w-100 p-2 bold"
              >
                กู้เงิน
              </button>
            </Col> */}
            {/* )} */}
            <Col className="d-flex justify-content-start px-5">
              <button
                onClick={goBack}
                className="btn-coupon-back w-sm-100 w-lg-25 p-2 bold text-black"
              >
                ย้อนกลับ
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PolicyLoan;

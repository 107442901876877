import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import Header_Home from "../Component/Header_Home";
import moment from "moment";
import PopupDetail from "./PopupDetail";
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Transaction = function (a1, a3, a4, a5, a6, a7, a8) {
  const request_date = a1;
  const policy_no = a3;
  const insure_name = a4;
  const type = a5;
  const status_by = a6;
  const close_date = a7;
  const status = a8;
  return {
    request_date,
    policy_no,
    insure_name,
    type,
    status_by,
    close_date,
    status,
  };
};

const columns = [
  {
    label: "Request Date",
    key: "request_date", // String-based value accessors!
  },
  {
    label: "Policy Number",
    key: "policy_no", // String-based value accessors!
  },
  {
    label: "Insure Name",
    key: "insure_name", // String-based value accessors!
  },
  {
    label: "Type",
    key: "type", // String-based value accessors!
  },
  {
    label: "Taken/Close by",
    key: "status_by", // String-based value accessors!
  },
  {
    label: "Close Date",
    key: "close_date", // String-based value accessors!
  },
  {
    label: "Status",
    key: "status", // String-based value accessors!
  },
];

let data_tb = [
  { name: "ramesh", age: "12" },
  { name: "bill", age: "13" },
  { name: "arun", age: "9" },
  { name: "kathy", age: "21" },
];

class Update_request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      token: "",
      username: "",
      offset: 0,
      data: [],
      perPage: 20,
      currentPage: 0,
      pageCount: 0,
      step: 1,
      seen: false,
      data_close: [],
      css_export: "btn disabled-tag btn-noti margin-5",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDate2Change = this.handleDate2Change.bind(this);
    this.handleDateClosehange = this.handleDateClosehange.bind(this);
  }

  componentDidMount() {
    // console.log("token", localStorage.getItem("token_login"));
    // console.log("componentDidMount");
    // fetch(process.env.REACT_APP_API_BASEURL + "/api/user/getprofile",
    // {
    //     headers: {
    //     'authorization': localStorage.getItem('token_login'),
    //     'Content-Type': 'application/json'
    //     },
    //     method: "GET"
    // })
    // .then(res => res.json())

    // .then( res => {
    //     console.log("profile.check" , res)
    //     if(res.success == 'true'){
    //         if((res.payload.profile).toLowerCase() == 'customer'){
    //             window.location.href ='/home';
    //         }
    //     }
    // })
    // if(localStorage.getItem('profile') == 'customer'){
    //     window.location.href ='/home';
    // }
    document.getElementById("send_close_date").disabled = true;
    document.getElementById("export_btn").disabled = true;
  }

  componentDidUpdate() {
    // console.log("componentDidUpdate");
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount");
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.report();
      }
    );
  };
  handleDateChange(date) {
    this.setState({
      date1: date,
    });
  }
  handleDate2Change(date) {
    this.setState({
      date2: date,
    });
  }

  handleDateClosehange(date) {
    this.setState({
      close_date: date,
    });
  }
  report() {
    const { data_close, date1, date2 } = this.state;

    //  const {  data_close } = this.state;
    for (let item of data_close) {
      document.getElementById(item).checked = false;
    }
    // console.log("date1", date1);
    // console.log("date2", date2);
    let date_1 = moment(date1).format("YYYY-MM-DD");

    // CONCERN old logic have send time '16:59:59'
    // let date_2 = moment(date2).format('YYYY-MM-DD') + ' 16:59:59'
    let date_2 = moment(date2).format("YYYY-MM-DD");
    //let date_2 = moment(date2).format('YYYY-MM-DD')+ ' 23:59:59'

    // console.log("date1", date1);
    // console.log("date2", date2);
    let category = document.getElementById("category").value;
    let date_type = document.getElementById("date_type").value;
    // console.log(
    //   process.env.REACT_APP_API_BASEURL +
    //     "/api/request/list?start=" +
    //     date_1 +
    //     "&end=" +
    //     date_2 +
    //     "&category=" +
    //     category +
    //     "&date_type=" +
    //     date_type
    // );
    fetch(
      process.env.REACT_APP_API_BASEURL +
        "/api/request/list?start=" +
        date_1 +
        "&end=" +
        date_2 +
        "&category=" +
        category +
        "&date_type=" +
        date_type,
      {
        headers: {
          authorization: localStorage.getItem("token_login"),
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => res.json())

      .then((res) => {
        // console.log(res);
        //console.log(res)
        const data = res.payload;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        // console.log(slice.length, "จำนวน");
        if (data.length > 1) {
          this.setState({ showing_all: true });
        }

        let data_csv = [];

        for (let item of data) {
          // = Card("3", "H");
          let data_row = Transaction(
            item.request_date
              ? moment(item.request_date).format("DD/MM/YYYY HH:mm")
              : "-",
            '=""' + item.policy_number + '""',
            //    '\''+ item.policy_number +'',
            item.insurer_name,
            item.subject,
            item.status_by,
            item.close_date
              ? moment(item.close_date).format("DD/MM/YYYY")
              : "-",
            item.status
          );
          data_csv.push(data_row);
        }
        // console.log(total_fyc,res.payload.total_fyc)
        data_tb = data_csv;

        const postData = slice.map((item) => (
          <tr className="focus-table">
            <td className="text-center">
              <input
                type="checkbox"
                onChange={this.handleChange("id_request")}
                value={item.id}
                id={item.id}
                name={item.id}
                className="margin-left-px mt-1"
                disabled={item.close_date ? true : false}
              ></input>
            </td>
            <td className="text-center">
              {" "}
              {item.request_date
                ? moment(item.request_date).format("DD/MM/YYYY HH:mm")
                : "-"}
            </td>
            <td> {item.policy_number}</td>
            <td> {item.insurer_name}</td>
            <td> {item.subject}</td>
            <td> {item.status_by}</td>
            <td>
              {" "}
              {item.close_date
                ? moment(item.close_date).format("DD/MM/YYYY")
                : "-"}{" "}
            </td>
            <td>
              {" "}
              <a
                className={
                  item.status == "New"
                    ? "btn btn-new"
                    : item.status == "Taken"
                    ? "btn btn-taken"
                    : item.status == "Closed"
                    ? "btn btn-closetype"
                    : ""
                }
              >
                {item.status}
              </a>
            </td>
            <td> {item.link ? <a href={item.link}>เอกสาร</a> : "-"}</td>
            <td>
              {" "}
              {item.id_detail ? (
                <a
                  onClick={() =>
                    this.togglePop(
                      item.id_detail,
                      item.type,
                      item.policy_number
                    )
                  }
                  className="link-event"
                >
                  รายละเอียด
                </a>
              ) : (
                "-"
              )}
            </td>
          </tr>
        ));
        this.setState({
          payload: res.payload,
          pageCount: Math.ceil(data.length / this.state.perPage),
          postData,
          css_export: "btn btn-export btn-noti margin-5",
        });
        // console.log(
        //   "data",
        //   res.payload,
        //   res.payload.length,
        //   this.state.postData,
        //   postData
        // );
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  handleChange = (input) => (e) => {
    const { data_close } = this.state;
    let close_date_array = data_close;
    // console.log(e.target.value);
    if (document.getElementById(e.target.value).checked == true) {
      close_date_array.push(e.target.value);
    } else {
      var index = close_date_array.indexOf(e.target.value);
      if (index !== -1) {
        close_date_array.splice(index, 1);
      }
    }
    if (close_date_array.length > 0) {
      document.getElementById("send_close_date").disabled = false;
    }
    this.setState({ [input]: e.target.value, data_close: close_date_array });
    // console.log({ [input]: e.target.value });
  };

  send() {
    const { data_close, date_close } = this.state;
    let date_close_value = moment(date_close).format("YYYY-MM-DD");
    let array_send = [];

    // console.log(data_close.length);
    if (data_close.length == 0) {
      alert("กรุณาเลือกรายการที่จะส่ง close date");
      return false;
    } else if (!date_close_value && date_close_value !== undefined) {
      alert("กรุณากรอก close date");
      return false;
    } else {
      for (let item of data_close) {
        let close = {};
        close.id = item;
        close.close_date = date_close_value;
        array_send.push(close);
      }

      let save = {};
      save.close_date = array_send;
      // console.log(save);
      let close_date_array = [];
      fetch(process.env.REACT_APP_API_BASEURL + "/api/request/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token_login"),
        },
        body: JSON.stringify(save),
      })
        .then((res) => res.json())

        .then((res) => {
          //console.log(res)
          console.log("body_save", res);
          if (res.success) {
            this.setState({ data_close: close_date_array });
            alert("บันทึกข้อมูลเรียบร้อย");
            this.report();
          }
        })
        .catch(function (res) {
          // console.log(res);
        });
    }
  }

  taken() {
    const { data_close } = this.state;
    let array_send = [];
    // console.log(data_close.length);
    if (data_close.length == 0) {
      alert("กรุณาเลือกรายการที่จะส่ง taken");
      return false;
    } else {
      for (let item of data_close) {
        let close = {};
        close.id = item;
        array_send.push(close);
      }

      let save = {};
      save.taken = array_send;
      // console.log(save);
      let close_date_array = [];
      fetch(process.env.REACT_APP_API_BASEURL + "/api/request/update_taken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token_login"),
        },
        body: JSON.stringify(save),
      })
        .then((res) => res.json())

        .then((res) => {
          //console.log(res)
          // console.log("body_save", res);

          if (res.success) {
            this.setState({ data_close: close_date_array });
            alert("บันทึกข้อมูลเรียบร้อย");
            this.report();
          }
        })
        .catch(function (res) {
          // console.log(res);
        });
    }
  }
  togglePop = (value, type, policy_no) => {
    // console.log(value, type, policy_no);
    this.setState({
      seen: !this.state.seen,
      id_detail: value,
      type_req: type,
      policy_no: policy_no,
    });
    // console.log(this.state.seen);
  };

  render() {
    const { postData, css_export } = this.state;
    return (
      <div>
        {this.state.seen ? (
          <PopupDetail
            toggle={this.togglePop}
            id={this.state.id_detail}
            type={this.state.type_req}
            policy_no={this.state.policy_no}
          />
        ) : null}
        <Header_Home />

        <section className="d-flex justify-content-center align-items-center h-100 my-5">
          <div className="container-fluid  container-margin">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body header-card">
                    <div className="row col-12 ">
                      <div className="col-12 text-center">
                        <label htmlFor="subject" className="text-center mt-2">
                          <strong>ปรับปรุงการใช้สิทธิ</strong>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="card-body list-group list-group-flush p-0 pt-4">
                    <div className="row col-12 ">
                      <div className="row col-12 mb-4 ml-4 pt-1">
                        <div
                          id="export"
                          className="col-2 margin-l-auto text-align-right"
                        >
                          <CSVLink
                            data={data_tb}
                            id="export_btn"
                            filename="Report.csv"
                            headers={columns}
                            className={css_export}
                          >
                            Export File
                          </CSVLink>
                        </div>
                      </div>

                      <div className="row col-12 mb-4 ml-4 pt-1">
                        <div className="col-lg-2 col-md-6 pt-1">
                          <select
                            style={{ borderWidth: 1 }}
                            className="form-select menu-style  margin-left-10-px list-unstyled"
                            name="date_type"
                            id="date_type"
                          >
                            <option value="1">Request Date</option>
                            <option value="2">Close Date</option>
                          </select>
                        </div>

                        {/* <div className="col-1 text-center pt-2">
                                                   ถึง
                                                </div> */}
                        <div className="row col-lg-6 col-md-6 pt-1">
                          <div className="col-lg-5 col-md-6">
                            <DatePicker
                              style={{ borderWidth: 1 }}
                              className="form-control border-1"
                              id="date1"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.date1}
                              onChange={this.handleDateChange}
                            />
                            {/* <input  type="date" className="form-control" id="date1"  />   */}
                          </div>
                          <div className="col-lg-1 col-md-3 col-sm-4 text-center pt-1">
                            ถึง
                          </div>
                          <div className="col-lg-5 col-md-6">
                            <DatePicker
                                style={{ borderWidth: 1 }}
                              className="form-control border-1"
                              id="date2"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.date2}
                              onChange={this.handleDate2Change}
                            />
                            {/* <input  type="date" className="form-control" id="date2" />  */}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 pt-1">
                          <select
                          style={{ borderWidth: 1 }}
                            className="form-select menu-style  margin-left-10-px list-unstyled"
                            name="category"
                            id="category"
                          >
                            <option value="0">All</option>
                            <option value="1">ประเภทงานเปลี่ยนแปลง</option>
                            <option value="2">
                              ประเภทการใช้สิทธิตามกรมธรรม์
                            </option>
                          </select>
                        </div>
                        <div className="col-lg-1 col-md-3 col-sm-4 text-center pt-2">
                          <a
                            onClick={() => this.report()}
                            className="btn btn-lg btn-blue"
                          >
                            เรียก
                          </a>
                        </div>
                      </div>
                      <div className="row col-12 mb-4 ml-4">
                        <div className="col-lg-2 pt-1">
                          <a
                            onClick={() => this.taken()}
                            id="send_close_date"
                            className="btn btn-lg btn-blue"
                          >
                            Taken Owner
                          </a>
                        </div>
                        <div className="row col-lg-10 col-md-12 text-center">
                          <div className="col-lg-2 col-md-6 text-center pt-2">
                            Close Date
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <DatePicker
                              className="form-control border-1"
                              id="date_close"
                              dateFormat="dd/MM/yyyy"
                              selected={this.state.close_date}
                              onChange={this.handleDateClosehange}
                            />
                            {/* <input  type="date" className="form-control" id="date_close" />   */}
                          </div>

                          <div className="col-1 pt-1">
                            <a
                              onClick={() => this.send()}
                              id="send_close_date"
                              className="btn btn-lg btn-blue"
                            >
                              Close
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="table-responsive ">
                          <table
                            className="table table-class white"
                            id="table-id"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">select</th>
                                <th className="text-center">Request Date</th>
                                <th>Policy Number</th>
                                <th>Insure Name</th>
                                <th>Type</th>
                                <th>Taken/Close by</th>
                                <th>Close Date</th>
                                <th>Status</th>
                                <th>Link</th>
                                <th>Detail</th>
                              </tr>
                            </thead>
                            {/* <Listcustomer list_products={this.state.product} token={this.state.token}></Listcustomer> */}
                            <tbody>{postData}</tbody>
                          </table>
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body App">
                    <a
                      href="/home"
                      type="button"
                      className="btn btn-outline-rabbit mr-3"
                    >
                      ย้อนกลับ
                    </a>
                    {/* <a  onClick={() => this.next()} className="btn btn-lg btn-blue">ต่อไป</a> */}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
          </div>
        </section>
      </div>
    );
  }
}

export default Update_request;

import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CSVLink } from 'react-csv';
import moment from 'moment';
import React, { useContext, useEffect, useState, Component } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@material-ui/core";
import { CustomInputText } from "../Component/CustomInputText";
import { CustomInputSelect } from "../Component/CustomInputSelect";
import Skeleton from "react-loading-skeleton";
import { CustomInputDate } from "../Component/CustomInputDate";
import { Modal, Form, Col } from 'react-bootstrap';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import successGif from "../../images/successGiff.gif"
import { StoreContext } from "../../context/store";
import "./requestReport-styles.css";
import { TableDetail } from "./component/TableDetail";

// import { mockDataHistory, mockDataTest } from "./mockData";
import {
  TextField,
  Grid,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import {
  searchRequest,
  updateTaken,
  updateCloseDate,
  getdetail
} from "../RequestReport/RequestService/requestService";


const RequestReport = () => {
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: "#FFA500",
          },
        },
      },
    },
  });
  const {
    informationEditBank,
    updateInformationEditBank,
    informationEditPromptPayAccount,
    updateInformationEditPromptPay,
  } = useContext(StoreContext);

  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [LoadingContent, setLoadingContent] = useState(false);
  const [rowPayment, setRowPayment] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDisabledTaken, setIsDisabledTaken] = useState(true);
  const [isDisabledClose, setIsDisabledClose] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const [policyNumber, setPolicyNumber] = useState('');
  const [insurerName, setInsurerName] = useState('');
  const [type, setType] = useState('0');
  const [status, setStatus] = useState('');
  const [requestStartDate, setRequestStartDate] = useState('');
  const [requestEndDate, setRequestEndDate] = useState('');
  const [closeStartDate, setCloseStartDate] = useState('');
  const [closeEndDate, setCloseEndDate] = useState('');
  const [mockDataHistory, setMockDataHistory] = useState([]);
  const [dataTable, setDataTable] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalClose, setShowModalClose] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [selectedDetail, setSelectedDetail] = React.useState({});


  const [closeDate, setCloseDate] = useState('');





  useEffect(() => {

  }, []);


  const getRequest = async () => {
    setLoadingContent(true);
    const result = await searchRequest(
      policyNumber,
      insurerName,
      requestStartDate ? moment(requestStartDate).format('YYYY-MM-DD') : '',
      requestEndDate ? moment(requestEndDate).format('YYYY-MM-DD') : '',
      closeStartDate ? moment(closeStartDate).format('YYYY-MM-DD') : '',
      closeEndDate ? moment(closeEndDate).format('YYYY-MM-DD') : '',
      type,
      status
    ); setTimeout(() => {
      // setPage(0);
      setDataTable(result.payload);
      setLoadingContent(false);
    }, 500);
  };

  const handleUpdateCloseDate = async () => {
    if (selectedRows.length === 0) {
      alert("กรุณาเลือกรายการที่จะส่ง");
      return;
    }

    if (!closeDate) {
      alert("กรุณากรอก close date");
      return;
    }

    const body = selectedRows.map(id => ({
      id,
      close_date: moment(closeDate).format('YYYY-MM-DD') // หรือวันที่ที่คุณต้องการ
    }));

    const firstName = localStorage.getItem('first_name'); // ดึงข้อมูล firstName จาก localStorage
    const lastName = localStorage.getItem('last_name');   // ดึงข้อมูล lastName จาก localStorage

    try {
      const response = await updateCloseDate(body, firstName, lastName);
      // console.log('Update successful', response);
      setSelectedRows([])
      setCloseDate('')
      toggleModalClose2();
      setIsDisabledTaken(true)
      setIsDisabledClose(true)
      toggleModalOpen()
      getRequest();
    } catch (error) {
      console.error('Error occurred while updating', error);
      alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
    }
  };
  const handleUpdateTaken = async () => {
    if (selectedRows.length === 0) {
      alert("กรุณาเลือกรายการที่จะส่ง");
      return;
    }

    // สร้าง body ตามรูปแบบที่ต้องการ
    // console.log(selectedRows);
    const body = selectedRows.map(id => ({ id }));


    try {
      const response = await updateTaken(body); // ส่ง body ไปยังฟังก์ชัน updateTaken
      // console.log('Update successful', response);
      setSelectedRows([])
      toggleModalOpen()      // รีเฟรชข้อมูลหลังจากการอัปเดตสำเร็จ
      setIsDisabledTaken(true)
      setIsDisabledClose(true)
      getRequest();

    } catch (error) {
      console.error('Error occurred while updating', error);
      alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล');

    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChange = (event, id) => {
    let updatedSelectedRows;

    if (event.target.checked) {
      // เพิ่ม id เข้าไปใน selectedRows ใหม่
      updatedSelectedRows = [...selectedRows, id];
    } else {
      // เอา id ออกจาก selectedRows ใหม่
      updatedSelectedRows = selectedRows.filter((rowId) => rowId !== id);
    }

    // อัปเดต state ของ selectedRows
    setSelectedRows(updatedSelectedRows);

    // ปรับสถานะของปุ่ม Taken ทันทีโดยใช้ updatedSelectedRows
    setIsDisabledTaken(updatedSelectedRows.length === 0);
    setIsDisabledClose(updatedSelectedRows.length === 0);
    // console.log(updatedSelectedRows);

  };
  const headers = [
    { label: "Request Date", key: "request_date" },
    { label: "Policy Number", key: "policy_number" },
    { label: "Insurer Name", key: "insurer_name" },
    { label: "Subject", key: "subject" },
    { label: "Status By", key: "status_by" },
    { label: "Close Date", key: "close_date" },
    { label: "Status", key: "status" }
  ];

  const generateCsvData = (data) => {
    let data_csv = [];

    data.forEach(item => {
      const data_row = {
        request_date: item.request_date ? moment(item.request_date).format('DD/MM/YYYY HH:mm') : '-',
        policy_number: `=""${item.policy_number}""`,  // ป้องกันปัญหาการแสดงหมายเลข policy
        insurer_name: item.insurer_name,
        subject: item.subject,
        status_by: item.status_by,
        close_date: item.close_date ? moment(item.close_date).format('DD/MM/YYYY') : '-',
        status: item.status
      };
      data_csv.push(data_row);
    });

    return data_csv;
  };

  const data_csv = generateCsvData(dataTable);

  const onSearch = () => {
    getRequest().then();
  };

  const onClear = () => {
    setPolicyNumber('');
    setInsurerName('');
    setType('0');
    setStatus('');
    setRequestStartDate('');
    setRequestEndDate('');
    setCloseStartDate('');
    setCloseEndDate('');
    setDataTable([]);
    setSelectedRows([])
  };


  const toggleModalOpen = () => {
    setShowModal(true);
  };
  const toggleModalClose = () => {
    setShowModal(false);
  };
  const toggleModalOpen2 = () => {
    setShowModalClose(true);
  };
  const toggleModalClose2 = () => {
    setShowModalClose(false);
  };
  const toggleModalDetailOpen = (id_detail, type, policy_number,subject) => {
    setSelectedDetail({ id_detail, type, policy_number,subject }); // เก็บค่าที่เลือก
    setShowModalDetail(true); // เปิด Modal
  };
  

  const toggleModalDetailClose = () => {
    setShowModalDetail(false);
  };
  return (
    <div>
      <Header_Home />
      <Header title="ปรับปรุงสถานะคำร้อง" />
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <section className="filter-action-section" style={{ display: "flex", justifyContent: "flex-end", paddingInline: '32px' }}>
            <div id="export" className="margin-l-auto text-align-right">
              <CSVLink
                data={data_csv}
                headers={headers}
                filename="Report.csv"
                style={{ fontSize: 18 }}
                className="btn-fill-export  p-2 bold"
              >
                Export File
              </CSVLink>
            </div>
          </section>

          <Grid container spacing={1} justifyContent="center" style={{ paddingInline: '32px', marginTop: '10px' }}>
            <Grid item xs={12} md={6} alignContent={"center"}>
              <CustomInputText
                id="input-Policy"
                label="Policy Number"
                placeholder="กรมธรรม์เลขที่"
                style={{ textAlign: "center" }} // จัดให้อยู่ตรงกลาง
                value={policyNumber} // ค่า value จาก state
                onChange={(e) => setPolicyNumber(e.target.value)} // ฟังก์ชัน onChange
              />
            </Grid>
            <Grid item xs={12} md={6} alignContent={"center"}>
              <CustomInputText
                id="input-name"
                label="Insure Name"
                placeholder="ชื่อผู้เอาประกัน"
                style={{ textAlign: "center" }} // จัดให้อยู่ตรงกลาง
                value={insurerName} // ค่า value จาก state
                onChange={(e) => setInsurerName(e.target.value)} // ฟังก์ชัน onChange
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
            <Grid item xs={12} md={6}>
              <CustomInputSelect
                id="input-Type"
                label="Type"
                placeholder="ประเภทงานเปลี่ยนแปลง"
                value={type} // ค่า value จาก state
                onChange={(e) => setType(e.target.value)} // ฟังก์ชัน onChange
                options={[
                  { value: "0", label: "All" },
                  { value: "1", label: "ประเภทงานเปลี่ยนแปลง" },
                  { value: "2", label: "ประเภทการใช้สิทธิตามกรมธรรม์" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInputSelect
                id="input-Status"
                label="Status"
                placeholder="สถานะ"
                value={status} // ค่า value จาก state
                onChange={(e) => setStatus(e.target.value)} // ฟังก์ชัน onChange
                options={[
                  { value: "New", label: "New" },
                  { value: "Taken", label: "Taken" },
                  { value: "Closed", label: "Closed" },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
            <Grid item xs={6} md={3} alignContent={"center"}>
              <CustomInputDate
                id="Request-start-date"
                label="Request Date"
                placeholder="เริ่มต้น"
                value={requestStartDate} // ค่า value จาก state
                onChange={(value) => setRequestStartDate(value)} // ฟังก์ชัน onChange
              />
            </Grid>
            <Grid item xs={6} md={3} alignContent={"end"}>
              <CustomInputDate
                id="Request-end-date"
                label=" "
                placeholder="สิ้นสุด"
                value={requestEndDate} // ค่า value จาก state
                onChange={(value) => setRequestEndDate(value)} // ฟังก์ชัน onChange
              />
            </Grid>
            <Grid item xs={6} md={3} alignContent={"center"}>
              <CustomInputDate
                id="Close-start-date"
                label="Close Date"
                placeholder="เริ่มต้น"
                value={closeStartDate} // ค่า value จาก state
                onChange={(value) => setCloseStartDate(value)} // ฟังก์ชัน onChange
              />
            </Grid>
            <Grid item xs={6} md={3} alignContent={"end"}>
              <CustomInputDate
                id="Close-end-date"
                label=" "
                placeholder="สิ้นสุด"
                value={closeEndDate} // ค่า value จาก state
                onChange={(value) => setCloseEndDate(value)} // ฟังก์ชัน onChange
              />
            </Grid>
          </Grid>
          <div className="App">
            <br />
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={onSearch}
                className="btn-fill-orange  p-2 bold"
              >
                ค้นหา
              </button>
              <button
                onClick={onClear}
                className="btn-fill-gray p-2 bold ml-2">
                ล้าง
              </button>
            </section>
            <br />
            <hr
              style={{
                margin: "10px 10px 15px 5px",
                border: "solid 1px",
                borderTop: 0.1,
              }}
            />
            <section className="filter-action-section" style={{ display: "flex", justifyContent: "flex-start", paddingInline: '10px' }}>
              <button
                style={{ fontSize: 18 }}
                onClick={handleUpdateTaken}
                className={isDisabledTaken ? "btn-fill-gray p-2 bold" : "btn-fill-orange p-2 bold"}
                disabled={isDisabledTaken}
              >
                Taken Owner
              </button>
              <button
                style={{ fontSize: 18 }}
                onClick={toggleModalOpen2}
                className={isDisabledClose ? "btn-fill-gray p-2 bold ml-2" : "btn-fill-orange p-2 bold ml-2"}
                disabled={isDisabledClose}
              >
                Close
              </button>
            </section>
            <br />
            <div style={{ position: 'relative' }}>
              {LoadingContent && (
                <div className="loading-content">
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                </div>
              )}
              <ThemeProvider theme={theme}>
                <TableContainer
                  style={{ height: "auto" }}
                  component={Paper}
                >
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#e7e7e786" }}>
                        <TableCell className="font-new" align="center" style={{ width: "3%" }}>
                          Select
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                          Policy Number
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "30%" }}>
                          Insure Name
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "30%" }}>
                          Type
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "20%" }}>
                          Request Date
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "20%" }}>
                          Close Date
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                          Taken/Close by
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "20%" }}>
                          Status
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                          Link
                        </TableCell>
                        <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                          Detail
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!(dataTable.length === 0) ? (
                        dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, idx) => {
                          const seq = idx + 1 + page * rowsPerPage;
                          return (
                            <TableRow key={idx}>
                              <TableCell className="font-new" align="center" style={{ width: "3%" }}>
                                <input
                                  type="checkbox"
                                  value={item.id}
                                  id={item.id}
                                  name={item.id}
                                  onChange={(event) => handleCheckboxChange(event, item.id)}
                                  className="checkbox-large margin-left-px mt-1"
                                  disabled={item.close_date ? true : false}
                                  checked={selectedRows.includes(item.id)}
                                />
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                                {item.policy_number}
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "30%" }}>
                                {item.insurer_name}
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "30%" }}>
                                {item.subject}
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "20%" }}>
                                {item.request_date ? moment(item.request_date).format('DD/MM/YYYY HH:mm') : '-'}
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "20%" }}>
                                {item.close_date ? moment(item.close_date).format('DD/MM/YYYY') : '-'}
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "20%" }}>
                                {item.status_by ? item.status_by : '-'}
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                                <span
                                  style={{
                                    color: item.status === 'New' ? '#FA0E0E' :
                                      item.status === 'Taken' ? '#FF5E43' :
                                        item.status === 'Closed' ? '#12B71D' : 'black'
                                  }}
                                >
                                  {item.status}
                                </span>
                              </TableCell>
                              <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                                {item.link ? (
                                  <a href={item.link} target="_blank" rel="noopener noreferrer">Document</a>
                                ) : (
                                  '-'
                                )}
                              </TableCell>

                              <TableCell className="font-new" align="center" style={{ width: "10%" }}>
                                {item.id_detail ? (
                                  <a
                                    className="link-event"
                                    onClick={() => toggleModalDetailOpen(item.id_detail, item.type, item.policy_number,item.subject)}
                                  >
                                    Detail
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableCell align="center" colSpan={12}>
                          <span className="font-new">ไม่พบข้อมูล</span>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dataTable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </ThemeProvider>
            </div>



          </div>
        </div>
        <br />
            <br />
            <br />
        <Modal show={showModal} onHide={toggleModalClose} centered>
          <Modal.Header closeButton>
            <h5 className="bold" style={{ color: "#ff5e43" }} >Taken</h5>
            {/* <Modal.Title>ตรวจสอบสำเร็จ</Modal.Title> */}
          </Modal.Header>
          <hr
            style={{
              margin: "0px 10px 0px 5px",
              border: "solid 2px",
              borderTop: 0.1,
              color: "#ff5e43"
            }}
          />
          <Modal.Body >
            <img
              src={successGif}
              alt={"confirmGif"}
              style={{
                height: "auto",
                marginLeft: '35%',
                width: '30%',
              }}
            />
            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Col xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <h3 className="bold">ระบบบันทึกข้อมูลเรียบร้อย</h3>
              </Col>
            </li>
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={toggleModalClose}
                className="btn-fill-orange  p-2 bold"
              >
                ตกลง
              </button>
            </section>


          </Modal.Body>
        </Modal>
        <Modal show={showModalClose} onHide={toggleModalClose2} centered>
          <Modal.Header closeButton>
            <h5 className="bold" style={{ color: "#ff5e43" }} >Close Date</h5>
            {/* <Modal.Title>ตรวจสอบสำเร็จ</Modal.Title> */}
          </Modal.Header>
          <hr
            style={{
              margin: "0px 10px 0px 5px",
              border: "solid 2px",
              borderTop: 0.1,
              color: "#ff5e43"
            }}
          />
          <Modal.Body >
            <Grid item xs={12} md={6} alignContent={"center"} style={{ marginTop: '10px' }}>
              <CustomInputDate
                id="input-closeDate"
                label="Close Date"
                // placeholder="เริ่มต้น"
                value={closeDate}
                onChange={(value) => setCloseDate(value)} // ฟังก์ชัน onChange

              />
            </Grid>
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={handleUpdateCloseDate}
                className="btn-fill-orange  p-2 bold"
              >
                ตกลง
              </button>
            </section>


          </Modal.Body>
        </Modal>
        <Modal show={showModalClose} onHide={toggleModalClose2} centered>
          <Modal.Header closeButton>
            <h5 className="bold" style={{ color: "#ff5e43" }} >Close Date</h5>
            {/* <Modal.Title>ตรวจสอบสำเร็จ</Modal.Title> */}
          </Modal.Header>
          <hr
            style={{
              margin: "0px 10px 0px 5px",
              border: "solid 2px",
              borderTop: 0.1,
              color: "#ff5e43"
            }}
          />
          <Modal.Body >
            <Grid item xs={12} md={6} alignContent={"center"} style={{ marginTop: '10px' }}>
              <CustomInputDate
                id="input-closeDate"
                label="Close Date"
                // placeholder="เริ่มต้น"
                value={closeDate}
                onChange={(value) => setCloseDate(value)} // ฟังก์ชัน onChange

              />
            </Grid>
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={handleUpdateCloseDate}
                className="btn-fill-orange  p-2 bold"
              >
                ตกลง
              </button>
            </section>


          </Modal.Body>
        </Modal>
        <Modal size="xl" show={showModalDetail} onHide={toggleModalDetailClose} centered>
          <Modal.Header closeButton>
            <h5 className="bold" style={{ color: "#ff5e43" }} >{selectedDetail.subject}</h5>
            {/* <Modal.Title>ตรวจสอบสำเร็จ</Modal.Title> */}
          </Modal.Header>
          <hr
            style={{
              margin: "0px 10px 0px 5px",
              border: "solid 2px",
              borderTop: 0.1,
              color: "#ff5e43"
            }}
          />
          <Modal.Body >
            <TableDetail
              id_detail={selectedDetail.id_detail}
              type={selectedDetail.type}
              policy_number={selectedDetail.policy_number}
            />

            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={toggleModalDetailClose}
                className="btn-fill-orange  p-2 bold"
              >
                ตกลง
              </button>
            </section>


          </Modal.Body>
        </Modal>
      </div>
    </div>

  );
};

export default RequestReport;

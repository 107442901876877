import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'
import Header_Home from '../Component/Header_Home';
class ListPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0}
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list",
        //fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(item => 
                <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="card">
                    <div className="card-body">
                        <div className="title">{item.policy_name}</div>
                        <div className="subtitle">{item.policy_no}</div>
                            {item.policy_status === 'Active'? <span className="badge bg-success text-lg p-2 mt-2">กรมธรรม์ที่มีผลบังคับ</span>:<span className="badge bg-danger text-lg p-2 mt-2">กรมธรรม์ที่ขาดผลบังคับ</span> } 
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <span className="bold">คุ้มครอง</span>
                                <span className="light">{item.coverage}</span>
                            </li>
                            <li className="list-group-item">
                                <span className="bold">เบี้ยประกันภัยต่องวด</span>
                                <span className="light">{(parseFloat(item.premium)).toLocaleString('en-US', { maximumFractionDigits: 2 })} <strong> บาท</strong></span>
                            </li>
                            <li className="list-group-item">
                                <span className="bold">จำนวนเงินเอาประกันภัย</span>
                                <span className="light">{(parseFloat(item.sum_insured)).toLocaleString('en-US', { maximumFractionDigits: 2 })} <strong> บาท</strong></span>
                            </li>                       
                        </ul>
                        <div className="card-body App">
                            <button type="submit" className="btn btn-lg btn-blue">Detail</button>
                        </div>
                  </div>
                  </div>
            )
            this.setState({user : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData})
            
        })
        .catch(function(res){ console.log(res) })
    }

    load(filename,type)  {
        // console.log(filename,type)
        let url = 'https://dev-eapp.azurewebsites.net/api/agent/doc/download?type='+type+'&name='+filename;   
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('token_login'),
            }
        })
        .then(
            response => response.blob()
        )
        
        .then( 
          
            imageBlob  => {
                const imageObjectURL = URL.createObjectURL(imageBlob);
                window.open(imageObjectURL, '_blank');
            }
        )

    }

    componentDidMount() {
        
        // console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

   
    componentDidUpdate() {
       
        // console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount')
       
    }
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
        localStorage.setItem('status_cus',  e.target.value );

        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list",
        //fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(item => 
                
                <div className="card">
                  
                  
                     <div className="card-body">
                        <div className="title">{item.policy_no}</div>
                        <div className="subtitle">20,000</div>
                        <span className="badge bg-danger text-lg p-2 mt-2">Dective</span>
                     </div>
                     <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                           <span className="bold">Category</span>
                           <span className="light">Designer</span>
                        </li>
                        <li className="list-group-item">
                           <span className="bold">DOI</span>
                           <span className="light">2016-05-25</span>
                        </li>
                        <li className="list-group-item">
                           <span className="bold">DOE</span>
                           <span className="light">2016-05-30</span>
                        </li>                       
                     </ul>
                     <div className="card-body">
                        <button type="submit" className="btn btn-lg btn-blue">Delete</button>
                        <button type="reset" className="btn btn-lg btn-reset">Edit</button>
                     </div>
                  </div>
            )
            this.setState({user : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData})
            
        })
        .catch(function(res){ console.log(res) })
    };


 
    render() {
        return (
            <div >
                <Header_Home/>
                <div className="content margin-3">
                    <div className="content margin-auto">

                        <section className="section">
                            <div className='row'>
                                {this.state.postData}
                            </div>
                        </section>

                        <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div>
                    </div> 
                </div>
            </div>      
        )
    }
        
    
}

export default ListPolicy;
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Col, Modal } from "react-bootstrap";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import successGif from "../../images/successGiff.gif";
import { TextField, Grid, InputLabel, TableFooter } from "@mui/material";
import { Outlet, Navigate, useNavigate,useLocation } from "react-router-dom";
import {updateDigitalCom} from "../LetterDigiCom/LetterDigiComService/letterDigiComService";
import CircularProgress from "@mui/material/CircularProgress";
import "../LetterDigiCom/letterDigiCom-styles.css";


const LetterDigiComDetail = () => {

  const [showModal, setShowModal] = useState(false);
  const naviate = useNavigate();
  const location = useLocation();
  var { rowData } = location.state || {};
  const [citizenId, setCitizenId] = useState(rowData.citizen_id);
  const [policyNo, setPolicyNo] = useState(rowData.policy_no);
  const [letterName, setLetterName] = useState(rowData.letter_name);
  const [letterFileName, setLetterFileName] = useState(rowData.letter_file_name);
  const [letterOwnerName, setLetterOwnerName] = useState(rowData.letter_owner_name); 
  const [mobile, setMobile] = useState(rowData.mobile);
  const [spinner, setSpinner] = useState();

  const toggleModalClose = () => {
    setShowModal(false);
  };

  const gotoMain = () => {
    naviate("/letterDigiCom");
  };

  const updateDataDigitalCom = async () => {
    setSpinner(true);
    const updateData = {
      citizenId: citizenId,
      policyNo: policyNo,
      letterFileName: letterFileName,
      letterName: letterName,
      letterOwnerName: letterOwnerName,
      mobile: mobile
    };
    
    const result = await updateDigitalCom(rowData.id,updateData)
    setTimeout(() => {
      setSpinner(false);
      setShowModal(true)
      // console.log(result);
    }, 1000);
    
  }

  const handleChangeMobile = (event) => {
    setMobile(event.target.value)
  }

  useEffect(() => {
    // console.log(rowData);
  }, []);

  return (
    <div>
      <Header_Home />
      <Header title="จดหมาย" />
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      <div className="frame-other p-4">
        <h2
          className="bold"
          style={{ fontSize: "20px", marginLeft: "20px", marginTop: "20px" }}
        >
          แก้ไขข้อมูล
        </h2>
        <br />
        <div
          className="form-4 mx-auto"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontSize: "15px", marginLeft: "50px" }}>
            หมายเลขประจำตัวประชาชน
          </span>
          <Grid item xs={6} style={{ marginLeft: "50px",width:"50%" }}>
            {" "}
            <TextField
              value={citizenId}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
              disabled
            />
          </Grid>
        </div>
        <div
          className="form-4 mx-auto"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontSize: "15px", marginLeft: "50px" }}>
            กรมธรรม์เลขที่
          </span>
          <Grid item xs={6} style={{ marginLeft: "130px",width:"50%" }}>
            {" "}
            <TextField
              value={policyNo}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
              disabled
            />
          </Grid>
        </div>
        <div
          className="form-4 mx-auto"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontSize: "15px", marginLeft: "50px" }}>
            ชื่อจดหมาย
          </span>
          <Grid item xs={6} style={{ marginLeft: "152px",width:"50%" }}>
            {" "}
            <TextField
              value={letterName}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
              disabled
            />
          </Grid>
        </div>
        <div
          className="form-4 mx-auto"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontSize: "15px", marginLeft: "50px" }}>
            ชื่อเจ้าของจดหมาย
          </span>
          <Grid item xs={6} style={{ marginLeft: "103px",width:"50%" }}>
            {" "}
            <TextField
              value={letterOwnerName}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
              disabled
            />
          </Grid>
        </div>
        <div
          className="form-4 mx-auto"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontSize: "15px", marginLeft: "50px" }}>
          ชื่อไฟล์จดหมาย
          </span>
          <Grid item xs={6} style={{ marginLeft: "125px",width:"50%" }}>
            {" "}
            <TextField
              value={letterFileName}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
              disabled
            />
          </Grid>
        </div>
        <div
          className="form-4 mx-auto"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <span style={{ fontSize: "15px", marginLeft: "50px" }}>
            เบอร์ติดต่อ
          </span>
          <Grid item xs={6} style={{ marginLeft: "151px",width:"50%" }}>
            {" "}
            <TextField
              onChange={handleChangeMobile}
              value={mobile}
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "16px",
                  height: "42px",
                },
              }}
              variant="outlined"
              style={{ borderRadius: "16px", height: "42px" }}
            />
          </Grid>
        </div>

        <br />
        <div className="App">
          <div className="d-flex justify-content-center flex-wrap">
            <a
              onClick={updateDataDigitalCom}
              type="button"
              className="mt-3 btn-confirm"
            >
              บันทึก
            </a>
            <a type="button" className="mt-3 btn-back" onClick={gotoMain}>
              กลับ
            </a>
          </div>
        </div>
        <br />

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header></Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              fontSize: "25px",
            }}
          >
            <img
              src={successGif}
              style={{
                width: "150px",
                height: "150px",
              }}
            />
            <p style={{ marginTop: "20px" }}>บันทึกข้อมูลสำเร็จ</p>
          </Modal.Body>
          <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
            <a
              onClick={() => setShowModal(false)}
              type="button"
              className="mt-3 btn-back"
              style={{ minWidth: "100px", textAlign: "center" }}
            >
              ปิด
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default LetterDigiComDetail;

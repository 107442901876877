import { toast } from "react-toastify";

const notifySuccess = (title) => {
  toast.success(title, {
    position: "top-center",
  });
};

const notifyError = (title) => {
  toast.error(title, {
    position: "top-center",
  });
};

const notifyWarning = (title) => {
  toast.warning(title, {
    position: "top-center",
  });
};

export { notifySuccess, notifyError, notifyWarning };

import * as React from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Grid,
  Pagination,
  PaginationItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./TableBenefitReceiving.css";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { useNavigate } from "react-router-dom";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

function RenderRow(props) {
  let navigate = useNavigate();
  const { row, index, isMobile } = props;
  const [open, setOpen] = React.useState(false);

  const isUnder7 = row.age_range === "MST009";
  const isUnder20 = row.age_range === "MST010";

  const getImage = (row, index) => {
    // สร้าง doc_code ที่ต้องการ
    const docCodeToCheck = `${row.age_range}_${index}`;

    // ตรวจสอบว่ามี doc_code ตรงกับที่ต้องการหรือไม่
    const matchingFile = row.file.find(file => file.doc_code === docCodeToCheck);

    // คืนค่า file_name ถ้ามีหรือ "-" ถ้าไม่มี
    return matchingFile ? matchingFile.file_name : row.imageOwner || "-";
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear() + 543;

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const getImage2 = (row, index) => {
    // สร้าง doc_code ที่ต้องการ
    const docCodeToCheck = `${row.age_range}_${index}`;

    // ตรวจสอบว่ามี doc_code ตรงกับที่ต้องการหรือไม่
    const matchingFile = row.file.find(file => file.doc_code === docCodeToCheck);

    // คืนค่า link_files ถ้ามีหรือ "-" ถ้าไม่มี
    return matchingFile ? matchingFile.link_files : row.imageOwner || "-";
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: "white" }}
      >
        <TableCell component="th" scope="row" align="center">
          {index + 1}
        </TableCell>
        <TableCell>{row.cus_nm + " " + row.cus_ln}</TableCell>
        <TableCell xs={0} md={6}>
          {row.cus_psnid}
        </TableCell>
        {isMobile ? null : <TableCell>{row.cus_tel}</TableCell>}

        <TableCell xs={0} md={6}>
          {row.cus_check &&
            row.cus_check == "true" ? (
            <CheckRoundedIcon style={{ color: "green" }} />
          ) : (
            <CloseRoundedIcon style={{ color: "red" }} />
          )}
        </TableCell>

        {isMobile ? null : <TableCell>{row.create_date}</TableCell>}

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#cdcbcb33",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{ margin: 1, padding: "16px", maxWidth: "80vw" }}
              component={Paper}
            >
              <Grid container spacing={1}>
                {isMobile ? (
                  <>
                    <Grid item xs={4}>
                      <span className="bold">{"เบอร์โทร"}</span>
                    </Grid>
                    <Grid item xs={8}>
                      <span> {row.phoneNumber || "-"} </span>
                    </Grid>
                  </>
                ) : null}
                {isMobile ? (
                  <>
                    <Grid item xs={4}>
                      <span className="bold">{"วันที่ลูกค้าทำรายการ"}</span>
                    </Grid>
                    <Grid item xs={8}>
                      <span> {row.create_date || "-"} </span>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={4}>
                  <span className="bold">{"ธนาคาร"}</span>
                </Grid>
                <Grid item xs={8}>
                  <span> {row.cuscf_bank || "-"} </span>
                </Grid>
                <Grid item xs={4}>
                  <span className="bold">{"เลขบัญชี"}</span>
                </Grid>
                <Grid item xs={8}>
                  <span> {row.cuscf_banknum || "-"} </span>
                </Grid>
                <Grid item xs={4}>
                  <span className="bold">{"KYC "}</span>
                </Grid>
                <Grid item xs={8}>
                  <span> {row.cus_kyc || "-"} </span>
                </Grid>
                {/* <Grid item xs={4}>
                  <span className="bold">{"ตรวจสอบ "}</span>
                </Grid>
                <Grid item xs={8}>
                  {row.cus_check &&
                    row.cus_check == "true" ? (
                    <CheckRoundedIcon style={{ color: "green" }} />
                  ) : (
                    <CloseRoundedIcon style={{ color: "red" }} />
                  )}
                </Grid> */}
                <Grid item xs={12} sm={8} md={7} lg={6}>
                  <span className="bold">
                    {isUnder7
                      ? "สำเนาสูติบัตรของผู้เยาว์ เซ็นสำเนาถูกต้อง  "
                      : isUnder20
                        ? "สำเนาบัตรประชาชนของผู้เยาว์ เซ็นสำเนาถูกต้อง  "
                        : "สำเนาบัตรประชาชน เซ็นสำเนาถูกต้อง  "}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4} md={5} lg={6}>
                  <a
                    className="text-orange text-long-hidden"
                    href={row.file.find(file => file.doc_code === `${row.age_range}_1`)?.link_files || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {getImage(row, 1)} {/* เรียกใช้ฟังก์ชันเพื่อดึงชื่อไฟล์ */}
                  </a>
                </Grid>
                <Grid item xs={12} sm={8} md={7} lg={6}>
                  <span className="bold">
                    {isUnder7
                      ? "รูปถ่ายหน้าตรงที่เป็นปัจจุบันของผู้เยาว์ "
                      : isUnder20
                        ? "รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้เยาว์"
                        : "รูปถ่ายหน้าตรงคู่กับบัตรประชาชน"}
                  </span>
                </Grid>
                <Grid item xs={12} sm={4} md={5} lg={6}>
                  <a
                    className="text-orange text-long-hidden"
                    href={
                      isUnder7
                        ? row.file.find(file => file.doc_code === `${row.age_range}_4`)?.link_files
                        : isUnder20
                          ? row.file.find(file => file.doc_code === `${row.age_range}_3`)?.link_files
                          : row.file.find(file => file.doc_code === `${row.age_range}_2`)?.link_files
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {isUnder7
                      ? getImage(row, 4)
                      : isUnder20
                        ? getImage(row, 3)
                        : getImage(row, 2)}
                  </a>
                </Grid>
                {isUnder7 || isUnder20 ? (
                  <>
                    <Grid item xs={12} sm={8} md={7} lg={6}>
                      <span className="bold">
                        {
                          "สำเนาบัตรประชาชนของผู้ปกครอง โดยชอบธรรม เซ็นสำเนาถูกต้อง "
                        }
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={4} md={5} lg={6}>
                      <a
                        className="text-orange text-long-hidden"
                        href={row.file.find(file => file.doc_code === `${row.age_range}_2`)?.link_files || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getImage(row, 2)}
                      </a>
                    </Grid>
                    <Grid item xs={12} sm={8} md={7} lg={6}>
                      <span className="bold">
                        {
                          "รูปถ่ายหน้าตรงคู่กับบัตรประชาชนของผู้ปกครองโดยชอบธรรม "
                        }
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={4} md={5} lg={6}>
                      {isUnder7 || isUnder20 ? (
                        <a
                          className="text-orange text-long-hidden"
                          href={
                            isUnder7
                              ? row.file.find(file => file.doc_code === `${row.age_range}_3`)?.link_files
                              : row.file.find(file => file.doc_code === `${row.age_range}_4`)?.link_files
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {isUnder7
                            ? getImage(row, 3)
                            : getImage(row, 4)}
                        </a>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item xs={12} sm={8} md={7} lg={6}>
                      <span className="bold">
                        {"สำเนาทะเบียนบ้านของผู้เยาว์ เซ็นสำเนาถูกต้อง "}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={4} md={5} lg={6}>
                      <a
                        className="text-orange text-long-hidden"
                        href={row.file.find(file => file.doc_code === `${row.age_range}_6`)?.link_files}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getImage(row, 6)}
                      </a>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12} sm={8} md={7} lg={6}>
                  <span className="bold">{"สำเนาสมุดบัญชีเงินฝากธนาคาร "}</span>
                </Grid>
                <Grid item xs={12} sm={4} md={5} lg={6}>
                  {isUnder7 || isUnder20 ? (
                    <a
                      className="text-orange text-long-hidden"
                      href={row.file.find(file => file.doc_code === `${row.age_range}_5`)?.link_files}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getImage(row, 5)}
                    </a>
                  ) : (
                    <a
                      className="text-orange text-long-hidden"
                      href={row.file.find(file => file.doc_code === `${row.age_range}_3`)?.link_files}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getImage(row, 3)}
                    </a>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <span className="bold">{"สำเร็จ "}</span>
                </Grid>
                <Grid item xs={8}>
                  {row.cus_check &&
                    row.cus_check == "true" ? (
                    <span className="text-orange">{"Y"}</span>
                  ) : (
                    <span className="text-orange">{"N"}</span>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <span className="bold">{"แก้ไข "}</span>
                </Grid>
                <Grid item xs={8}>
                  <IconButton
                    onClick={() =>
                      navigate("/benefit-receiving-detail", {
                        state: { id: row.cus_uuid, data: row },
                      })
                    }
                  >
                    <EditRoundedIcon style={{ color: "#FF5E43" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const TableBenefitReceiving = (props) => {
  const { data = [], loading } = props;
  const isMobile = useMediaQuery("(max-width:480px)");
  const rowPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleChange = (_event, value) => {
    setPage(value);
  };



  const firstIndex = page * rowPerPage - rowPerPage;
  const lastIndex = page * rowPerPage;
  const dataForPage = data.slice(firstIndex, lastIndex);
  const pageCount = data.length > 0 ? Math.ceil(data.length / rowPerPage) : 1;



  return (
    <>
      <TableContainer
        className="table-benefit-receiving"
        component={"div"}
        sx={{
          backgroundColor: "#F6F6F6CC",
          // borderRadius: "14px",
        }}
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width={48} align="center">
                No.
              </TableCell>
              <TableCell align="left">ชื่อ</TableCell>
              <TableCell>เลขประจำตัวประชาชน</TableCell>
              {!isMobile ? <TableCell>เบอร์โทร</TableCell> : null}
              <TableCell>ตรวจสอบ</TableCell>
              {/* <TableCell>วันที่ลูกค้าทำรายการ</TableCell> */}
              {!isMobile ? <TableCell>วันที่ลูกค้าทำรายการ</TableCell> : null}
              <TableCell width={40} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              dataForPage.map((row, index) => (
                <RenderRow
                  key={index + row.name}
                  index={index + firstIndex}
                  row={row}
                  isMobile={isMobile}
                />
              ))
            ) : (
              <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  colSpan={isMobile ? 4 : 7}
                  height={120}
                >
                  {loading ? <CircularProgress /> : "ไม่พบข้อมูล"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ marginTop: "8px", display: "flex", justifyContent: "end" }}
        count={pageCount}
        variant="outlined"
        shape="rounded"
        size="small"
        showFirstButton
        showLastButton
        hidePrevButton
        hideNextButton
        page={page}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{
              // previous: KeyboardArrowLeftRoundedIcon,
              // next: KeyboardArrowRightRoundedIcon,
              first: KeyboardDoubleArrowLeftRoundedIcon,
              last: KeyboardDoubleArrowRightRoundedIcon,
            }}
            {...item}
          />
        )}
      />
    </>
  );
};

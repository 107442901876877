import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col } from 'react-bootstrap';


// import Header_Home from '../Component/Header_Home';
// import Left_bar from '../Component/Left_bar';
// import ReactPaginate from 'react-paginate';
// import img_profile from '../../images/profile.svg'
import Header_Home from '../Component/Header_Home';
import Header from '../Component/Header';
import Button from 'react-bootstrap/Button';
import { BsChevronRight } from "react-icons/bs";
import menu_11 from '../../images/icon31.svg'
import menu_12 from '../../images/icon32.svg'
import menu_13 from '../../images/icon33.svg'
import menu_14 from '../../images/icon34.svg'
import CollapseTable from '../Benefit/collapseTable.tsx';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styles from '../Benefit/benefit-styles.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { OutlinedInput, InputLabel } from "@mui/material";
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import successGif from "../../images/successGiff.gif"

import {
    useForm,
    Controller,
    FormProvider,
    useFormContext,
    useWatch,
} from "react-hook-form";

class ListPolicy extends Component {

    constructor(props) {
        super(props);
        this.indexselect = null;
        this.state = {
            date: new Date(),
            token: '',
            username: '',
            step: 1,
            offset: 0,
            data: [],
            perPage: 50,
            currentPage: 0,
            pageCount: 0
        }

        this.cardRefs = [];

    }

    receivedData() {
        // console.log(this.indexselect);

        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer=" + localStorage.getItem('customer_id'),
            {
                headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
            .then(res => res.json())
            .then(res => {
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map((item, index) =>
                    // <div key={item.id} className={`frame-policy ${this.indexselect === null ? 'frame-selected' : 'frame-policy'}`} onClick={() => this.handleCardClick(item.policy_no, index)}>

                    <div key={item.id} className={`frame-policy`} onClick={() => this.handleCardClick(item.policy_no, index)}>
                        <ul className="list-group list-group-flush" style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 className="bold" style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }} >{item.policy_name}</h2>
                            <hr style={{ margin: "0 20px" }} />
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">กรรมธรรม์เลขที่</span>
                                <span className="bold">{item.policy_no}</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">วันที่เริ่มสัญญา</span>
                                <span className="bold">14/01/2566</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">วันที่ครบกำหนดสัญญา</span>
                                <span className="bold">14/01/2569</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">สถานะกรมธรรม์</span>
                                <span className="bold">{item.policy_status}</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">จำนวนเงินเอาประกันภัย</span>
                                <span className="bold">{(parseFloat(item.sum_insured)).toLocaleString('en-US', { maximumFractionDigits: 2 })} <strong> บาท</strong></span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                                <span className="light">วิธีการชำระเบี้ย</span>
                                <span className="bold">รายปี</span>
                            </li>
                            <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', marginRight: '10px' }}>
                            </li>
                        </ul>
                    </div>

                )
                this.setState({
                    user: res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData
                })

            })
            .catch(function (res) { console.log(res) })
    }

    load(filename, type) {
        // console.log(filename, type)
        let url = 'https://dev-eapp.azurewebsites.net/api/agent/doc/download?type=' + type + '&name=' + filename;
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('token_login'),
            }
        })
            .then(
                response => response.blob()
            )

            .then(

                imageBlob => {
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    window.open(imageObjectURL, '_blank');
                }
            )

    }

    handleCardClick = (id, index) => {
        // console.log(index + ":" + this.state.selectedCardIndex);
        // alert("ค่าของ card คือ " + id + ":" + index);
        this.setState({
            selectedCard: id,
            selectedCardIndex: index // เปลี่ยนจาก 1 เป็น index
        }, () => {
            // ตรวจสอบค่า this.cardRefs[index] ก่อนเรียกใช้งาน focus
            if (this.cardRefs[index]) {
                this.cardRefs[index].focus();
            }
        });
    }

    componentDidMount() {

        // console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };


    componentDidUpdate() {

        // console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount')

    }
    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
        localStorage.setItem('status_cus', e.target.value);

        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer=" + localStorage.getItem('customer_id'),
            {
                headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'Content-Type': 'application/json'
                },
                method: "GET"
            })
            .then(res => res.json())

            .then(res => {
                //console.log(res)
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                const postData = slice.map(item =>

                    <div className="card">
                        <div className="card-body">
                            <div className="title">{item.policy_no}</div>
                            <div className="subtitle">20,000</div>
                            <span className="badge bg-danger text-lg p-2 mt-2">Dective</span>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <span className="bold">Category</span>
                                <span className="light">Designer</span>
                            </li>
                            <li className="list-group-item">
                                <span className="bold">DOI</span>
                                <span className="light">2016-05-25</span>
                            </li>
                            <li className="list-group-item">
                                <span className="bold">DOE</span>
                                <span className="light">2016-05-30</span>
                            </li>
                        </ul>
                        <div className="card-body">
                            <button type="submit" className="btn btn-lg btn-blue">Delete</button>
                            <button type="reset" className="btn btn-lg btn-reset">Edit</button>
                        </div>
                    </div>
                )
                this.setState({
                    user: res.payload,
                    pageCount: Math.ceil(data.length / this.state.perPage),
                    postData
                })

            })
            .catch(function (res) { console.log(res) })
    };



    render() {
        const { drop_prefix } = this.state;
        // const { control } = useFormContext();

        return (
            <div>
                <Header_Home />
                <Header title="รายชื่อลูกค้าที่ลงทะเบียนรับเงินผลประโยชน์" />
                {/* <div className="card-frame margin-1"> */}
                <div className="content margin-1">
                    <div className="card big-frame">
                        <ul className="list-group list-group-flush cus-frame2" style={{ display: 'flex', flexDirection: 'column' }}>
                            <h2 className="bold font-field" style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }} >วันที่</h2>
                            <div className='App'>
                                <div className="d-flex justify-content-center flex-wrap">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <DatePicker
                                            className="form-control textFeild"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.date1}
                                            onChange={this.handleDateChange}
                                            placeholderText="เริ่มต้น"

                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <DatePicker
                                            className="form-control textFeild"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.date1}
                                            onChange={this.handleDateChange}
                                            placeholderText="สิ้นสุด"

                                        />
                                    </div>
                                    {/* <a href="/addChannelPay" type="button" className="mt-3 pay-btn col-xl-6 col-lg-6 col-md-6 col-sm-12">เพิ่ม หรือเปลี่ยนแปลงบัญชี</a>
                                    <a href="/policylist" type="button" className="mt-3 btn-back col-xl-6 col-lg-6 col-md-6 col-sm-12">กลับ</a> */}
                                </div>
                            </div>
                            <h2 className="bold" style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }} >ชื่อ</h2>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <input type="text" className="form-control textFeild" id="username" placeholder="ชื่อ-นามสกุล" />

                            </div>
                            <h2 className="bold" style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }} >เลขประจำตัวประชาชน</h2>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <input type="text" className="form-control textFeild" id="username" placeholder="เลขประจำตัวประชาชน" />

                            </div>

                            <div className='App'>
                                <div className="d-flex justify-content-center flex-wrap">
                                    <a href="/addChannelPay" type="button" className="mt-3 pay-btn col-xl-6 col-lg-6 col-md-6 col-sm-12">ค้นหา</a>
                                    <a href="/policylist" type="button" className="mt-3 btn-back col-xl-6 col-lg-6 col-md-6 col-sm-12">ล้าง</a>
                                </div>
                            </div>
                            <br />
                            <br />
                            <div>
                                <a href="/addChannelPay" type="button" className="mt-3 download-btn col-xl-5 col-lg-5 col-md-5 col-sm-12">Download (PDF)</a>
                                <a href="/policylist" type="button" className="mt-3 download-btn col-xl-5 col-lg-5 col-md-5 col-sm-12">Export (Excel)</a>
                            </div>
                            <br />
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <CollapseTable />
                            </div>
                            <br />




                        </ul>
                    </div>

                </div>



            </div>
        )
    }



}

export default ListPolicy;
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-datepicker/dist/react-datepicker.css";
import Header_Home from '../Component/Header_Home';
import Header from '../Component/Header';
import { Modal, Form, Col } from 'react-bootstrap';
import successGif from "../../images/successGiff.gif"
import warning from "../../images/warning.gif"

import progressGif from "../../images/wait.gif"
import moment from 'moment';
import { CustomInputDate } from "../Component/CustomInputDate";
import { CustomInputFileExcel } from "../Component/CustomInputFileExcel";
import {
    notifyError,
    notifySuccess,
    notifyWarning,
} from "../../util/Notification";

import {
    TextField,
    Grid,
    InputLabel,
    OutlinedInput,
    MenuItem,
    Select,
} from "@mui/material";
import { getStausQuickTransfer, processQuickTransferWeb } from "./LetterManageService/letterManageService";

const LetterManage = () => {
    const [showNextPage, setShowNextPage] = useState(false);
    const [check, setCheck] = useState(false);
    const [date, setDate] = useState(new Date());
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [file1, setFile1] = useState();
    const [fileName1, setFileName1] = useState();
    const [file2, setFile2] = useState();
    const [fileName2, setFileName2] = useState();
    const [showModalSave, setShowModalSave] = useState(false);
    const [showGif, setShowGif] = useState(false);





    const onProcess = async () => {
        setIsDisabled(true)
        setShowModal(true);

        const import_date = date ? moment(date).format('YYYY-MM-DD') : '';

        try {
            const result = await processQuickTransferWeb(file1, file2, import_date);
            // console.log("process DATA ID >>>", result);
            // ตรวจสอบและดึงข้อความจาก result.results
            // if (result && result.results) {
            //     result.results.forEach(item => {
            //         if (item.status !== undefined && item.message) {
            //             setMessage1(item.message)
            //         }
            //         if (item.statusExpire !== undefined && item.messageExpire) {
            //             setMessage2(item.messageExpire)
            //         }
            //         if ((item.status && item.statusExpire) || (item.status && item.statusExpire == undefined) || (item.statusExpire && item.status == undefined)) {
            //             setShowGif(true)
            //         }
            //     });
            //     // console.log("Message:", item.message);
            //     // console.log("MessageExpire:", item.messageExpire);
            // }
            if (result && result.results) {
                // กำหนดข้อความเบื้องต้น
                let message1 = '';
                let message2 = '';
                let showGif = false;

                result.results.forEach((item) => {
                    // ตรวจสอบและตั้งค่า Message
                    if (item.status !== undefined && item.message) {
                        message1 = item.message;
                    }
                    if (item.statusExpire !== undefined && item.messageExpire) {
                        message2 = item.messageExpire;
                    }
                    // ตรวจสอบสถานะ GIF
                    if (item.status || item.statusExpire) {
                        showGif = true;
                    }
                });

                // ตั้งค่าข้อความและสถานะ
                setMessage1(message1);
                setMessage2(message2);
                setShowGif(showGif);
            }
            setDate(new Date())
            setFile1('')
            setFile2('')
            setFileName1('')
            setFileName2('')


        } catch (error) {
            console.error("Error processing QuickTransferWeb", error);
        } finally {
            setShowModal(false); // ปิด modal หลังจากกระบวนการเสร็จสิ้น
            setShowModalSave(true)
            // setIsDisabled(false)
        }
    };

    const toggleModalOpen = () => {
        setShowModal(true);
    };
    const toggleModalClose = () => {
        setShowModal(false);
    };
    const toggleModalOpenSave = () => {
        setShowModalSave(true);
    };
    const toggleModalCloseSave = () => {
        setDate(new Date())
        setFile1('')
        setFile2('')
        setFileName1('')
        setFileName2('')
        setMessage1('')
        setMessage2('')
        setShowGif(false)
        setShowModalSave(false);
    };

    return (
        <div>
            <Header_Home />
            <Header title="จัดการจดหมาย" />
            <div className="table-page-container footer-space">
                <div className="rounded-border bg-white p-2">
                    <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
                        <Grid item xs={12} md={6} alignContent={"center"}>
                            <CustomInputDate
                                id="Request-date"
                                label="วันที่"
                                value={date} // ค่า value จาก state
                                onChange={(value) => setDate(value)} // ฟังก์ชัน onChange
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
                        <Grid item xs={12} md={6} alignContent={"center"}>
                            {/* <label className='text-righ'><strong>ไฟล์ Web : </strong>   {filename1 ? filename1 : '-'}</label> */}
                            <div className="d-flex flex-row justify-content-between align-content-center align-items-center " style={{ paddingLeft: '10px' }}>
                                <div>
                                    <div className="bold">{"ไฟล์ Web : "}</div>
                                    <div>{"รองรับไฟล์ .xlsx , .xls"}</div>

                                </div>
                                <CustomInputFileExcel
                                    id={"file1"}
                                    filename={fileName1}
                                    onChange={(e) => {
                                        const inputFile = e.target.files;
                                        const file_input = e.target.files[0];
                                        setFile1(file_input);
                                        setFileName1(inputFile[0].name);
                                        setIsDisabled(false)

                                    }}
                                />
                            </div>
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} justifyContent="center" style={{ marginTop: '5px', paddingInline: '32px' }}>
                        <Grid item xs={12} md={6} alignContent={"center"}>
                            {/* <label className='text-righ'><strong>ไฟล์ Web : </strong>   {filename1 ? filename1 : '-'}</label> */}
                            <div className="d-flex flex-row justify-content-between align-content-center align-items-center " style={{ paddingLeft: '10px' }}>
                                <div>
                                    <div className="bold">{"ไฟล์ Web Expired : "}</div>
                                    <div>{"รองรับไฟล์ .xlsx , .xls"}</div>

                                </div>
                                <CustomInputFileExcel
                                    id={"file2"}
                                    filename={fileName2}
                                    onChange={(e) => {
                                        const inputFile = e.target.files;
                                        const file_input = e.target.files[0];
                                        setFile2(file_input);
                                        setFileName2(inputFile[0].name);
                                        setIsDisabled(false)

                                    }}
                                />
                            </div>
                        </Grid>

                    </Grid>
                    <div className="App">
                        <section className="filter-action-section">
                            <button
                                style={{ fontSize: 18 }}
                                onClick={onProcess}
                                className={isDisabled ? "btn-fill-gray p-2 bold" : "btn-fill-orange p-2 bold"}
                                disabled={isDisabled}
                            >
                                Process
                            </button>
                        </section>
                        <br />
                    </div>
                </div>
            </div>
            <Modal show={showModal} centered>
                <Modal.Header >
                </Modal.Header>
                <Modal.Body >
                    <img
                        src={progressGif}
                        alt={"progressGif"}
                        style={{
                            height: "auto",
                            marginLeft: '35%',
                            width: '30%',
                        }}
                    />
                    <li className="body-list-group" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Col xl={12} md={12} xs={12} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                            <h3 className="bold">ระบบกำลังดำเนินการ Process</h3>
                        </Col>
                    </li>
                    {/* <section className="filter-action-section">
                        <button
                            style={{ fontSize: 18 }}
                            onClick={toggleModalClose}
                            className="btn-fill-orange  p-2 bold"
                        >
                            ตกลง
                        </button>
                    </section> */}


                </Modal.Body>
            </Modal>
            <Modal show={showModalSave} onHide={toggleModalCloseSave} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {/* แสดงภาพ GIF */}
                    <img
                        src={showGif ? successGif : warning}
                        alt={showGif ? "Success" : "Warning"}
                        style={{
                            height: "auto",
                            margin: "0 auto",
                            display: "block",
                            width: "30%",
                        }}
                    />

                    {/* เนื้อหาใน Modal */}
                    <div
                        className="body-list-group"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 20,
                        }}
                    >
                        <Col
                            xl={12}
                            md={12}
                            xs={12}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {/* แสดงข้อความสำหรับไฟล์ Web */}
                            {message1 && (
                                <div style={{ marginBottom: 10 }}>
                                    <strong>ไฟล์ Web:</strong> {message1}
                                </div>
                            )}

                            {/* แสดงข้อความสำหรับไฟล์ Web Expired */}
                            {message2 && (
                                <div style={{ marginBottom: 10 }}>
                                    <strong>ไฟล์ Web Expired:</strong> {message2}
                                </div>
                            )}

                            {/* ข้อความเมื่อไม่มี Message */}
                            {!message1 && !message2 && (
                                <div style={{ color: "red", textAlign: "center" }}>
                                    ไม่พบข้อความแสดงผล
                                </div>
                            )}
                        </Col>
                    </div>

                    {/* ปุ่มตกลง */}
                    <section className="filter-action-section" style={{ marginTop: 20 }}>
                        <button
                            style={{
                                fontSize: 18,
                                display: "block",
                                margin: "0 auto",
                            }}
                            onClick={toggleModalCloseSave}
                            className="btn-fill-orange p-2 bold"
                        >
                            ตกลง
                        </button>
                    </section>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default LetterManage;

import React, { useEffect, useState } from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import Row from "react-bootstrap/Row";
import { Modal, Col, Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPolicyInformationAPI,
  insertConsentAPI,
} from "./ExemptIncomeTaxService/exemptIncomeTaxService";
import SuccessPage from "../Component/Success_page";
import "../ExemptIncomeTax/exemptIncomeTax-styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";
import { Typography } from "@material-ui/core";
import successGif from "../../images/successGiff.gif";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Grid, Backdrop, Card, CardContent } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

const ExemptIncomeTax = () => {
  const [LoadingContent, setLoadingContent] = useState(true);
  const [dataMaster, setDataMaster] = useState([]);
  const [dataPolicies, setDataPolicies] = useState([]);
  const [dataRdConsent, setDataRdConsent] = useState([]);
  const [rdConsent, setRdConsent] = useState([]);
  const [listPolicyNo, setListPolicyNo] = useState([]);
  const [listUndesirePolicyNo, setListUndesirePolicyNo] = useState([]);
  const [insureIdCard, setInsureIdCard] = useState(
    localStorage.getItem("customer_id")
  );
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllUnChecked, setIsAllUnChecked] = useState(false);
  const [checkedPolicies, setCheckedPolicies] = useState([]);
  const [checkedUndesirePolicies, setCheckedUndesirePolicies] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [saveFlag, setSaveFlag] = useState("");
  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };
  const [customerfName, setCustomerfName] = useState(
    localStorage.getItem("first_name")
  );
  const [customerlName, setCustomerlName] = useState(
    localStorage.getItem("last_name")
  );
  const [customerName, setCustomerName] = useState(
    customerfName + " " + customerlName
  );
  const [citizenId, setCitizenId] = useState(
    decryptCardNumber(
      localStorage.getItem("customer_id"),
      process.env.REACT_APP_ENCRYPT_DATA
    )
  );
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    // console.log(customerName);
    // console.log(citizenId);
    getPolicyInformation();
  }, []);

  const getPolicyInformation = async () => {
    const result = await getPolicyInformationAPI(
      decryptCardNumber(insureIdCard, process.env.REACT_APP_ENCRYPT_DATA)
    );
    if (result.status) {
      setDataMaster(result.data);
      setDataPolicies(result.data.policies);
      setDataRdConsent(result.data.rdConsent);
      setRdConsent(
        result.data.rdConsent.length > 0 ? result.data.rdConsent : []
      );
      setCheckedPolicies(result.data.policies.map(() => false));
      setLoadingContent(false);
      // console.log(result.data.policies);
      // console.log(result.data.rdConsent);
    }
  };

  const handleSelectAll = () => {
    const newCheckedState = !isAllChecked;
    setSaveFlag("N");

    if (newCheckedState) {
      setSpinner(true);
      setTimeout(() => {
        setIsAllChecked(newCheckedState);
        setSpinner(false);
      }, 1000);
    } else {
      setIsAllChecked(newCheckedState);
    }
  };

  const handleDeselectPolicy = () => {
    setSaveFlag("Y");
    const newCheckedState = !isAllUnChecked;

    if (newCheckedState) {
      setSpinner(true);
      setTimeout(() => {
        setIsAllUnChecked(newCheckedState);
        setSpinner(false);
      }, 1000);
      setCheckedPolicies([]);
      setListPolicyNo([]);
    } else {
      setIsAllUnChecked(newCheckedState);
    }
  };

  const handleSelectPolicy = (index) => {
    // console.log(index);
    const newCheckedPolicies = [...checkedPolicies];
    newCheckedPolicies[index] = !newCheckedPolicies[index];
    setCheckedPolicies(newCheckedPolicies);

    if (newCheckedPolicies.every((checked) => !checked)) {
      setIsAllChecked(false);
    }
    // console.log(checkedPolicies);
  };

  const handleUndesireSelectPolicy = (index) => {
    // console.log(index);
    const newCheckedUndesirePolicies = [...checkedUndesirePolicies];
    newCheckedUndesirePolicies[index] = !newCheckedUndesirePolicies[index];
    setCheckedUndesirePolicies(newCheckedUndesirePolicies);

    if (newCheckedUndesirePolicies.every((checked) => !checked)) {
      setIsAllUnChecked(false);
    }
    // console.log(newCheckedUndesirePolicies);
  };

  const clickPolicy = (policyNo) => {
    var arr = [];
    var map = {};

    const filterData = listPolicyNo.find((po) => po.policyNo === policyNo);
    if (filterData) {
      // console.log("aleady policy");
      const filterData = listPolicyNo.filter((po) => po.policyNo === policyNo);
      setListPolicyNo(filterData);
      arr.push(filterData);
    } else {
      rdConsent.forEach((value, index) => {
        if (policyNo === value.policyNo) {
          map["policyNo"] = policyNo;
          map["consentFlag"] = value.consentFlag;
          map["consentFlagNew"] = "Y";
          map["customerName"] = customerName;
          map["citizenId"] = citizenId;
        }
        //  console.log(value)
      });
      arr.push(map);
    }

    setListPolicyNo(arr);
    // console.log(arr);
  };

  const clickUndesirePolicy = (policyNo) => {
    let arr = [...listUndesirePolicyNo]; // Initialize with the current state to keep existing selections
    let map = {};

    const isPolicyAlreadySelected = listUndesirePolicyNo.some(
      (po) => po.policyNo === policyNo
    );

    if (isPolicyAlreadySelected) {
      // console.log("Policy already exists");
      // Remove the policy if it's already in the list (toggle-like behavior)
      arr = arr.filter((po) => po.policyNo !== policyNo);
    } else {
      // Add the new policy to the list
      const policyData = rdConsent.find((value) => value.policyNo === policyNo);
      if (policyData) {
        map["policyNo"] = policyNo;
        map["consentFlag"] = policyData.consentFlag;
        map["consentFlagNew"] = "N";
        map["customerName"] = customerName;
        map["citizenId"] = citizenId;
        arr.push(map); // Add the new policy
      }
    }

    setListUndesirePolicyNo(arr); // Update the list with the modified array
    // console.log(arr);
  };

  const saveData = async () => {
    // console.log(
    //   "List Policy Check:",
    //   listPolicyNo.length > 0 || listUndesirePolicyNo.length > 0
    // );
    // console.log("listPolicyNo:", listPolicyNo);
    // console.log("listUndesirePolicyNo:", listUndesirePolicyNo);
    const listToSave = [...listPolicyNo, ...listUndesirePolicyNo];
    if (listToSave.length > 0) {
      setLoading(true);
      try {
        const result = await insertConsentAPI(listToSave);
        // console.log("Consent data inserted successfully", result);
        setTimeout(() => {
          setShowSuccess(true);
          setLoading(false);
        }, 1500);
      } catch (error) {
        console.error("Failed to insert consent data", error);
      }
    } else {
      setShowModal(true);
    }
  };

  const goToHome = () => {
    navigate("/home");
  };

  const handleConfirm = () => {
    setShowConfirmModal(true);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div>
      <Header_Home />
      <div className="top">
        <section className="sec-navbar">
          <nav
            style={{
              backgroundColor: "#FF5E43",
              color: "white",
              height: "50px",
            }}
          >
            <div style={{ padding: "10px" }}>
              <h3 className="flex-shrink-1 text-center bold">
                การขอใช้สิทธิยกเว้นภาษีเงินได้
              </h3>
            </div>
          </nav>
        </section>
      </div>
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      {!showSuccess ? (
        <div
          className="p-4 frame-other"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "20px auto 0 auto",
            maxWidth: "1600px",
          }}
        >
          <h2
            className="bold"
            style={{ fontSize: "25px", marginLeft: "20px", marginTop: "20px" }}
          >
            เลือกกรมธรรม์ที่ต้องการลดหย่อนภาษี
          </h2>
          <br />
          <hr
            style={{
              margin: "10px 10px 15px 5px",
              border: "solid 1px",
              borderTop: 0.1,
            }}
          />
          <>
            {/* <Col xl={5} md={5} xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}> */}
            <div
              className="body-layout-head"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="insurance_desire"
                name="insurance_desire"
                style={{ marginRight: "5px",marginLeft: isSmallScreen ? "0px" : "45px" }}
                checked={isAllChecked}
                onChange={handleSelectAll}
              />
              <label htmlFor="insurance_desire" style={{ marginTop: "11px" }}>
                มีความประสงค์ใช้สิทธิลดหย่อนภาษีกรมธรรม์ที่ทำไว้กับ บริษัท
                แรบบิท ประกันชีวิต จำกัด (มหาชน)
              </label>
            </div>

            {/* </Col> */}

            <div className="body-layout-text">
              <span style={{ fontSize: "15px",marginLeft:isSmallScreen ? "0px" : "45px" }}>
                มีความประสงค์ใช้สิทธิ เฉพาะกรมธรรม์ประกันภัยเลขที่ต่อไปนี้
              </span>
            </div>

            {isAllChecked &&
              dataPolicies
                .filter((policy) =>
                  dataRdConsent.some(
                    (item) =>
                      item.policyNo === policy.policyNo &&
                      item.consentFlag === "N"
                  )
                )
                .map((item, idx) => {
                  const consentData = dataRdConsent.find(
                    (consent) => consent.policyNo === item.policyNo
                  );

                  return (
                    <div key={idx} className="body-layout-chkbox">
                      {/* ตรวจสอบว่า consentDesireFlag เป็น 'Y' */}
                      {consentData && consentData.consentDesireFlag === "Y" ? (
                        isSmallScreen ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <label
                                htmlFor={`insurance_desire_${idx}`}
                                style={{ marginLeft: "5px", marginTop: "20px" }}
                              >
                                กรมธรรม์เลขที่ :
                              </label>
                              <span
                                className="value-item"
                                style={{
                                  marginLeft: "10px",
                                  marginTop: "11px",
                                }}
                              >
                                {item.policyNo}
                              </span>
                            </div>
                            <span
                              style={{
                                color: "gray",
                                marginTop: "10px",
                              }}
                            >
                              (อยู่ระหว่างการดำเนินการ)
                            </span>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor={`insurance_desire_${idx}`}
                              style={{ marginLeft: "5px", marginTop: "20px",marginLeft:isSmallScreen ? "0px" : "45px" }}
                            >
                              กรมธรรม์เลขที่ :
                            </label>
                            <span
                              className="value-item"
                              style={{ marginLeft: "10px", marginTop: "11px" }}
                            >
                              {item.policyNo}
                            </span>
                            <span
                              style={{
                                color: "gray",
                                marginLeft: "10px",
                                marginTop: "10px",
                              }}
                            >
                              (อยู่ระหว่างการดำเนินการ)
                            </span>
                          </div>
                        )
                      ) : (
                        <>
                          {/* แสดง checkbox หาก consentDesireFlag ไม่เป็น 'Y' */}
                          <input
                            type="checkbox"
                            id={`insurance_desire_${idx}`}
                            name="insurance_desire"
                            checked={checkedPolicies[idx]}
                            onChange={() => handleSelectPolicy(idx)}
                            onClick={() => clickPolicy(item.policyNo)}
                            style={{marginLeft:isSmallScreen ? "0px" : "45px"}}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor={`insurance_desire_${idx}`}
                              style={{ marginLeft: "5px", marginTop: "20px" }}
                            >
                              กรมธรรม์เลขที่ :
                            </label>
                            <span
                              className="value-item"
                              style={{ marginLeft: "10px", marginTop: "11px" }}
                            >
                              {item.policyNo}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
          </>

          <>
            <div
              className="body-layout-head"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input
                type="checkbox"
                id="insurance_desire"
                name="insurance_desire"
                style={{ marginRight: "5px",marginLeft: isSmallScreen ? "0px" : "45px" }}
                checked={isAllUnChecked}
                onChange={handleDeselectPolicy}
              />
              <label htmlFor="insurance_desire" style={{ marginTop: "11px" }}>
                ไม่มีความประสงค์
              </label>
            </div>
            {isAllUnChecked &&
              dataPolicies
                .filter((policy) =>
                  dataRdConsent.some(
                    (item) =>
                      item.policyNo === policy.policyNo &&
                      item.consentFlag === "Y"
                  )
                )
                .map((item, idx) => (
                  <div key={idx} className="body-layout-chkbox">
                    <input
                      type="checkbox"
                      id={`insurance_desire_${idx}`}
                      name="insurance_desire"
                      checked={checkedUndesirePolicies[idx]}
                      onChange={() => handleUndesireSelectPolicy(idx)}
                      onClick={() => clickUndesirePolicy(item.policyNo)}
                      style={{marginLeft:isSmallScreen ? "0px" : "45px"}}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor={`insurance_desire_${idx}`}
                        style={{ marginLeft: "5px", marginTop: "20px" }}
                      >
                        กรมธรรม์เลขที่ :
                      </label>
                      <span
                        className="value-item"
                        style={{ marginLeft: "10px", marginTop: "11px" }}
                      >
                        {item.policyNo}
                      </span>
                    </div>
                  </div>
                ))}
          </>

          <br />
          <div className="body-layout-text">
            <span>
              การไม่แจ้งความประสงค์ที่จะใช้สิทธิยกเว้นภาษีเงินได้
              ต่อบริษัทอาจทำให้ท่านไม่สามารถหักค่าลดหย่อนภาษี
              เงินได้บุคคลธรรมดาสำหรับเบี้ยประกันภัย ตามข้อกำหนดของกรมสรรพากรได้
            </span>
          </div>
          <br />
          {isAllChecked && (
            <div className="body-layout-text">
              <span className="text-desc">
                ข้าพเจ้ายินยอมให้บริษัท แรบบิท ประกันชีวิต จำกัด (มหาชน)
                นำส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร
                ตามหลักเกณฑ์วิธีการที่กรมสรรพากรกำหนดเพื่อใช้สิทธิยกเว้นภาษีเงินได้
                ตามกฏหมายว่าด้วยภาษีอากร
              </span>
            </div>
          )}
          <br />
          <div style={{ display: "flex", marginLeft: "20px" }}>
            <span className="text-desc">หมายเหตุ</span>
            <div style={{ display: "flex", marginLeft: "23px" }}>
              <span className="text-desc">
                ตามประกาศอธิบดีกรมสรรพากรเกี่ยวกับภาษีเงินได้ ( ฉบับที่ 383 )
                กำหนดให้ผู้มีเงินได้ที่ต้องการนำเบี้ยประกันไปใช้สิทธิลดหย่อนภาษีเงินได้สำหรับปีภาษี
                2561 เป็นต้นไป ต้องแจ้งความประสงค์ที่จะใช้สิทธิ
                และจะยินยอมให้บริษัทฯ
                เปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร ภายในวันที่ 31
                ธันวาคม ของปีภาษีนั้น ๆ
              </span>
            </div>
          </div>
          <br />
          <br />
          <br />
          {/* <div className="d-flex justify-content-center flex-wrap"> */}
          <section className="action-address">
            <Grid
              container
              item
              xs={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6} md={2}>
                <button
                  onClick={goToHome}
                  className="btn-address-back w-100 p-2 bold"
                  style={{ minWidth: "100px", fontSize: "15px" }}
                >
                  ย้อนกลับ
                </button>
              </Grid>
              <Grid item xs={6} md={2}>
                <button
                  onClick={handleConfirm}
                  className="btn-address-next w-100 p-2 bold"
                >
                  ยืนยัน
                </button>
                
              </Grid>
            </Grid>
          </section>
          <br />
          <br />
        </div>
      ) : (
        <>
          <div
            className="p-4 frame-other"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px auto 0 auto",
              maxWidth: "1600px",
            }}
          >
            <Typography variant="h3" align="center">
              <img
                src={successGif}
                alt={"confirmGif"}
                style={{
                  height: "auto",
                  marginTop: "5%",
                  marginBottom: "5%",
                  width: "20%",
                }}
              />
              <li
                className="body-list-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Col
                  xl={12}
                  md={12}
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <h3 className="bold">บันทึกข้อมูลสำเร็จ</h3>
                </Col>
              </li>
            </Typography>
            <Row className="d-flex  flex-column mb-5">
              <Col className="d-flex justify-content-center px-5">
                <button
                  onClick={goToHome}
                  type="button"
                  className="btn-ex-back w-sm-100 w-lg-25 p-2 bold"
                >
                  กลับสู่หน้าหลัก
                </button>
              </Col>
            </Row>
          </div>
        </>
        // <SuccessPage />
      )}
      {LoadingContent && (
        <Row style={{ padding: "20px" }}>
          <Col lg={4} md={6} sm={12}>
            <Skeleton height={30} />
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header></Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          กรุณาเลือกกรมธรรม์ที่ต้องการทำรายการ !!!
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <a
            onClick={() => setShowModal(false)}
            type="button"
            className="mt-3 btn-back"
            style={{ minWidth: "100px", textAlign: "center" }}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        centered
      >
        <Modal.Header></Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          ท่านยืนยันข้อมูลหรือไม่
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <a
            onClick={() => setShowConfirmModal(false)}
            type="button"
            className="mt-3 btn-back"
            style={{ minWidth: "100px", textAlign: "center", fontSize: "15px" }}
          >
            ยกเลิก
          </a>
          <a
            onClick={() => saveData()}
            type="button"
            className="mt-3 btn-confirm"
            style={{ minWidth: "100px", textAlign: "center", fontSize: "15px" }}
          >
            บันทึก
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ExemptIncomeTax;

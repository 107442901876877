import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import "../../App.css";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, Grid, InputLabel, TableFooter } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "../PaymentInquiry/paymentInquiry-styles.css";
import {
  searchPaymentAPI,
  inquiryAPI,
} from "./PaymentInquiryService/paymentHistoryService";
import Skeleton from "react-loading-skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@material-ui/core";
import "moment/locale/th";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";
import OverwriteMomentBE from "../../util/OverwriteMomentBE";
import moment from "moment";
import { IconButton, InputAdornment } from "@material-ui/core";
import AlarmIcon from "@material-ui/icons/CalendarToday";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { CustomInputText } from "../Component/CustomInputText";
import { CSVLink } from "react-csv";

const PaymentInquiry = () => {
  const theme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            // backgroundColor: "#FFA500",
          },
        },
      },
    },
  });

  useEffect((date) => {
    // getConvertFormat(date)
  }, []);

  const style = {
    py: 0,
    width: "100%",
    maxWidth: 360,
    borderRadius: 2,
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
  };

  const [payDate, setPayDate] = useState(null);
  const [policyNo, setPolicyNo] = useState("");
  const [rowPayment, setRowPayment] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refNo, setRefNo] = useState("");
  const [LoadingContent, setLoadingContent] = useState(false);
  const [spinner, setSpinner] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment());
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltip = () => {
    setOpenTooltip(!openTooltip);
  };

  const handleChangeCurrentDate = (e) => {
    setCurrentDate(e);
  };

  const handleDateChange = (date) => {
    getConvertFormat(date);
  };

  const getConvertFormat = (date) => {
    const momentDate = moment(date);
    const formattedDate = momentDate.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };

  const handleChangePolicyNo = (e) => {
    setPolicyNo(e.target.value);
    // console.log(e.target.value);
  };

  const clear = (date) => {
    setSelectedDate(null);
    // getConvertFormat(date);
    setPolicyNo("");
    setRowPayment([]);
    searchPayment();
  };

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear(); /*+ 543*/
    return `${day}/${month}/${year}`;
  }

  function formatDate2(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear(); /*+ 543*/
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }

  function formatCurrency(amount) {
    return new Intl.NumberFormat("th-TH", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchPayment = async (policyNo, selectedDate) => {
    setLoadingContent(true);
    // console.log(selectedDate);
    const result = await searchPaymentAPI(policyNo, selectedDate);
    // console.log(result);
    setTimeout(() => {
      setPage(0);
      setRowPayment(result.data);
      setLoadingContent(false);
    }, 1000);
  };

  useEffect(() => {
    searchPayment();
  }, []);

  const inquiry = async (refNo) => {
    setSpinner(true);
    await inquiryAPI(refNo);

    const pollInterval = 1000;
    const maxRetries = 2;
    let retries = 0;

    const pollForUpdates = async () => {
      const result2 = await searchPaymentAPI(policyNo, selectedDate);

      if (result2.data.length > 0 || retries >= maxRetries) {
        clearInterval(polling);
        setTimeout(() => {
          setRowPayment(result2.data);
          setSpinner(false);
        }, 100);
      } else {
        retries++;
      }
    };

    const polling = setInterval(pollForUpdates, pollInterval);
  };

  const handleSearchClick = () => {
    searchPayment(policyNo, selectedDate);
  };

  const generateCsvData = (data) => {
    let data_csv = [];

    data.forEach((item, index) => {
      const data_row = {
        seq: index + 1, // เริ่มจาก 1
        policyNo: `=""${item.policyNo}""`, 
        paymentInsuredName: item.paymentInsuredName, 
        referenceNumber: item.referenceNumber, 
        cardMasking: `=""${item.cardMasking}""`, 
        approvalCode: `=""${item.approvalCode}""`, 
        amount: new Intl.NumberFormat('th-TH',{minimumFractionDigits: 2,maximumFractionDigits: 2 }).format(item.amount),
        paymentDueDate: item.paymentDueDate ? moment(item.paymentDueDate).format('DD/MM/YYYY HH:mm:ss') : '-',
        paymentActualDate: item.paymentActualDate ? moment(item.paymentActualDate).format('DD/MM/YYYY HH:mm:ss') : '-',
        paymentMethod: item.paymentMethod,
        paymentStatus: item.paymentStatus
      };
      data_csv.push(data_row);
    });

    return data_csv;
};


  const data_csv = generateCsvData(rowPayment);

  const headers = [
    { label: "ลำดับที่", key: "seq" },
    { label: "กรมธรรม์เลขที่", key: "policyNo" },
    { label: "ชื่อ-นามสกุลเจ้าของกรมธรรม์", key: "paymentInsuredName" },
    { label: "Reference number", key: "referenceNumber" },
    { label: "Card number", key: "cardMasking" },
    { label: "Approval Code", key: "approvalCode" },
    { label: "ค่าเบี้ยประกัน", key: "amount" },
    { label: "วันที่ครบกำหนดชำระ", key: "paymentDueDate" },
    { label: "วันที่ชำระ", key: "paymentActualDate" },
    { label: "ประเภทการชำระ", key: "paymentMethod" },
    { label: "สถานะ", key: "paymentStatus" }
    
    
  ];

  const generateFilename = () => {
    const currentDate = moment().format("YYYYMMDDHHmm");
    return `PAYMENT_TRANSACTION_${currentDate}.csv`;
  };

  return (
    <div>
      <Header_Home />
      <Header title="Payment Inquiry" />
      {spinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </div>
      )}
      <div className="table-page-container footer-space">
        <div className="rounded-border bg-white p-2">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{
              marginTop: "5px",
              marginBottom: "15px",
              paddingInline: "32px",
            }}
          >
            <Grid item xs={12} md={12}>
              <CustomInputText
                id="input-name"
                label="กรมธรรม์เลขที่"
                value={policyNo}
                onChange={(e) => handleChangePolicyNo(e)}
                InputProps={{
                  style: {
                    borderRadius: "16px",
                    height: "42px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{
              marginTop: "5px",
              marginBottom: "15px",
              paddingInline: "32px",
            }}
          >
            <Grid item xs={12} md={12}>
              <label htmlFor="from-date" style={{ fontWeight: "bold" }}>
                วันที่ชำระ
              </label>
              <MuiPickersUtilsProvider
                utils={OverwriteMomentBE}
                locale="th"
                moment={moment}
              >
                <DatePicker
                  pickerHeaderFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  defaultValue={""}
                  value={selectedDate || null}
                  onChange={handleDateChange}
                  fullWidth
                  autoOk={true}
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                      height: "42px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={{ borderRadius: "16px", height: "42px" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <div className="App">
            <section className="filter-action-section">
              <button
                style={{ fontSize: 18 }}
                onClick={handleSearchClick}
                className="btn-fill-orange  p-2 bold"
              >
                ค้นหา
              </button>
              <button onClick={clear} className="btn-fill-gray p-2 bold ml-2">
                ล้าง
              </button>
              
            </section>
            <br/>
            <div id="export" className="margin-l-auto text-align-right">
              <CSVLink
                 data={data_csv}
                 headers={headers}
                 filename={generateFilename()}
                style={{ fontSize: 18 }}
                className="btn-fill-export  p-2 bold"
              >
                Export File
              </CSVLink>
            </div>

            <br />

            {LoadingContent ? (
              <div className="p-4">
                <div className="p-2">
                  <Skeleton className="h-70px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                  <Skeleton className="h-30px" />
                </div>
              </div>
            ) : (
              <ThemeProvider theme={theme}>
                <TableContainer style={{ height: "auto" }} component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#e7e7e786" }}>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 100, minWidth: 8 }}
                        >
                          ลำดับที่
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 8 }}
                        >
                          กรมธรรม์เลขที่
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 300, minWidth: 8 }}
                        >
                          ชื่อ-นามสกุลเจ้าของกรมธรรม์
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 70 }}
                        >
                          Reference number
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 70 }}
                        >
                          Card Number
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 70 }}
                        >
                          Approval Code
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 30 }}
                        >
                          ค่าเบี้ยประกัน
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          วันที่ครบกำหนดชำระ
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          วันที่ชำระ
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          ประเภทการชำระ
                        </TableCell>
                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            สถานะ
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltip}
                              onClick={handleTooltip}
                              placement="top"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "transparent",
                                    padding: 2,
                                  },
                                },
                              }}
                              open={openTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                <>
                                  <List sx={style}>
                                    <ListItem sx={{ color: "orange" }}>
                                      <span>PENDING คือ รอยืนยันการชำระ</span>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem sx={{ color: "gray" }}>
                                      <span>EXPIRED คือ หมดอายุ</span>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem sx={{ color: "green" }}>
                                      <span>PAID คือ ชำระเงินสำเร็จ</span>
                                    </ListItem>
                                    <Divider component="li" />
                                    <ListItem sx={{ color: "red" }}>
                                      <span>FAIL คือ ชำระเงินไม่สำเร็จ</span>
                                    </ListItem>
                                    <Divider component="li" />
                                  </List>
                                </>
                              }
                              sx={{ color: "rgba(255, 94, 67, 1.0)" }}
                            >
                              <IconButton>
                                <InfoIcon
                                  sx={{ color: "rgba(255, 94, 67, 1.0)" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>

                        <TableCell
                          className="font-new"
                          align="center"
                          style={{ width: 200, minWidth: 50 }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!(rowPayment.length === 0) ? (
                        rowPayment
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, idx) => {
                            const seq = idx + 1 + page * rowsPerPage;
                            return (
                              <TableRow key={idx}>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 100, minWidth: 8 }}
                                >
                                  {seq}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 8 }}
                                >
                                  {item.policyNo}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 300, minWidth: 8 }}
                                >
                                  {item.paymentInsuredName}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 70 }}
                                >
                                  {item.referenceNumber}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 70 }}
                                >
                                  {item.cardMasking}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 70 }}
                                >
                                  {item.approvalCode}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="right"
                                  style={{ width: 200, minWidth: 30 }}
                                >
                                  {formatCurrency(item.amount)}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {formatDate(item.paymentDueDate)}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {formatDate2(item.paymentActualDate)}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.paymentMethod === "card"
                                    ? "บัตรเครดิต"
                                    : "QR"}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{
                                    width: 200,
                                    minWidth: 50,
                                    color:
                                      item.paymentStatus === "PENDING"
                                        ? "orange"
                                        : item.paymentStatus === "PAID"
                                        ? "green"
                                        : item.paymentStatus === "FAIL"
                                        ? "red"
                                        : "gray",
                                  }}
                                >
                                  {item.paymentStatus}
                                </TableCell>
                                <TableCell
                                  className="font-new"
                                  align="center"
                                  style={{ width: 200, minWidth: 50 }}
                                >
                                  {item.paymentStatus === "PENDING" ? (
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        inquiry(item.referenceNumber)
                                      }
                                      sx={{
                                        backgroundColor: "rgb(255, 94, 67)",
                                        height: 25,
                                        "&:hover": {
                                          backgroundColor: "#e4e4e4",
                                          boxShadow: "none",
                                        },
                                      }}
                                      variant="contained"
                                    >
                                      Inquiry
                                    </Button>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableCell align="center" colSpan={10}>
                          <span className="font-new">ไม่พบข้อมูล</span>
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowPayment.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </ThemeProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInquiry;

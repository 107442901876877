import { Axios } from "../../../util";

const uploadFileAPI = async (formData) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(`/api/policy/uploadKyc`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};


const getPolicyInformationAPI = async (insureIdCard) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyInformation?insureIdCard=${insureIdCard}`
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getPolicyCouponAPI = async (policyNo) => {
  try {
    // const res = await Axios.post("/api/policy/policyInformation");
    const res = await Axios.post(
      `/api/policy/policyCoupon?policyNo=${policyNo}`
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export { uploadFileAPI, getPolicyInformationAPI, getPolicyCouponAPI };

import React, { useContext, useEffect,useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  TextField,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import uploadImg from "../../../images/upload.svg";
import { StoreContext } from "../../../context/store";
import "./styles.css";
import { getParameterAPI } from "../../PolicyDetail/PolicyDetailService/policyDetailService";

const BankInfomationForm = ({ channelType,age }) => {
  const {
    informationEditBank,
    updateInformationEditBank,
    informationEditPromptPayAccount,
    updateInformationEditPromptPay,
  } = useContext(StoreContext);

  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');

  useEffect(() => {
    // console.log(age);
    // console.log(channelType);
    // console.log(
    //   "useContext Success informationEditBank : ",
    //   informationEditBank
    // );
    // console.log(
    //   "useContext Success informationEditPromptPayAccount : ",
    //   informationEditPromptPayAccount
    // );
    // console.log("Props : ", channelType);
    getBankName();
  }, [informationEditBank, channelType, informationEditPromptPayAccount]);

  const handleDataEdit = (key, value) => {
    let data = {
      [key]: value,
    };
    updateInformationEditBank(data);
  };

  const handleDataPromptPayEdit = (key, value) => {
    let data = {
      [key]: value,
    };
    updateInformationEditPromptPay(data);
  };

  const handleFileData = (key, value) => {
    let data = {
      [key]: value,
      bank_account_file_name: value[0].name,
    };
    updateInformationEditBank(data);
  };

  const getBankName = async () => {
    const result = await getParameterAPI(14);
    if (result.success) {
      const payload = result.payload;
      setBanks(result.payload);
      // console.log(payload);
    }
  };

  const handleBankChange = (event) => {
    updateInformationEditBank({bank_name: event.target.value})
    setSelectedBank(event.target.value);
    // console.log(event.target.value);
  };

  let placeholderAcc;

  if (age < 7) {
    placeholderAcc = "ชื่อจริง - นามสกุล ผู้ปกครองโดยชอบธรรม";
  } else if (age >= 7 && age <= 19) {
    placeholderAcc = "ชื่อจริง - นามสกุล ผู้เอาประกันภัย หรือผู้ปกครองโดยชอบธรรม";
  } else if (age >= 20) {
    placeholderAcc = "ชื่อจริง - นามสกุล ผู้เอาประกันภัย";
  } else {
    placeholder
  }

  return (
    <>
      {channelType === "bank" ? (
        <>
          <InputLabel
            htmlFor="bank_account_name"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            ชื่อบัญชี<span className="text-danger mx-1">*</span>
          </InputLabel>
          <TextField
            InputProps={{ sx: { borderRadius: 16 } }}
            id="bank_account_name"
            type="search"
            className="mb-2 label"
            placeholder="ชื่อจริง - นามสกุล"
            variant="outlined"
            value={informationEditBank.bank_account_name}
            size="small"
            onChange={(e) =>
              handleDataEdit("bank_account_name", e.target.value)
            }
            fullWidth
          />
          <InputLabel
            htmlFor="bank_name"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            ธนาคาร<span className="text-danger mx-1">*</span>
          </InputLabel>
          <Select
            input={<OutlinedInput />}
            style={{ borderRadius: "16px", height: "42px" }}
            id="bank_name"
            variant="outlined"
            fullWidth
            displayEmpty
            className="select-input mb-2 label"
            value={informationEditBank.bank_name}
            onChange={handleBankChange}
          >
            <MenuItem value="" disabled>
              เลือกธนาคาร
            </MenuItem>
            {banks.map((bank) => (
              <MenuItem key={bank.pmdId} value={bank.name}>
                {bank.name}
              </MenuItem>
            ))}
            <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
            {/* <MenuItem value="ธนาคาร 1">ธนาคาร 1</MenuItem> */}
          </Select>
          <InputLabel
            htmlFor="bank_account"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            เลขที่บัญชี<span className="text-danger mx-1">*</span>
          </InputLabel>
          <TextField
            InputProps={{ sx: { borderRadius: 16 } }}
            id="bank_account"
            type="search"
            className="mb-2 label"
            placeholder="เลขที่บัญชี"
            variant="outlined"
            value={informationEditBank.bank_account}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, ""); 
              handleDataEdit("bank_account", value);
            }}
            
            size="small"
            fullWidth
          />

          <InputLabel
            htmlFor="bank_account"
            className="label"
            style={{
              fontWeight: "bold",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {age < 7 ? (
              <>
                สำเนาสมุดบัญชีผู้ปกครองโดยชอบธรรม<span className="text-danger mx-1">*</span>
              </>
            ) : age >= 7 && age <= 19 ? (
              <>
                สำเนาสมุดบัญชีผู้เอาประกันภัย หรือผู้ปกครองโดยชอบธรรม<span className="text-danger mx-1">*</span>
              </>
            ) : age >= 20 ? (
              <>
                สำเนาสมุดบัญชีผู้เอาประกันภัย<span className="text-danger mx-1">*</span>
              </>
            ) : (
              <>
                สำเนาสมุดบัญชีเงินฝากธนาคาร <span className="text-danger mx-1">*</span>
              </>
            )}
          </InputLabel>
          <label
            htmlFor="file1"
            className="btn-upload-file d-flex flex-row justify-content-center align-items-center"
            style={{ marginTop: "-5px" }} 
          >
            <i className="fa fa-cloud-upload"></i> อัพโหลด
            <img
              className="icon-image-content-upload"
              src={uploadImg}
              style={{ textAlign: "center" }}
              alt=""
            />
          </label>
          <input
            type="file"
            id="file1"
            accept="image/png, image/jpeg, image/jpg, application/pdf"
            style={{ display: "none" }}
            onChange={(e) => {
              handleFileData("bank_account_file", e.target.files);
            }}
          />
          {informationEditBank.bank_account_file_name && (
            <p
              className="ml-2 text-danger label"
            >
              {informationEditBank.bank_account_file_name || ""}
            </p>
          )}
        </>
      ) : (
        <>
         <InputLabel
            htmlFor="prompt_pay_account_name"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            ชื่อบัญชี<span className="text-danger mx-1">*</span>
          </InputLabel>
          <TextField
            InputProps={{ sx: { borderRadius: 16 } }}
            id="prompt_pay_account_name"
            type="search"
            className="mb-2 label"
            placeholder={placeholderAcc}  // Set placeholder here directly
            inputProps={{
              style: { fontSize: "16px" }, // Normal font size for input text
            }}
            sx={{
              "& ::placeholder": {
                fontSize: "16px", // Default placeholder font size for large screens
                "@media (max-width:600px)": {
                  fontSize: "11px", // Smaller placeholder font size for smaller screens
                },
              },
            }}
            variant="outlined"
            value={informationEditPromptPayAccount.prompt_pay_account_name}
            size="small"
            onChange={(e) =>
              handleDataPromptPayEdit("prompt_pay_account_name", e.target.value)
            }
            fullWidth
          />
          <InputLabel
            htmlFor="prompt_pay_account"
            className="label"
            style={{ fontWeight: "bold" }}
          >
            เลขประจำตัวประชาชน<span className="text-danger mx-1">*</span>
          </InputLabel>
          <TextField
          InputProps={{ sx: { borderRadius: 16 } }}
          id="prompt_pay_account"
          type="search"
          className="mb-2 label"
          placeholder="เลขประจำตัวประชาชน 13 หลัก"
          variant="outlined"
          value={informationEditPromptPayAccount.prompt_pay_account}
          onChange={(e) => {
            const value = e.target.value;
            // กรองให้กรอกได้เฉพาะตัวเลขและจำกัดให้ไม่เกิน 13 ตัวอักษร
            if (/^\d{0,13}$/.test(value)) {
              handleDataPromptPayEdit("prompt_pay_account", value);
            }
          }}
          inputProps={{
            style: { fontSize: "16px" }, // Normal font size for input text
          }}
          sx={{
            "& ::placeholder": {
              fontSize: "16px", // Default placeholder font size for large screens
              "@media (max-width:600px)": {
                fontSize: "11px", // Smaller placeholder font size for smaller screens
              },
            },
          }}
          size="small"
          fullWidth
        />
        </>
      )}
    </>
  );
};

export default BankInfomationForm;

import React from "react";
import Header_Home from "../Component/Header_Home";
import Header from "../Component/Header";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

const ChannelPay = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const location = useLocation();
  const dataParams = location.state || {};

  const policyNo = dataParams.policyNo || "";
  const age = dataParams.age || "";

  const goToEditInfoChannel = (channel) => {
    navigate(`/editBank`, {
      state: {
        channelType: channel,
        policyNo: policyNo,
        age: age,
      },
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header_Home />
      <Header title="เงินคืนตามเงื่อนไขกรมธรรม์" />
      <div className="container px-4 pt-5">
        <div
          style={{ paddingBottom: 100 }}
          className="card-change-pay big-frame-change-pay"
        >
          <div className="d-flex px-3 pt-3 pb-3">
            <h2 className="font-size-title">ช่องทางการรับเงิน</h2>
          </div>
          <hr
            className="mx-2"
            style={{ margin: "0px 0px 15px 0px", border: "solid 1px" }}
          /><div className="d-flex flex-row" style={{paddingLeft:"30px"}}>
          <div
            onClick={() => goToEditInfoChannel("bank")}
            className="card-service-change-pay d-flex flex-column text-center align-items-center justify-content-center align-content-center"
          >
            <img
              src={require("../../images/BankCards.svg").default}
              alt="mySvgImage"
              className="img-service-payment-change-pay"
            />
            <p className="fontSizeCard-Change-pay">บัญชีธนาคาร</p>
          </div>
        </div>
          {/* <div
            className="d-flex flex-row justify-content-evenly"
            style={{ marginTop: "60px" }}
          >
            <div
              onClick={() => goToEditInfoChannel("bank")}
              className="card-service-change-pay d-flex flex-column text-center align-items-center justify-content-center align-content-center"
            >
              
              <img
                src={require("../../images/BankCards.svg").default}
                alt="mySvgImage"
                className="img-service-payment"
              />
              <p className="fontSizeCard-Change-pay">บัญชีธนาคาร</p>
            </div>
            <div
              onClick={() => goToEditInfoChannel("promptpay")}
              className="card-service-change-pay d-flex flex-column text-center align-items-center justify-content-center align-content-center"
            >
             
              <img
                src={require("../../images/promptpay.png")}
                alt="mySvgImage"
                className="img-service-payment-change-pay-promtpay"
              />
              <p className="fontSizeCard-Change-pay">บัญชีพร้อมเพย์</p>
            </div>
          </div> */}
        </div>
        <Row className="d-flex flex-column mt-5 mb-5">
          <Col className="d-flex justify-content-center px-5">
            <button
              style={{ marginTop: "-20px" }}
              onClick={goBack}
              className="btn-checkout-back w-100 p-2 bold"
            >
              ย้อนกลับ
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChannelPay;

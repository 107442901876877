import React, { useState } from "react";
import img_profile from "../../images/profile.svg";
import img_1 from "../../images/rabbitlife-logo.png";
import {
  getMenuParameterAPI,
  getMenuByRoleAPI,
} from "../Home/homeService/homeService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Header_Home(props) {
  const [menu, setMenu] = useState([]);
  const [role, setRole] = useState(localStorage.getItem("profile") || "");
  const [perRole, setPerRole] = useState(localStorage.getItem("role" || ""));
  const [age, setAge] = useState(localStorage.getItem("age"));
  let navigate = useNavigate();

  const handleGetMenu = () => {
    // console.log(role);
    if (role == "Customer") {
      getMenuParameterAPI()
        .then((res) => {
          // console.log("getMenuParameterAPI ", res);
          setMenu(res.data.payload);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      getMenuByRoleAPI(perRole)
        .then((res) => {
          // console.log("getMenuByRoleAPI ", res);
          setMenu(res.data.payload);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const logout = () => {
    if (localStorage.getItem("profile") == "POS") {
      localStorage.clear();
      navigate("/login");
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  useEffect(() => {
    // console.log(age);
    handleGetMenu();
  }, []);

  const handleGoToRoute = (url) => {
    navigate(url);
  };

  const goToHome = () => {
    navigate("/home");
  };

  return (
    <section className="sec-navbar">
      <nav className="navbar navbar-expand-lg navbar-light bg-light px-lg-5 nav-h pl-3">
        <div className="navbar-brand">
          <img src={img_1} height="48" alt="" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="d-flex mx-auto justify-content-between align-items-center">
            <ul className="navbar-nav d-flex">
              <li className="nav-item active">
                <a
                  type="button"
                  className="nav-link"
                  style={{ backgroundColor: "#F8F9FA" }}
                  onClick={goToHome}
                >
                  หน้าหลัก <span className="sr-only">(current)</span>
                </a>
              </li>
              {menu.map((post, index) => (
                <li key={index} className="nav-item active">
                  <a
                    type="button"
                    className="nav-link"
                    style={{ backgroundColor: "#F8F9FA" }}
                    onClick={() => {
                      if (post.urlLink === "/sendDoc") {
                        navigate("/sendDoc", {
                          state: {
                            age: age,
                          },
                        });
                      } else {
                        handleGoToRoute(post.urlLink);
                      }
                    }}
                  >
                    {post.menuName} <span className="sr-only">(current)</span>
                  </a>
                </li>
              ))}
            </ul>
            <div
              className="d-lg-none d-block dropdown-mobile"
              style={{ marginBottom: "90px" }}
            >
              <button className="d-block info-profile-mobile">
                <label>
                  <img
                    src={img_profile}
                    alt=""
                    height="70"
                    className="mar-mi-20"
                  />
                  <br />
                  {localStorage.getItem("first_name")}{" "}
                  {localStorage.getItem("last_name")}
                </label>
              </button>
              <div className="dropdown-content-mobile">
                <a onClick={logout}>Logout</a>
              </div>
            </div>
          </div>
        </div>
        <div className="form-inline my-2 my-lg-0 mx-3 d-none d-lg-block">
          <button className="d-block info-profile">
            <div className="dropdown info">
              <button className="d-block info-profile d-flex justify-content-center align-items-center">
                {/* <div className='col-7'>  */}
                <span className="text-nowrap">
                  {localStorage.getItem("first_name")}{" "}
                  {localStorage.getItem("last_name")}
                </span>
                <label>
                  <br />
                  <img
                    src={img_profile}
                    alt=""
                    height="70"
                    className="mar-mi-20"
                  />
                </label>
                {/* </div>  */}
              </button>
              <div className="dropdown-content">
                <a onClick={logout}>Logout</a>
              </div>
            </div>
          </button>
        </div>
      </nav>
    </section>
  );
}

import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import img_2 from "../../images/logonew1.png";
import img_logo from "../../images/rabbitlife-logo.png";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Login from "./Login";
import HeaderLogin from "../Component/HeaderLogin";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CryptoJS from "crypto-js";
import { TextField } from "@mui/material";
import { getParameterAPI } from "../PolicyDetail/PolicyDetailService/policyDetailService";

const OTP_LOGIN = (props) => {
  const [step, setStep] = useState(2);
  const [loading, setLoading] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง"
  );
  const [cusTel, setCusTel] = useState("");

  useEffect(() => {
    getTel();
  }, []);

  const encryptCardNumber = (cardNumber, secretKey) => {
    const encrypted = CryptoJS.AES.encrypt(cardNumber, secretKey).toString();
    return encrypted;
  };

  const decryptCardNumber = (encryptedCardNumber, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCardNumber, secretKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const getTel = async () => {
    const result = await getParameterAPI(12);
    const payload = result.payload;
    const cusTel = payload.find((item) => item.code === "CUS_SERV_TEL");
    const variable1 = cusTel.variable1;
    setCusTel(variable1);
  };

  const resend_otp = () => {
    document.getElementById("otp").value = "";
    let username = props.username;
    let password = props.password;

    setLoading(true);

    fetch(process.env.REACT_APP_API_BASEURL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          setLoading(false);
          setErrorFlag(true);
          setErrorMsg(
            "ระบบทำการส่ง OTP เรียบร้อยแล้วโปรดทำการตรวจสอบอีกครั้ง"
          );
        } else {
          setLoading(false);
          setErrorFlag(true);
          setErrorMsg(res.payload.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submit = (event) => {
    event.preventDefault();
    let otp = document.getElementById("otp").value;
    let username = props.username;

    setLoading(true);

    fetch(process.env.REACT_APP_API_BASEURL + "/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        otp: otp,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success !== false) {
          localStorage.clear();
          const encryptedCardNumber = encryptCardNumber(
            res.customer_id,
            process.env.REACT_APP_ENCRYPT_DATA
          );
          localStorage.setItem("customer_id", encryptedCardNumber);
          localStorage.setItem("profile", res.profile);
          localStorage.setItem("first_name", res.first_name);
          localStorage.setItem("last_name", res.last_name);
          localStorage.setItem("token_login", res.token);
          // console.log("localStorage", localStorage.getItem("token_login"));
          // console.log("localStorage", localStorage.getItem('username'))
          window.location.href = "/home";
        } else {
          setLoading(false);
          setErrorFlag(true);
          setErrorMsg(res.payload.error);
          if (res.payload.type === "otp_limit") {
            setStep(1);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const goBack = () => {
    navigate("/");
  };

  return (
    <div className="page-contanner">
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress
            sx={{
              animationDuration: "550ms",
            }}
            color="inherit"
          />
        </Backdrop>
      )}
      <HeaderLogin />
      <section className="sec-form" id="">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md- col-xs-12 p-5 mx-auto">
              <div className="logo text-center">
                <img className="img-fluid" src={img_2} alt="" />
                <h4>iService</h4>
              </div>

              <form className="login-form mt-5" onSubmit={submit}>
                <div className="form-group">
                  <label htmlFor="input_otp" id="defaultValueotp">
                    กรุณาใส่หมายเลข OTP 6 หลัก ที่ส่งไปยังเบอร์โทรศัพท์ของท่าน
                    (รหัสนี้มีอายุการใช้งาน 5 นาที)
                  </label>
                  <TextField
                    type="tel"
                    defaultValue=""
                    style={{ borderWidth: 1, marginBottom: "10px" }}
                    className="form-control form-control-lg"
                    id="otp"
                    placeholder="OTP"
                    inputProps={{
                      maxLength: 6,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      onInput: (e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      },
                    }}
                  />
                  {errorFlag && <p className="text-danger">{errorMsg}</p>}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg btn mt-2 cust-btn shadow"
                >
                  เข้าสู่ระบบ
                </button>
                <a
                  href="/"
                  type="button"
                  className="btn btn-back-regis btn-block btn-lg btn mt-3 cust-btn-back"
                >
                  ย้อนกลับ
                </a>
                <a onClick={resend_otp}>
                  <small
                    className="form-text text-primary pointer mt-3"
                    style={{ cursor: "pointer" }}
                  >
                    ส่งรหัส OTP อีกครั้ง?
                  </small>
                </a>
                <p
                  className="mt-2"
                  style={{ color: "#FF5E43", fontSize: "12px" }}
                >
                  หากท่านมีการเปลี่ยนเบอร์มือถือ หรือไม่ได้รับ OTP
                  โปรดติดต่อฝ่ายลูกค้าสัมพันธ์ หมายเลข {cusTel}
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OTP_LOGIN;

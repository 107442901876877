import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import axios from 'axios';
import Header_Home from '../Component/Header_Home';
class Download_doc extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date() ,
        token: '',
        username:'',
        step:1,
        offset: 0,
        data: [],
        perPage: 50,
        currentPage: 0,
        pageCount:0,
        showing_step:1,
        showing_all:false
    }
    }

    receivedData() {
         
        fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list",
        // fetch(process.env.REACT_APP_API_BASEURL + "/api/policy/list?customer="+localStorage.getItem('customer_id'),
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            const data = res.payload;
            const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
            
            if(data.length > 1){
                this.setState({showing_all : true})
            }
            const postData = slice.map(item => 
                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                    <div className="card">  
                        <div className="card-body">
                            <div className="row col-12">
                                <div className="col-1 text-right pt-2">
                                    <input onChange={this.handleChange("policy_change_ans")} type="radio" id="policy_change" name="policy_change" value={item.policy_no} className="margin-left-px"  disabled={this.state.showing3} ></input>
                                </div>
                                <div className="col-9">
                                    <label className='text-righ mt-2'><strong>หมายเลขกรมธรรม์ : </strong> {item.policy_no}</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
          
            )
            this.setState({payload : res.payload,
                pageCount: Math.ceil(data.length / this.state.perPage),
                postData})
            
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidMount() {
        // console.log('componentDidMount')
        this.receivedData()
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.receivedData()
        });

    };

    handleselectedFile  = input => e => {
        this.setState({
            selectedFile: e.target.files[0],
            //**** added line below ****//
            file_ori: e.target.files[0].name,
            [input]: e.target.files[0].name
        });
    };

    get_doc (policy) {

        fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/getdownload",
        {
            headers: {
            'authorization': localStorage.getItem('token_login'),
            'Content-Type': 'application/json'
            },
            method: "GET"
        })
        .then(res => res.json())
        
        .then( res => { 
            //console.log(res)
            if(res.success) {
                const data = res.payload;
                const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
                
                if(data.length > 1){
                    this.setState({showing_all : true})
                }

                let postData2 =''
                if(slice.filter(i => i.policy_no.trim() == policy.trim()).length == 0){
                    postData2 = 
                    <div className="row col-12 ">
                        <div className="col-10 text-center m-auto">
                        ขออภัย กรมธรรม์ฉบับนี้ยังไม่มีเอกสารให้ดาวน์โหลด
                        </div>
                    </div>  
                }else {
                    postData2 = slice.filter(i => i.policy_no.trim() == policy.trim()).map(item => 
                        <div className="row col-12 ">
                            <div className="col-1 text-right pt-2">
                                <input  type="checkbox" id={item.file_name} name={item.file_name} value={item.file_name} className="margin-left-px"  disabled={this.state.showing3} ></input>
                            </div>
                            <div className="col-9 pr-0">
                                <label className='text-righ mt-2'> {item.letter_name}</label>
                            </div>
                        </div>  
                  
                    )
                }
               
                // console.log("postData2",postData2,slice.filter(i => i.policy_no.trim() == policy.trim()).length)
                this.setState({payload2 : res.payload,
                    pageCount2: Math.ceil(data.length / this.state.perPage),
                    postData2})
                
            } else {
                const postData2 = 
                    <div className="row col-12 ">
                        <div className="col-10 text-center m-auto">
                        ขออภัย กรมธรรม์ฉบับนี้ยังไม่มีเอกสารให้ดาวน์โหลด
                        </div>
                    </div>  
              this.setState({postData2})
                
            }
            
        })
        .catch(function(res){ console.log(res) })
    }

    componentDidUpdate() {
       
        // console.log('componentDidUpdate')
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount')
       
    }

    con () {
        const { payload} = this.state;
        const data = payload;
        const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData2 = this.item_change()
        this.setState({postData2})
    }

    

    handleChange = input => e => {
        // console.log("handleChange",e.target.value)

        this.setState({ [input]: e.target.value });
        if( [input] == "policy_change_ans"){
            this.get_doc(e.target.value)
        }
        // console.log({ [input]: e.target.value })
    };

    back () {
        const {showing_step} = this.state;
        this.setState({
            showing_step: showing_step-1
        });
        this.con();
    }


    download () {
        const { payload2, policy_change_ans} = this.state;
        // console.log("payload2",payload2,policy_change_ans)

        payload2.filter(i => i.policy_no.trim() == policy_change_ans.trim()).map(item => {
            if(document.getElementById(item.file_name).checked){
                // console.log("letter_name",item.letter_name)
                const FileDownload = require("js-file-download");

                fetch(process.env.REACT_APP_API_BASEURL + "/api/personal/download?policy="+item.policy_no+"&file_name="+item.file_name,
                {
                    headers: {
                    'authorization': localStorage.getItem('token_login'),
                    'Content-Type': 'application/json'
                    },
                    method: "GET"
                })
                .then(response => response.blob())
                
                .then(response => {
                    FileDownload(response, item.file_name.trim());
                    // const imageObjectURL = URL.createObjectURL(response);
                    // window.open(imageObjectURL, '_blank');
                })
                .catch(function(res){ console.log(res) })
            }
        })

    }


 
    render() {
        const { showing_step, postData, postData2, showing_all} = this.state;
        return (
            <div >
                <Header_Home/>
                <section className="d-flex justify-content-center align-items-center h-100 my-5">
                    <div className="container-fluid  container-margin">
                        <div className='row'>
                            <div className="col-12">
                                <div className="card" style={{ display: (showing_step === 1 ? 'block' : 'none')}}>
                                    <div className="card-body header-card">
                                        <div className="row col-12 ">
                                            <div className="col-12 text-center">
                                                <label htmlFor="subject" className='text-center mt-2'><strong>ดาวน์โหลดเอกสาร</strong></label>
                                            </div>
                                        </div>       
                                    </div>
                       
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-10 pt-4 m-auto text-center">
                                            ท่านสามารถดาวน์โหลดหนังสือแจ้งครบกำหนดชำระเบี้ยประกัน   บันทึกสลักหลัง  และ จดหมายต่างๆ   โดยเลือกกรมธรรม์ที่ท่านต้องการ  
                                               <br/> *หมายเหตุ สำหรับหนังสือรับรองการชำระเบี้ยประกัน  สามารถดาวน์โหลดได้ในเดือนมกราคม

                                            </div>
                                       
                                                
                                        </div>    
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-12 pt-4">
                                                {postData} 
                                            </div>
                                       
                                                
                                        </div>       
                                    </div>
                                    <div className="card-body list-group list-group-flush p-0 pt-4">
                                        <div className="row col-12 p-0 margin-auto">
                                            <div className="col-lg-10 col-md-12 col-sm-12 margin-auto">
                                                <div className="col-lg-8 col-md-12 col-sm-12 margin-auto">
                                                    <div className="card">  
                                                        <div className="card-body">
                                                            {postData2}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                       
                                                
                                        </div>       
                                    </div>
                                    <div className="card-body App">
                                        <a  href="/download" type="button" className="btn btn-outline-rabbit mr-3">ย้อนกลับ</a>
                                        <a  onClick={() => this.download()} className="btn btn-lg btn-blue">ดาวน์โหลดเอกสาร</a>
                                    </div>
                                </div>


                            </div>
                        </div>
                    

                        {/* <div className='App'>
                            <a  href="/home" type="button" className="btn btn-lg btn mt-3 cust-btn"  >ย้อนกลับ</a>
                        </div> */}
                        </div> 
                </section>
            </div> 
        )
    }
        
    
}

export default Download_doc;
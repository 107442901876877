import { Axios } from "../../../util";
import axios from "axios";

const getDownloadDoc = async (username) => {
  try {
    const res = await Axios.get(
      `api/policy/getDataDownloadDoc?username=${username}`,
      {
        headers: { accept: "application/json" },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getFileByApi = async (policyNo, receiptNo,fileName) => {
  try {
    const res = await Axios.post(
      `api/policy/getFileApi?policyNo=${policyNo}&receiptNo=${receiptNo}`,
      {}, // Request body (empty object if not needed)
      {
        headers: { accept: "application/json" },
        responseType: 'blob' // Set the response type here
      }
    ).then((response) => {
        // Create a link element to download the file
        // console.log("response",response)
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

const getFileFromOneDrive = async (fileName) => {
  try {
    const res = await Axios.get(
      `api/policy/getFileFromDrive?fileName=${fileName}`,
      {
        headers: { accept: "application/json" },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};


const downloadLetterDigiCom = async (fileName) => {
  try {
    const res = await Axios.get(
      `api/policy/downloadLetterDigiCom?fileName=${fileName}`,
      {
        headers: { accept: "application/json" },
      }
    );
    // console.log("data", res.data);
    return res.data;
  } catch (error) {
    console.error("There was an error fetching the API data!", error);
    throw error;
  }
};

export { getDownloadDoc,
         getFileByApi,
         getFileFromOneDrive,
         downloadLetterDigiCom
};
